import Slider from "react-slick";
import React, {ReactNode} from "react";

import "@components/moment/slider/slider.css"

const ImageSlider = (props: { children: ReactNode }) => {
    return (
        <Slider className="slider overflow-hidden mb-3" {...{
            slidesToShow: 1,
            centerMode: true,
            adaptiveHeight: true,
            speed: 500,
            variableWidth: true,
            responsive: [
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 1,
                    }
                },
            ]
        }}>
            {props.children}
        </Slider>
    )
}

export default ImageSlider;