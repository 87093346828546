import {IconFriends, IconHeart, IconHeartHandshake, IconInfinity} from "@tabler/icons-react";
import {useContext} from "react";
import {Context} from "../../index";

const Icon = ({style, size, color}: any) => {
    const {moment}: any = useContext(Context);

    if (!moment) {
        return null;
    }

    if (moment.typeId == 1) {
        if (style == 1) {
            return <IconInfinity size={size} color={color}/>
        } else if (style == 2) {
            return <IconHeart size={size} color={color}/>
        } else {
            return <IconInfinity size={size} color={color}/>
        }
    } else if (moment.typeId == 2) {
        if (style == 1) {
            return <IconHeartHandshake size={size} color={color}/>
        } else if (style == 2) {
            return <IconFriends size={size} color={color}/>
        } else {
            return <IconHeartHandshake size={size} color={color}/>
        }
    } else {
        return null;
    }


}

export default Icon;