export default new (class CacheService {
    getCache() {
        if (!localStorage.getItem("images")) {
            localStorage.setItem("images", JSON.stringify({}));
        }
        return JSON.parse(localStorage.getItem("images") as string);
    }

    getImage(url: string) {
        if (this.getCache()) {
            if (this.getCache()[url]) {
                if (this.getCache()[url].expires && !(this.getCache()[url].expires < new Date().getTime())) {
                    return this.getCache()[url];
                }
            }
        }
        return false;
    }

    cacheImage(url: string, signedUrl: string, expires: number) {
        if (this.getCache()) {
            let cache = this.getCache();
            cache[url] = {
                url: signedUrl,
                expires: expires
            }
            localStorage.setItem("images", JSON.stringify(cache));
        }
    }


})()