import React, { useContext, useState } from "react";
import Modal from "@components/ui/Modal";
import api from "@services/api.service";
import { Context } from "../../../index";
import ImageUploader from "@components/upload/ImageUploader";
import ProtectedImage from "@components/auth/ProtectedImage";
import { IconFiles, IconTrash } from "@tabler/icons-react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const GalleryModal = ({ fetch, show, setShow }: any) => {
  const { moment }: any = useContext(Context);

  const handleUpload = () => {
    fetch();
  };

  const [loading, setLoading]: any = useState(false);

  const { t } = useTranslation<"translation", undefined>();

  const handleUploadMulti = (e: any) => {
    setLoading(true);

    let fd = new FormData();
    Object.values(e.currentTarget.files).map((file: any) => {
      fd.append("files", file);
    });

    api
      .post(`/v1/moment/${moment.id}/images/gallery/multi`, fd)
      .then(() => {
        setLoading(false);
        fetch();
      })
      .catch((e) => {
        setLoading(false);
        if (e && e.response.status == 413) {
          toast.error(t("image_too_big"), { position: "bottom-right" });
        } else {
        }
      });
    fetch();
  };

  const handleDelete = (id: number) => {
    api.delete(`/v1/moment/${moment.id}/images/gallery/${id}`).then(() => {
      fetch();
    });
  };

  return (
    <Modal title={t("gallery")} show={show} setShow={setShow}>
      <p className="text-gray-400 mb-1">
        {t("images_can_be_up_to_5_MB_in_size")}
      </p>
      <ImageUploader
        id={2}
        onFinish={handleUpload}
        endpoint={`/v1/moment/${moment.id}/images/gallery`}
      />
      <span className="text-center">{t("or")}</span>
      <div className="flex items-center justify-center w-full">
        <label className="flex flex-col items-center justify-center w-full h-36 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100">
          <div className="flex flex-col items-center justify-center pt-2 pb-2">
            {loading && (
              <span className="loading loading-spinner loading-lg bg-gray-400"></span>
            )}
            {!loading && <IconFiles className="w-10 h-10 mb-3 text-gray-400" />}
            <p className="mb-2 text-md text-gray-500 dark:text-gray-400">
              <span className="font-semibold">{t("add_multiple_images")}</span>
            </p>
            <p className="text-md text-gray-500 dark:text-gray-400">
              {t("jpg_jpeg")}
            </p>
          </div>
          <input
            multiple
            type="file"
            className="hidden"
            accept="image/jpeg,image/png"
            onChange={handleUploadMulti}
          />
        </label>
      </div>
      {moment.images["@gallery"] && moment.images["@gallery"].length !== 0 && (
        <table className="mt-3 table-fixed">
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {moment.images["@gallery"] &&
              moment.images["@gallery"].map((image: any, i: number) => {
                return (
                  <tr key={i} className="bg-gray-50 rounded-lg p-3">
                    <td className="p-3 text-2xl">{image.index}</td>
                    <td className="p-3 w-full text-center">
                      <ProtectedImage
                        className="mx-auto w-auto h-32 object-cover mb-2 rounded-lg"
                        url={image.url}
                      />
                    </td>
                    <td className="p-3 h-32 flex justify-center items-center">
                      <IconTrash
                        onClick={() => handleDelete(image.id)}
                        className="text-red-600"
                        size={30}
                      />
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}
    </Modal>
  );
};

export default GalleryModal;
