import React, { FormEvent, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import api from "@services/api.service";
import { toast } from "react-toastify";
import { Dialog } from "@components/layout/Dialog";
import Input from "@components/ui/Input";
import Button from "@components/ui/Button";
import { useTranslation } from "react-i18next";

const RegisterPage = () => {
  const { tokenId } = useParams();

  const [search] = useSearchParams();

  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const [success, setSuccess] = useState(false);

  const [loading, setLoading] = useState(false);

  const { t } = useTranslation<"translation", undefined>();

  const handleResend = () => {
    api
      .post("/v1/auth/resend", {
        token: tokenId,
      })
      .then((response) => {
        if (response.status == 201) {
          toast.success(t("we_have_sent_another_confirmation"), {
            position: "bottom-right",
          });
        }
      })
      .catch((e) => {
        if (e.response.data.message == "already-sent") {
          toast.warning(t("please_wait_before_requesting_confirmation_again"), {
            position: "bottom-right",
          });
        }
      });
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);

    if (password !== passwordConfirm) {
      toast.error(t("the_passwords_do_not_match"), {
        position: "bottom-right",
      });
      setLoading(false);
      return;
    }

    api
      .post("/v1/auth/register", {
        email: email,
        name: username,
        password: password,
        token: tokenId,
      })
      .then((response) => {
        if (response.status == 201) {
          setSuccess(true);
        }
      })
      .catch((e) => {
        if (e.response.status === 409) {
          toast.error(t("email_already_in_use"), {
            position: "bottom-right",
          });
        } else if (e.response.data.message == "invalid-token") {
          toast.error(t("invalid_token"), { position: "bottom-right" });
        } else {
          toast.error(e.response.data.message[0], { position: "bottom-right" });
        }
        setLoading(false);
      });
  };

  if (success) {
    return (
      <Dialog>
        <span className="text-lg font-bold">
          {t("confirm_your_e_mail_address")}
        </span>
        <p>{t("we_have_sent_an_email_to", { email: email })}</p>
        <p>{t("you_must_confirm_your_email_to_continue")}</p>
        <p className="mt-5 mb-3">
          {t("please_remember_this_in_yours_too")} <i>{t("spam")}</i>{" "}
          {t("look_up_folders_if_you_dont_see_our_email")}
        </p>
        <Button
          title={t("resend_email")}
          onClick={handleResend}
          type="button"
        />
      </Dialog>
    );
  } else {
    return (
      <Dialog title={t("create_an_account")} back={search.get("b") !== null}>
        <div className="text-left">{t("please_create_the_account")}</div>
        <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
          <Input
            id="email"
            label={t("you_email")}
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
            type="email"
            placeholder="name@email.de"
            required
          />
          <Input
            id="username"
            label={t("your_username")}
            value={username}
            onChange={(e) => setUsername(e.currentTarget.value)}
            type="text"
            minLength={3}
            autoComplete="off"
            placeholder="Max"
            required
          />
          <Input
            id="password"
            label={t("your_password")}
            value={password}
            onChange={(e) => setPassword(e.currentTarget.value)}
            type="password"
            minLength={8}
            placeholder="********"
            autoComplete="new-password"
            required
          />
          <Input
            id="confirm-password"
            label={t("repeat_password")}
            value={passwordConfirm}
            onChange={(e) => setPasswordConfirm(e.currentTarget.value)}
            type="password"
            minLength={8}
            placeholder="********"
            autoComplete="new-password"
            required
          />
          <div className="form-control text-center">
            <label className="label cursor-pointer">
              <input type="checkbox" required className="checkbox" />
              <span className="label-text text-md">
                {t("i_accept_the")}
                <a
                  className="font-medium link link-neutral"
                  href="https://infinity-moments.com/policies/privacy-policy"
                  target="_blank"
                >
                  {t("data_protection")}
                </a>
              </span>
            </label>
          </div>
          <Button
            content={
              loading ? (
                <span className="loading loading-infinity loading-md" />
              ) : (
                <span className="text-md">{t("create_account")}</span>
              )
            }
          />
          <p className="text-sm text-center">
            {t("you_already_have_an_account")}
            <br />
            <Link to="/login" className="link link-hover">
              {t("log_in_here")}
            </Link>
          </p>
        </form>
      </Dialog>
    );
  }
};

export default RegisterPage;
