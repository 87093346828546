import Modal from "@components/ui/Modal";
import React, {useContext, useState} from "react";
import Button from "@components/ui/Button";
import api from "@services/api.service";
import {Context} from "../../../index";
import {IconGripVertical, IconTrash} from "@tabler/icons-react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {useTranslation} from "react-i18next";

const EditBucketListModal = ({fetch, show, setShow, bucketList}: any) => {
    const {moment}: any = useContext(Context);

    const {t} = useTranslation<"translation", undefined>();

    const handleAdd = () => {
        if (name && name != "") {
            api.get(`/v1/moment/${moment.id}/sector/lists`).then((response) => {
                if (response.data.length == 0) {
                    api.post(`/v1/moment/${moment.id}/sector/lists`, {name: "Bucketlist"}).then(() => {
                        api
                            .post(`/v1/moment/${moment.id}/sector/lists/${response.data[0].id}`, {
                                name: name,
                            })
                            .then(() => {
                                setName("");
                                fetch();
                            });
                    })
                } else {
                    api
                        .post(`/v1/moment/${moment.id}/sector/lists/${response.data[0].id}`, {
                            name: name,
                        })
                        .then(() => {
                            setName("");
                            fetch();
                        });
                }
            });
        }
    };

    const handleDelete = (index: number) => {
        api.get(`/v1/moment/${moment.id}/sector/lists`).then((response) => {
            api
                .delete(`/v1/moment/${moment.id}/sector/lists/${response.data[0].id}/${index}`, {
                    name: name,
                })
                .then(() => {
                    fetch();
                });
        });

    };

    const handleDragEnd = (e: any) => {
        if (e.destination && e.destination.index) {
            api.get(`/v1/moment/${moment.id}/sector/lists`).then((response) => {
                api
                    .patch(`/v1/moment/${moment.id}/sector/lists/${response.data[0].id}/${e.draggableId}`, {
                        partial: {
                            index: e.destination.index + 1
                        }
                    })
                    .then(() => {
                        fetch();
                    });
            });
        }
    };

    const [name, setName] = useState("");

    return (
        <Modal title={t("bucketlist")} show={show} setShow={setShow}>
            <div className="flex flex-col">
                <label htmlFor="name" className="font-black mb-1">
                    {t("add_entry")}
                </label>
                <input
                    name="name"
                    className="border-2 outline-none border-border-grey rounded-2xl py-3 px-4 placeholder:font-black mb-2"
                    type="text"
                    placeholder={`${t("goal")}...`}
                    required
                    value={name}
                    onChange={(e) => setName(e.currentTarget.value)}
                />
                <Button onClick={handleAdd} title={t("add")}/>
            </div>
            <hr/>
            <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="bucketlist">
                    {(provided) => (
                        <ul
                            className="mx-auto"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                        >
                            {bucketList.map((item: any, index: number) => (
                                <Draggable
                                    key={item.index}
                                    draggableId={String(item.index)}
                                    index={index}
                                >
                                    {(provided, snapshot) => (
                                        <li
                                            style={
                                                snapshot.isDragging
                                                    ? {
                                                        translate: 0,
                                                    }
                                                    : {}
                                            }
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <div className="flex mb-2 justify-between items-center space-x-2 text-left">
                                                <IconGripVertical className="w-12"/>
                                                <p
                                                    className={`text-base w-40 justify-self-start md:text-xl font-medium `}
                                                >
                                                    {item.name}
                                                </p>
                                                <button
                                                    className="w-12"
                                                    onClick={() => handleDelete(item.index)}
                                                >
                                                    <IconTrash/>
                                                </button>
                                            </div>
                                        </li>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </ul>
                    )}
                </Droppable>
            </DragDropContext>
        </Modal>
    );
};

export default EditBucketListModal;
