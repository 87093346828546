import React, { useContext, useEffect, useState } from "react";
import Modal from "@components/ui/Modal";
import Button from "@components/ui/Button";
import api from "@services/api.service";
import { Context } from "../../../index";
import authService from "@services/auth.service";
import { useTranslation } from "react-i18next";

const EditLetterModal = ({ fetch, show, setShow, l1, l2 }: any) => {
  const [letter1, setLetter1] = useState(l1);
  const [letter2, setLetter2] = useState(l2);

  const { moment }: any = useContext(Context);

  const { t } = useTranslation<"translation", undefined>();

  useEffect(() => {
    setLetter1(l1);
    setLetter2(l2);
  }, [l1, l2]);

  const handleEditLetters = () => {
    api
      .post(`/v1/moment/${moment.id}/sector/letters`, {
        userId: moment.users[0].id,
        content: letter1,
      })
      .then(() => {
        api
          .post(`/v1/moment/${moment.id}/sector/letters`, {
            userId: moment.users[1] ? moment.users[1].id : null,
            content: letter2,
          })
          .then(() => {
            fetch();
            setShow(false);
          });
      });
  };

  if (!moment) {
    return null;
  }

  return (
    <Modal title={t("news")} show={show} setShow={setShow}>
      <div className="flex flex-col">
        {authService.getUser()?.id == moment.users[0]?.id && (
          <>
            <label htmlFor="l1" className="font-black mb-1">
              {t("message_for")}{" "}
              {moment.users[1] && moment.users[1].name
                ? moment.users[1].name
                : moment.guser
                ? moment.guser.name
                : ""}
            </label>
            <textarea
              name="l1"
              className="border-2 outline-none border-border-grey rounded-2xl py-3 px-4 placeholder:font-black mb-2"
              placeholder={`${t("leave_a_message_for")} ${
                moment.users[1] && moment.users[1].name
                  ? moment.users[1].name
                  : moment.guser
                  ? moment.guser.name
                  : ""
              }`}
              required
              value={letter1}
              onChange={(e) => setLetter1(e.currentTarget.value)}
            />
          </>
        )}
        {(authService.getUser()?.id == moment.users[1]?.id || moment.guser) && (
          <>
            <label htmlFor="l2" className="font-black mb-1">
              {t("message_for")} {moment.users[0].name}
            </label>
            <textarea
              name="l2"
              className="border-2 outline-none border-border-grey rounded-2xl py-3 px-4 placeholder:font-black mb-2"
              placeholder={`${t("leave_a_message_for")} ${
                moment.users[0].name
              }`}
              required
              value={letter2}
              onChange={(e) => setLetter2(e.currentTarget.value)}
            />
          </>
        )}
        <Button onClick={handleEditLetters} title={t("save")} />
      </div>
    </Modal>
  );
};

export default EditLetterModal;
