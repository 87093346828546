import apiService from "@services/api.service";
import {toast} from "react-toastify";
import i18n from "i18next";

export const Form = ({
                         datum,
                         rel_datum,
                         store,
                         rel_type,
                         card_edit,
                         postSubmit,
                         card_display,
                         edit: {modal, open, close}
                     }: any) => {

    const t = i18n.t;

    const getEditFields = (card_edit: any) => {
        return card_edit.map((d: any) => (
            d.type === 'text'
                ? `<label>${d.placeholder}</label><input class="border-2 outline-none border-border-grey rounded-2xl py-3 px-4 placeholder:font-black mb-2 w-full" type="text" name="${d.key}" placeholder="${d.placeholder}" value="${datum.data[d.key] || ""}">`
                : d.type === 'textarea'
                    ? `<textarea class="materialize-textarea" name="${d.key}" placeholder="${d.placeholder}">${datum.data[d.key] || ""}</textarea>`
                    : d.type === "date" ? `<label>${d.placeholder}</label><input class="border-2 outline-none border-border-grey rounded-2xl py-3 px-4 placeholder:font-black mb-2 w-full" type="date" max="${new Date()}" name="${d.key}" placeholder="${d.placeholder}" value="${datum.data[d.key] || ""}">` : ''
        )).join('<br/>')
    }

    const selectParent = () => {
        return (`
          <div>
            <label>${t('other_parent')}</label>
            <select class="border-2 outline-none border-border-grey rounded-2xl py-3 px-4 placeholder:font-black mb-1 w-full" name="other_parent" style="display: block">
              ${(!rel_datum.rels.spouses || rel_datum.rels.spouses.length === 0) ? "" :
            rel_datum.rels.spouses.map((sp_id: any, i: number) => {
                const spouse = store.getData().find((d: any) => d.id === sp_id)
                return (`<option value="${sp_id}" ${i === 0 ? 'selected' : ''}>${card_display[0](spouse)} ${card_display[1](spouse)}</option>`)
            }).join("\n")}
              <option value="_new">${t('new')}</option>
            </select>
          </div>
        `)
    }

    modal.innerHTML = (`
      <div>
      <form>
            <label>Bild</label>
          <input class="border-2 w-full outline-none border-border-grey rounded-2xl py-3 px-4 placeholder:font-black mb-2" type="file" accept="image/jpeg,image/png" alt="Avatar" name="avatar"><br/>
          ${getEditFields(card_edit)}
          ${(rel_type === "son" || rel_type === "daughter") ? selectParent() : ""}
          <br><br>
            <button class="delete w-full mx-auto flex justify-center items-center bg-red-700 text-white py-3 rounded-xl px-8 mb-2">${t('delete')}</button>
            <button class="delete_avatar w-full mx-auto flex justify-center items-center bg-red-700 text-white py-3 rounded-xl px-8 mb-2">${t('clear_image')}</button>
          <button class="w-full mx-auto flex justify-center items-center bg-black text-white py-3 rounded-xl px-8" type="submit">${t('confirm')}</button>
        </form>
       </div>
    `);

    modal.querySelector("form").addEventListener("submit", (e: any) => {
        e.preventDefault();
        if (e.target) {
            let data: any = {};
            new FormData(e.target as HTMLFormElement).forEach((v: any, k) => {
                data[k] = v;
            });

            for (let k in data) {
                if (data.hasOwnProperty(k)) {
                    if (k != "avatar") {
                        datum.data[k] = data[k]
                    }
                }
            }

            if (data["avatar"].name != "" && data["avatar"].size > 0) {
                let momentId = location.pathname.split("/")[1];
                let fd = new FormData();
                fd.append("file", data["avatar"]);

                const promise = new Promise((resolve: any, reject: any) => {
                    apiService.post(`/v1/moment/${momentId}/sector/tree/avatar`, fd).then((response) => {
                        if (response.data && response.data.url) {
                            datum.data["avatar"] = encodeURI(response.data.url);
                        }
                    }).then(() => {
                        close();
                        postSubmit();
                        resolve();
                    }).catch((e) => {
                        if (e.response.data.statusCode == 413) {
                            reject();
                        }
                    })
                })
                toast.promise(promise, {
                    success: `${t('image_uploaded_successfully')}`,
                    pending: `${t("uploading_image_please_wait")}`,
                    error: `${t("this_image_is_too_big")}`
                }, {
                    position: "bottom-right"
                })
            } else {
                close();
                postSubmit();
            }
        }
    });

    modal.querySelector(".delete").addEventListener('click', () => {
        close();
        postSubmit({delete: true});
    });

    modal.querySelector(".delete_avatar").addEventListener('click', () => {
        datum.data["avatar"] = null;

        close();
        postSubmit();
    });

    open();
}