import Modal from "@components/ui/Modal";
import ImageUploader from "@components/upload/ImageUploader";
import React from "react";

const ChangeAvatarModal = ({show, setShow}: any) => {

    const handleUpload = () => {
        window.location.reload();
    }

    return (
        <Modal show={show} setShow={setShow}>
            <ImageUploader id={1} endpoint="/v1/user/avatar" onFinish={handleUpload}/>
        </Modal>
    )
}

export default ChangeAvatarModal;