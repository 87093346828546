import React, {useContext} from "react";
import {Context} from "../../index";

const Overlay = () => {
    const {overlay}: any = useContext(Context);

    return (
        <div>
            {overlay ? (<div className="fixed bg-black bg-opacity-60 w-full min-h-screen z-30"/>) : null}
        </div>
    );
};

export default Overlay;
