import React from "react";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    title?: any;
    content?: any;
    modifiers?: any;
}

const Button = ({...props}: ButtonProps) => {
    return (
        <button
            {...props}
            className={`w-full mx-auto flex justify-center items-center bg-black text-white py-3 rounded-xl px-8 ${props.modifiers}`}>
            {props.content ? props.content : <span className="text-md">{props.title}</span>}
        </button>
    )
}

export default Button;