import Title from "@components/moment/settings/Title";
import React, { useContext, useEffect, useState } from "react";
import { Context } from "../../index";
import api from "@services/api.service";
import ProtectedImage from "@components/auth/ProtectedImage";
import EditLetterModal from "@components/moment/modals/EditLetterModal";
import { isDemo, longestWord } from "@services/utils.service";
import { useTranslation } from "react-i18next";

const Letters = () => {
  const { moment }: any = useContext(Context);

  const [showEditLetter, setShowEditLetter] = useState(false);

  const [letter1, setLetter1] = useState("");
  const [letter2, setLetter2] = useState("");

  const { t } = useTranslation<"translation", undefined>();

  const ltr1 = t("your_team_spirit_solidarity");
  const ltr2 = t("your_sense_of_adventure");

  useEffect(() => {
    if (moment) {
      if (isDemo()) {
        setLetter1(ltr1);
        setLetter2(ltr2);
        return;
      }
      fetchLetters();
    }
  }, [moment]);

  const fetchLetters = () => {
    api.get(`/v1/moment/${moment.id}/sector/letters`).then((response: any) => {
      if (response.data && response.data.length >= 1) {
        let l1 = response.data.find((l: any) => {
          return l.userId == moment.users[0].id;
        });
        if (l1) {
          setLetter1(l1.content);
        }
        if (moment.users[1]) {
          let l2 = response.data.find((l: any) => {
            return l.userId == moment.users[1].id;
          });
          if (l2) {
            setLetter2(l2.content);
          }
        } else {
          let l2 = response.data.find((l: any) => {
            return l.userId == null;
          });
          if (l2) {
            setLetter2(l2.content);
          }
        }
      }
    });
  };

  if (!moment) {
    return null;
  }

  return (
    <>
      <EditLetterModal
        show={showEditLetter}
        setShow={setShowEditLetter}
        fetch={fetchLetters}
        l1={letter1}
        l2={letter2}
      />
      <div className="wrapper">
        <div className="flex justify-between items-end py-6">
          <Title title={t("news")} />
          {!isDemo() &&
            (moment.users.length == 2 ||
              (moment.users.length == 1 && moment.guser)) && (
              <button>
                <p
                  onClick={() => setShowEditLetter(true)}
                  className="underline font-gilroy font-black text-lg md:text-xl"
                >
                  {t("edit")}
                </p>
              </button>
            )}
        </div>

        {moment.users.length != 2 &&
          !(moment.users.length == 1 && moment.guser) && (
            <p className="font-bold text-sm">
              {t("add_your_partner_to_get_started")}
            </p>
          )}
        {(moment.users.length == 2 ||
          (moment.users.length == 1 && moment.guser)) && (
          <>
            <div className="relative bg-[#f8f8f8] flex items-center space-x-4 mb-8 rounded-[13px] p-4 md:p-6 md:px-8">
              <ProtectedImage
                url={moment.users[0].avatar}
                className="w-20 h-20 rounded-full border-4 border-white object-cover"
              />
              <div className="max-w-[70%]">
                <h2
                  className={`md:text-xl ${
                    longestWord(letter1).length > 20
                      ? "break-all"
                      : "break-words"
                  } hyphens-auto`}
                >
                  {!letter1 && <>{t("leave_a_message")}</>}
                  {letter1}
                </h2>
                <p className="text-sm md:text-base text-[#666666]">
                  {moment.users[0].name}
                </p>
              </div>
            </div>

            <div className="relative bg-[#f8f8f8] flex items-center space-x-4 rounded-[13px] p-4 md:px-8 mb-4">
              <div className="max-w-[70%]">
                <h2
                  className={`md:text-xl ${
                    longestWord(letter2).length > 20
                      ? "break-all"
                      : "break-words"
                  } hyphens-auto`}
                >
                  {!letter2 && <>{t("leave_a_message")}</>}
                  {letter2}
                </h2>
                <p className="text-sm md:text-base text-[#666666]">
                  {moment.users.length == 2 && <>{moment.users[1].name}</>}
                  {moment.users.length == 1 && moment.guser && (
                    <>{moment.guser.name}</>
                  )}
                </p>
              </div>

              {moment.users.length == 2 && (
                <ProtectedImage
                  url={moment.users[1].avatar}
                  className="w-20 h-20 rounded-full border-4 border-white object-cover"
                />
              )}
              {moment.users.length == 1 &&
                moment.guser &&
                moment.guser.image && (
                  <ProtectedImage
                    url={moment.guser.image.url}
                    className="w-20 h-20 rounded-full border-4 border-white object-cover"
                  />
                )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Letters;
