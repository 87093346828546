import PageTitle from "@components/ui/PageTitle";
import React from "react";

const PrivacyPage = () => {
    return (
        <div className="w-full bg-white relative pb-8 mt-5">
            <PageTitle noBack={true} title={"Datenschutzerklaerung"}/>
            <div className="mx-10 text-lg">
                <p className="text-xl font-bold">Information über die Verarbeitung personenbezogener Daten</p><br/>
                Wir, die Infinity Moments (nachfolgend auch: "Infinity Moments", "wir" oder "uns") möchten Sie an dieser
                Stelle über den Datenschutz in unserem Unternehmen und auf unserer Internetpräsenz
                https://infinity.moments.name informieren.<br/>
                Diese Datenschutzinformation ist modular aufgebaut. Sie besteht aus einem Allgemeinen Teil, welcher
                stets Anwendung findet und allgemeine Informationen für alle unsere Datenverarbeitungen angibt.<br/>
                Anschließend folgen die Informationen zur Datenverarbeitung, die auf spezielle Situationen mit der
                Bezeichnung des jeweiligen Angebots zugeschnitten sind, etwa die Verarbeitung auf der Webseite.<br/>
                Nachfolgend sehen Sie einen Überblick der Untergliederung dieser Information, mit der Sie die für Sie
                relevanten Teile finden können:<br/>
                <br/>
                <strong>Teil A: Allgemeines</strong>
                <br/>
                Dieser Teil ist stets relevant für Sie und gilt für jede Datenverarbeitung durch uns.<br/>
                <br/>
                <strong>Teil B: Webseite/Web-App</strong><br/>
                Dieser Teil ist relevant für Sie, wenn Sie unsere Webseite https://infinity.moments.name besuchen.<br/>
                <br/>
                <p className="text-xl font-bold underline">A. Allgemeines</p><br/>
                <strong>1. Begriffsbestimmungen</strong><br/>
                Wir verwenden in dieser Datenschutzinformationen die Begriffe des Art. 4 der
                EU-Datenschutz-Grundverordnung (Verordnung (EU) 2016/679; nachfolgend: „DS-GVO“).<br/>
                <br/>
                <strong>2. Verantwortlichkeit</strong><br/>
                Die für die Verarbeitung Ihrer Daten verantwortliche Stelle im Sinne des Art. 4 Nr. 7 DS-GVO sind
                wir:<br/>
                <br/>
                Florian Bauböck<br/>
                Schnitterweg 31<br/>
                65375 Oestrich Winkel<br/>
                +49 1567-9294505<br/>
                info@infinity-moments.de<br/>
                <br/>
                Weitere Angaben zu unserem Unternehmen entnehmen Sie bitte den Impressumsangaben auf unserer<br/>
                Internetseite [<a
                href="https://infinity-moments.com/policies/legal-notice">https://infinity-moments.com/policies/legal-notice</a>].<br/>
                <br/>
                <strong>3. Datenschutzbeauftragter</strong><br/>
                Derzeit besteht nach Maßgabe der DS-GVO für uns keine Pflicht zur Bestellung eines
                Datenschutzbeauftragten. Sobald dies erforderlich wird, werden wir hier die Kontaktdaten angeben.<br/>
                <br/>
                <strong>4. Erlaubnisgründe der Datenverarbeitung</strong><br/>
                Die DS-GVO erlaubt eine Verarbeitung von Daten nur, wenn einer folgenden Erlaubnisgründe
                (Rechtsgrundlagen) vorliegt:<br/>
                • Art. 6 Abs. 1 S. 1 lit. a DS-GVO ("Einwilligung"): Wenn der Betroffene freiwillig, in informierter
                Weise und unmissverständlich durch eine Erklärung oder eine sonstige eindeutige bestätigende Handlung zu
                verstehen gegeben hat, dass er mit der Verarbeitung der ihn betreffenden Daten für einen oder mehrere
                bestimmte Zwecke einverstanden ist;<br/>
                • Art. 6 Abs. 1 S. 1 lit. b DS-GVO: Wenn die Verarbeitung zur Erfüllung eines Vertrags, dessen
                Vertragspartei der Betroffene ist, oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist,
                die auf die Anfrage des Betroffenen erfolgen;<br/>
                • Art. 6 Abs. 1 S. 1 lit. c DS-GVO: Wenn die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung
                erforderlich ist, der der Verantwortliche unterliegt (z. B. eine gesetzliche Aufbewahrungspflicht);<br/>
                • Art. 6 Abs. 1 S. 1 lit. d DS-GVO: Wenn die Verarbeitung erforderlich ist, um lebenswichtige Interessen
                des Betroffenen oder einer anderen natürlichen Person zu schützen;<br/>
                • Art. 6 Abs. 1 S. 1 lit. e DS-GVO: Wenn die Verarbeitung für die Wahrnehmung einer Aufgabe erforderlich
                ist, die im öffentlichen Interesse liegt oder in Ausübung öffentlicher Gewalt erfolgt, die dem
                Verantwortlichen übertragen wurde oder<br/>
                • Art. 6 Abs. 1 S. 1 lit. f DS-GVO ("Berechtigte Interessen"): Wenn die Verarbeitung zur Wahrung
                berechtigter (insbesondere rechtlicher oder wirtschaftlicher) Interessen des Verantwortlichen oder eines
                Dritten erforderlich ist, sofern nicht die gegenläufigen Interessen oder Rechte des Betroffenen
                überwiegen, insbesondere dann, wenn es sich bei der betroffenen Person um ein Kind handelt.
                Bei den nachfolgend von uns genannten Datenverarbeitungen werden wir aus der obigen Aufzählung die
                jeweils anwendbare Rechtsgrundlage nennen. Eine Verarbeitung kann auch auf mehreren Rechtsgrundlagen
                beruhen.<br/>
                <br/>
                <strong>5. Speicherdauer und Speicherfristen</strong><br/>
                Bei unseren Datenverarbeitungen geben wir nachfolgend in der Regel jeweils an, wie lange die Daten
                gespeichert und wann sie gelöscht oder gesperrt werden. Wenn nachfolgend keine Speicherfrist angegeben
                wird, werden Ihre Daten gelöscht oder gesperrt, sobald der Zweck oder die Rechtsgrundlage für die
                Speicherung entfällt. Eine Speicherung Ihrer Daten erfolgt grundsätzlich nur auf unseren Servern in
                Frankfurt, München und Nürnberg in Deutschland, vorbehaltlich einer ggf. erfolgenden Weitergabe nach den
                Regelungen in A. (7) und A. (8).<br/>
                Eine Speicherung kann über die angegebene Zeit hinaus im Falle einer (drohenden) Rechtsstreitigkeit mit
                Ihnen oder eines sonstigen rechtlichen Verfahrens erfolgen oder wenn die Speicherung durch gesetzliche
                Vorschriften für uns vorgeschrieben ist (z.B. § 147 AO, § 257 HGB). Nach Ablauf der gesetzlich
                vorgeschriebenen Speicherfrist erfolgt eine Sperrung oder Löschung der Daten, es sei denn, dass eine
                weitere Speicherung durch uns erforderlich ist, wenn dafür eine Rechtsgrundlage besteht.<br/>
                <br/>
                <strong>6. Datensicherheit</strong><br/>
                Wir und die durch uns (auch auf Einhaltung des Datenschutzrechts) verpflichteten Auftragnehmer bedienen
                uns geeigneter technischer und organisatorischer Sicherheitsmaßnahmen, um Ihre Daten gegen zufällige
                oder vorsätzliche Manipulationen, teilweisen oder vollständigen Verlust, Zerstörung oder gegen den
                unbefugten Zugriff Dritter zu schützen (z.B. TLS-Verschlüsselung für unsere Webseite) unter
                Berücksichtigung des Stands der Technik, der Implementierungskosten und der Natur, des Umfangs, des
                Kontextes und des Zwecks der Verarbeitung sowie der bestehenden Risiken einer Datenpanne (inklusive von
                deren Wahrscheinlichkeit und Auswirkungen) für den Betroffenen. Unsere Sicherheitsmaßnahmen werden
                entsprechend der technologischen Entwicklung fortlaufend verbessert.<br/>
                <br/>
                <strong>7. Zusammenarbeit mit Auftragsverarbeitern und Übermittlung an andere Dritte</strong><br/>
                Wie jedes Unternehmen, setzen auch wir zur Abwicklung unseres Geschäftsverkehrs externe in- und
                ausländische Dienstleister ein (z. B. für die Bereiche IT, Logistik, Telekommunikation, Vertrieb und
                Marketing). Diese werden nur nach unserer Weisung tätig und wurden gemäß Art. 28 DS-GVO vertraglich
                (Auftragsverarbeitungsvertrag) dazu verpflichtet, die datenschutzrechtlichen Bestimmungen einzuhalten.
                Zudem geben wir an, an welche andere Dritte Daten übermittelt werden können oder müssen. Wenn
                nachfolgend nicht anders oder weitere Dritte beschrieben sind, kommen dabei folgende Kategorien von
                Dritten in Betracht:<br/>
                • Staatliche Stellen/Behörden, soweit dies zur Erfüllung einer gesetzlichen Verpflichtung erforderlich
                ist. Rechtsgrundlage für die Weitergabe ist dann Art. 6 Abs. 1 S. 1 lit. c DS-GVO;<br/>
                • Zur Durchführung unseres Geschäftsbetriebs eingesetzte Personen (z.B. Auditoren, Banken,
                Versicherungen, Rechtsberater, Aufsichtsbehörden, Beteiligte bei Unternehmenskäufen oder der Gründung
                von Gemeinschaftsunternehmen). Rechtsgrundlage für die Weitergabe ist dann Art. 6 Abs. 1 S. 1 lit. b
                oder lit. f DS-GVO.<br/>
                <br/>
                <strong>8. Voraussetzungen der Weitergabe von Daten in Drittländer</strong><br/>
                Im Rahmen unserer Geschäftsbeziehungen können Ihre Daten an dritte Unternehmen weitergegeben oder
                offengelegt werden. Diese können sich auch außerhalb des Europäischen Wirtschaftsraums (EWR), also in
                Drittländern, befinden. Eine derartige Verarbeitung erfolgt ausschließlich zur Erfüllung der
                vertraglichen und geschäftlichen Verpflichtungen und zur Pflege Ihrer Geschäftsbeziehung zu uns. Über
                die jeweiligen Einzelheiten der Weitergabe unterrichten wir Sie nachfolgend an den dafür relevanten
                Stellen.<br/>
                Einigen Drittländern bescheinigt die Europäische Kommission durch sog. Angemessenheitsbeschlüsse einen
                Datenschutz, der dem EWR-Standard vergleichbar ist (eine Liste dieser Länder sowie eine Kopie der
                Angemessenheitsbeschlüsse erhalten Sie hier:<br/>
                http://ec.europa.eu/justice/data-protection/international-transfers/adequacy/index_en.html).
                In anderen Drittländern, in die ggf. Daten übertragen werden, herrscht aber unter Umständen wegen
                fehlender gesetzlicher Bestimmungen kein durchgängig hohes Datenschutzniveau. Soweit dies der Fall ist,
                achten wir darauf, dass der Datenschutz ausreichend gewährleistet ist. Möglich ist dies über bindende
                Unternehmensvorschriften (Binding Corporate Rules“), Standard-Datenschutzklauseln der Europäischen
                Kommission zum Schutz Daten, Zertifikate oder anerkannte Verhaltenskodizes.<br/>
                <br/>
                <strong>9. Keine automatisiere Entscheidungsfindung (einschließlich Profiling)</strong><br/>
                Infinity Moments hat nicht die Absicht, Ihre Daten für ein Verfahren zur automatisierten
                Entscheidungsfindung (einschließlich Profiling) zu verwenden.<br/>
                <br/>
                <strong>10. Keine Pflicht zur Bereitstellung Daten</strong><br/>
                Sofern Sie unsere Dienste informatorisch nutzen, sind Sie grundsätzlich weder vertraglich noch
                gesetzlich verpflichtet uns Ihre Daten bereitzustellen. Es kann allerdings sein, dass wir bestimmte
                Angebote nur eingeschränkt oder gar nicht erbringen können, wenn Sie die dafür erforderlichen Daten
                nicht bereitstellen.<br/>
                <br/>
                <strong>11. Gesetzliche Pflicht zur Übermittlung bestimmter Daten</strong><br/>
                Wir können unter Umständen einer besonderen gesetzlichen oder rechtlichen Verpflichtung unterliegen, die
                rechtmäßig verarbeiteten Daten für Dritte, insbesondere öffentlichen Stellen, bereitzustellen (Art. 6
                Abs. 1 S. 1 lit. c DS-GVO).<br/>
                <br/>
                <strong>12. Ihre Rechte</strong><br/>
                Als Nutzer unseres Internet-Angebots haben Sie nach der DS-GVO verschiedene Rechte, die sich
                insbesondere aus den Art. 15 bis 18, 20, 21, 77 DS-GVO ergeben. Die nachfolgend genannten Rechte können
                Sie jederzeit uns gegenüber (Kontakt siehe A. (2)) geltend machen:<br/>
                <br/>
                a. Recht auf Auskunft:<br/>
                Sie können Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten verlangen. In Ihrem
                Auskunftsantrag sollten Sie Ihr Anliegen präzisieren, um uns das Zusammenstellen der erforderlichen
                Daten zu erleichtern.<br/>
                <br/>
                b. Recht auf Berichtigung:<br/>
                Sollten die Sie betreffenden Angaben nicht (mehr) zutreffend sein, können Sie eine Berichtigung
                verlangen. Sollten Ihre Daten unvollständig sein, können Sie eine Vervollständigung verlangen.<br/>
                <br/>
                c. Recht auf Löschung:<br/>
                Sie können unter den Bedingungen des Art. 17 DS-GVO die Löschung Ihrer personenbezogenen Daten
                verlangen. Ihr Anspruch auf Löschung hängt u. a. davon ab, ob die Sie betreffenden Daten von einem
                Aufbewahrungsrecht gedeckt sind oder von uns zur Erfüllung unserer gesetzlichen Pflichten noch benötigt
                werden und so weit nicht die Verarbeitung zur Ausübung des Rechts auf freie Meinungsäußerung und
                Information, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder Verteidigung
                von Rechtsansprüchen erforderlich ist;<br/>
                <br/>
                d. Recht auf Einschränkung der Verarbeitung:<br/>
                Sie haben im Rahmen der Vorgaben des Art. 18 DSGVO das Recht, eine Einschränkung der Verarbeitung der
                Sie betreffenden Daten zu verlangen.<br/>
                <br/>
                e. Recht auf Widerspruch:<br/>
                Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit der
                Verarbeitung der Sie betreffenden Daten zu widersprechen, wenn eine Verarbeitung auf Grundlage des Art.
                6 Abs. 1 S. 1 lit. e oder lit. f DS-GVO erfolgt. Dies gilt insbesondere, wenn wir aus einem berechtigtem
                Interesse Ihre Daten verarbeiten, welches in der jeweiligen Situation überwiegt. Dem Widerspruch können
                wir des Weiteren nicht immer nachkommen, z. B. wenn wir verpflichtet sind, die Daten nach den
                Vorschriften des Steuerrechts zu Prüfungszwecken archievieren müssen oder weil die Erfüllung eines
                Vertrages im Raum steht. Sofern es sich nicht um einen Widerspruch gegen Direktwerbung handelt, bitten
                wir bei Ausübung eines solchen Widerspruchs um die Darlegung der Gründe, weshalb wir Ihre Daten nicht
                wie von uns durchgeführt verarbeiten sollen. Im Falle Ihres begründeten Widerspruchs prüfen wir die
                Sachlage und werden entweder die Datenverarbeitung einstellen bzw. anpassen oder Ihnen unsere zwingenden
                schutzwürdigen Gründe aufzeigen, aufgrund derer wir die Verarbeitung fortführen<br/>
                <br/>
                f. Recht auf Widerruf<br/>
                Sofern Sie uns eine Einwilligung in die Verarbeitung Ihrer Daten erteilt haben, haben Sie das Recht, die
                Einwilligung jederzeit zu widerrufen. Hierdurch wird die Rechtmäßigkeit der aufgrund der Einwilligung
                bis zum Widerruf der erfolgten Verarbeitung nicht berührt.<br/>
                <br/>
                g. Recht auf Datenübertragbarkeit<br/>
                Sie haben das Recht, die sie betreffenden Daten, die Sie uns bereitgestellt haben, in einem
                strukturierten, gängigen und maschinenlesbaren Format zu erhalten. Sofern Sie die Daten aufgrund einer
                Einwilligung oder aufgrund eines Vertrages bereitgestellt haben, haben Sie das Recht, dass wir die Daten
                zu einem von Ihnen benannten Verantwortlichen übertragen, soweit die Verarbeitung mithilfe
                automatisierter Verfahren erfolgt ist und es technisch machbar ist.<br/>
                <br/>
                h. Recht auf Beschwerde:<br/>
                Wir haben jederzeit ein offenes Ohr für Ihr Anliegen, melden Sie sich daher sehr gerne unter der in A.
                (2) genannten Kontaktmöglichkeit.<br/>
                Wenn Sie der Auffassung sind, dass wir bei der Verarbeitung Ihrer Daten datenschutzrechtliche
                Vorschriften nicht beachtet haben, können Sie sich auch mit einer Beschwerde an eine
                Datenschutz-Aufsichtsbehörde wenden, die Ihre Beschwerde prüfen wird.<br/>
                Die Kontaktdaten der für uns zuständigen Datenschutzbehörde lauten wie folgt:<br/>
                Der Hessische Beauftragte für Datenschutz und Informationsfreiheit<br/>
                Gustav-Stresemann-Ring 1<br/>
                65189 Wiesbaden<br/>
                <br/>
                Telefon: 0611-1408 0<br/>
                E-Mail: poststelle@datenschutz.hessen.de<br/>
                <br/>
                <strong>13. Änderungen der Datenschutzinformation und Stand</strong><br/>
                Wenn sich das Datenschutzrecht fortentwickelt sowie wegen technischer oder organisatorischer
                Veränderungen werden wir unsere Datenschutzhinweise regelmäßig auf Anpassungs- oder Ergänzungsbedarf hin
                überprüfen. Über Änderungen werden Sie insbesondere auf unserer deutschen Webseite unter
                https://infinity.moments.name/privacy unterrichtet.<br/>
                <br/>
                Diese Datenschutzhinweise haben den Stand von Dezember 2023.<br/>
                <br/>
                <p className="text-xl font-bold underline">B. Datenverarbeitung auf Webseiten</p>
                <br/>
                <strong>1. Gegenstand</strong><br/>
                Unsere Leistungen erhalten Sie insbesondere unter https://infinity.moments.name samt den dazugehörigen
                Unterseiten (nachfolgend: "Webseiten"). Bei einem Besuch unserer Webseiten und weiteren Webpräsenzen
                werden die unten genannten Datenverarbeitungen durchgeführt. Nachfolgende Informationen geben Auskunft
                darüber, wie wir die Daten auf unseren Webseiten verarbeiten.<br/>
                <br/>
                <strong>2. Verarbeitete Daten</strong><br/>
                a. "Protokolldaten": Bei der rein informatorischen Nutzung der Webseiten werden die folgenden
                Kategorien von Daten durch uns verarbeitet:<br/>
                • dem Datum und der Uhrzeit Ihres Besuchs<br/>
                • der IP-Adresse des anfragenden Rechners<br/>
                • Informationen über den vom Nutzer verwendeten Browser<br/>
                <br/>
                b. „Kundendaten“: Bei der Nutzung der Webseiten, die Sie als Kunde/Nutzer mit einem Login vornehmen
                werden folgende Daten bei Registrierung und weiteren Nutzung der Webseiten als Kunde/Nutzer
                gespeichert:<br/>
                • Der vom Nutzer selbst festgelegte Nutzername<br/>
                • Die vom Nutzer angegebene E-Mail-Adresse<br/>
                • Das vom Nutzer angegebene Passwort initial verschlüsselt<br/>
                • Das Datum, an dem das Passwort zuletzt geändert wurde<br/>
                • Die URL zu dem vom Nutzer festgelegten Profilbild und anderer hochgeladener Bilder<br/>
                • Das Datum, an dem der Nutzer seinen Account verifiziert hat, also auf den Verifizierungslink in der
                von uns beim Registrieren verschickten Mail geklickt hat<br/>
                • Das Datum, an dem der Nutzer erstellt wurde<br/>
                • Das Datum, an dem irgendeine von den oben vorgenannten Informationen zuletzt geändert wurde (nur die
                obigen Informationen, nicht die nachfolgenden)<br/>
                • Wir können jeden Nutzer auf eine Bestell-ID aus Shopify zurückfolgen, hier wird allerdings auch
                nur
                die Bestell-ID also eine Zahlenfolge gespeichert und keinerlei Informationen zu der Bestellung
                selbst,
                außer was für eine Art von Produkt gekauft wurde also welcher Artikel<br/>
                • Im Übrigen hat jeder Nutzer die Möglichkeit Bilder hochzuladen und Texte bzw. einen Link oder ein
                Datum einzugeben. Diese werden ebenfalls in der Datenbank gespeichert.<br/>
                • Zu allen Bilder werden folgende Informationen gespeichert:<br/>
                o Welcher Nutzer hat dieses Bild hochgeladen, zu dem Zweck, dass wir wissen, auf wen wir ein Bild
                zurückführen können<br/>
                o Wann wurde dieses Bild hochgeladen, zu dem Zwecke der Sortierung der Bilder auf der Seite<br/>
                <br/>
                <strong>3. Zweck und Erlaubnisgrund für die Datenverarbeitung</strong><br/>
                Wir verarbeiten die oben näher bezeichneten Daten in Einklang mit den einschlägigen
                Datenschutzvorschriften und nur im erforderlichen Umfang. Soweit die Verarbeitung der Daten auf Art.
                6
                Abs. 1 S. 1 lit. f DS-GVO beruht, stellen die genannten Zwecke zugleich unsere berechtigten
                Interessen
                dar.<br/>
                a. Die Verarbeitung der Protokolldaten ist technisch zwingend notwendig, dient statistischen Zwecken
                und
                der Verbesserung der Qualität unserer Webseite, insbesondere der Stabilität und der Sicherheit der
                Verbindung sowie zur Verfolgung von Rechtswidrigkeiten auf unserer Webseite, insbesondere zur
                Feststellung, Beseitigung und beweissicheren Dokumentation von Störungen (z.B. DDoS-Angriffen)
                (Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. f DS-GVO).<br/>
                b. Die Verarbeitung von Kundendaten erfolgt zum Zwecke der Erfüllung unseres Vertrages mit dem
                Kunden/Nutzer, zur Gewährleistung der Sicherheit und Integrität unserer Webseiten und Leistungen
                sowie
                zum Zwecke der Bearbeitung der freiwillig durch die Kunden/Nutzer an uns gerichteten
                Informationen(Rechtsgrundlage ist Art. 6 Abs. 1 S. 1 lit. a, lit. b und/oder lit. f DS-GVO).<br/>
                <br/>
                <strong>4. Dauer der Datenverarbeitung</strong><br/>
                Ihre Daten werden nur so lange verarbeitet, wie dies für die Erreichung der oben genannten
                Verarbeitungszwecke erforderlich ist; hierfür gelten die im Rahmen der Verarbeitungszwecke
                angegebenen
                Rechtsgrundlagen entsprechend.<br/>
                Von uns eingesetzte Dritte werden Ihre Daten auf deren System so lange speichern, wie es im
                Zusammenhang
                mit der Erbringung der Leistungen für uns entsprechend dem jeweiligen Auftrag erforderlich ist.<br/>
                Die oben in Ziffer B 2. a. genannten Server-Logfiles werden für 8 Wochen gespeichert und
                anschließend gelöscht. Dies dient zur Nachverfolgbarkeit von Angriffen und rechtswidriger Nutzung
                unserer Webseite.<br/>
                Die oben in Ziffer B 2. b. genannten Kontaktdaten werden für die Dauer der Bearbeitung Ihrer Anfrage
                und
                im Falle eines Vertragsschlusses für die Dauer der Vertragslaufzeit und dazugehöriger, gesetzlich
                verpflichtender Aufbewahrungsfristen gespeichert und anschließend gelöscht.
                Weitere Infos zur Speicherdauer finden Sie im Übrigen oben unter A. (5).<br/>
                <br/>
                <strong>5. Übermittlung Daten an Dritte; Rechtfertigungsgrundlage</strong><br/>
                Folgende Kategorien von Empfängern, bei denen es sich im Regelfall um Auftragsverarbeiter handelt
                (siehe
                dazu A. (7)), erhalten ggf. Zugriff auf Ihre Daten:<br/>

                • Dienstleister für den Betrieb unserer Webseite und die Verarbeitung der durch die Systeme
                gespeicherten oder übermittelten Daten (z.B. für Rechenzentrumsleistungen, Zahlungsabwicklungen,
                IT-Sicherheit). Rechtsgrundlage für die Weitergabe ist dann Art. 6 Abs. 1 S. 1 lit. b oder lit. f
                DS-GVO, soweit es sich nicht um Auftragsverarbeiter handelt;<br/>

                Derzeit handelt es sich um den folgenden Hosting-Dienstleister (Serverstandort einzig in München),
                mit
                dem ein Auftragsverarbeitungsvertrag abgeschlossen wurde (siehe oben A. (7)):
                Contabo GmbH (Aschauer Straße 32a in 81549 München), einschließlich der folgender
                Unter-Auftragsverarbeiter, mit denen adesso SE ebenfalls Auftragsverarbeitungsverträge (siehe oben
                A.
                (7)) abgeschlossen hat.<br/>
                • Dienstleister für die Pflege unserer Kontakt-, Firmen- und sonstige Daten, die wir über unsere
                Webpräsenzen, Kontaktformulare, persönlichen Gesprächen, Telefon, E-Mails oder über andere
                Kommunikationswege von Ihnen erhalten, um mit Ihnen in Vertragsmaßnahmen. Rechtsgrundlage für die
                Weitergabe ist Art. 6 Abs. 1 S. 1 lit. a, lit. b oder lit. f DSGVO, soweit es sich nicht um
                Auftragsverarbeiter handelt;<br/>
                • Dienstleister für Content Delivery Network (CDN; sorgt für schnelle Ladezeiten) und Sicherheit
                unserer
                Webapp mittels einer Web Application Firewall, etwa Schutz vor DDoS-Angriffen: Derzeit nutzt unsere
                Webapp die Funktionen von Cloudflare, Inc. („Cloudflare“). Cloudflare ist ein US-amerikanischer
                Dienstleister, der ein Content-Distribution-Network, verschiedene Web-Sicherheitsservices und
                DNS-Dienste anbietet, was unsere Zwecke darstellt. Rechtsgrundlage für den Einsatz ist unser und Ihr
                berechtigtes Interesse, Ihnen eine sichere und performante Webapp anbieten zu können, Art. 6 Abs. 1
                S. 1
                lit. f DSGVO. Mit Cloudflare wurde ein Auftragsverarbeitungsvertrag geschlossen. Cloudflare ist
                zudem
                unter dem Angemessenheitsbeschluss der EU-Kommission mit der USA gemäß Art. 45 DSGVO zertifiziert,
                wie
                unter diesem Link zu sehen ist.<br/>
                Cloudflare erhält Zugriff auf den Datenverkehr zwischen uns und den Besuchern unsere Webapp. Dabei
                erhebt Cloudflare unter anderem Daten zu der abgerufenen Webseite, dem verwendeten Browsertyp, dem
                Betriebssystem, der Referrer-URL, der IP-Adresse und dem anfragenden Provider der User.
                Weitere Informationen zum Datenschutz bei Cloudflare finden Sie in der Datenschutzrichtlinie von
                Cloudflare unter diesem Link.<br/>
                Zu den Gewährleistungen eines angemessenen Datenschutzniveaus bei einer Weitergabe der Daten in
                Drittländer siehe A. (8).<br/>
                Darüber hinaus geben wir Ihre Daten nur an Dritte weiter, wenn Sie nach Art. 6 Abs. 1 S. 1 lit. a
                DS-GVO
                eine ausdrückliche Einwilligung dazu erteilt haben.<br/>
                <br/>
                <strong>6. Weitere Dritte: Einsatz von Plugins, sonstige Dienste und Verlinkungen auf unserer Webseite</strong><br/>
                a. Plugins: Auf unseren Webseiten setzen wir Plugins ein, welche die Dienste von Dritten direkt über
                unsere Webseite abrufbar werden lassen, wobei die Dritten die Verarbeitung von personenbezogenen
                Daten
                gänzlich eigenverantwortlich vornehmen, wenn diese Dienste über die Plugins wie nachfolgend
                erläutert
                genutzt werden: Die Plugins sind mittels einer Zwei-Klick-Lösung auf unserer Webseite eingebaut, das
                heißt, dass diese Plugins der Dritten keine Daten erhalten, solange der Kunde und Nutzer nicht zwei
                Mal
                auf eine Schaltfläche klickt und sich damit mit der Nutzung des Plugins des Dritten und der
                Datenverarbeitung durch den Dritten einverstanden erklärt.<br/>
                Wir weisen darauf hin, dass zunächst die Datenschutzinformationen der Plugin-Anbieter durchzulesen
                ist,
                bevor man die Dienste nutzt. Mit der Betätigung der Zwei-Klick-Lösung erklären Sie sich mit der
                Verarbeitung Ihrer Daten durch den jeweiligen dritten Plugin-Anbieter einverstanden.
                Der Plugin-Anbieter kann seinen Sitz gegebenenfalls auch außerhalb des EWR-Raums, also in
                Drittstaaten
                haben, wo nicht ein gleichwertiges Datenschutzniveau erreicht wird, wie es von der DS-GVO verlangt
                wird
                oder Daten an Behörden und andere staatliche Einrichtungen herausgegeben werden können. Der
                Kunde/Nutzer
                nimmt dies zur Kenntnis und willigt in das Risiko der schlechteren Verarbeitung ein, indem er die
                Zwei-Klick-Handlung vornimmt.<br/>
                <br/>
                • Dienstleister für die Einbettung von Musik-Ausschnitten in unserer Webapp: Derzeit nutzt unsere
                Webapp
                die Funktionen von Spotify AB („Spotify“). Spotify ist ein schwedischer Musik-Streaming-Dienst. Der
                Zweck ist es, Ihnen die Möglichkeit zu geben, dass Sie in Ihrem Profil den Ausschnitt eines
                bestimmten
                Liedes hinterlegen können, mit dem Sie etwa eine schöne Erinnerung verbinden. Rechtsgrundlage für
                den
                Einsatz ist Ihre Einwilligung, indem diese Funktion dadurch aktivieren und nutzen, dass Sie ein Lied
                auswählen, Art. 6 Abs. 1 S. 1 lit. a DSGVO. Mit Spotify wurde ein Auftragsverarbeitungsvertrag
                geschlossen
                Spotify erhält Zugriff auf den Datenverkehr zwischen uns und den Besuchern unsere Webapp. Dabei
                erhebt
                der Anbieter unter anderem Daten zu der abgerufenen Webseite, dem verwendeten Browsertyp, dem
                Betriebssystem, der Referrer-URL, der IP-Adresse und dem anfragenden Provider der User.
                Weitere Informationen zum Datenschutz bei Spotify finden Sie in der Datenschutzrichtlinie von
                Spotify
                unter diesem Link.<br/>
                <br/>
                b. Verlinkungen: Sofern unsere Webseiten Verlinkungen zu Drittanbietern (z.B. TikTok, Instagram)
                enthalten, nutzen wir diese lediglich zur passiven Verlinkung auf die Seiten der jeweiligen
                Anbieter.<br/>
                Hierbei werden keine Daten von Ihnen verarbeitet, solange Sie nicht auf die Verlinkung klicken. Bei
                einem Klick auf jedweder Verlinkung auf unserer Webseite gelangen Sie zur externen Seite des jeweils
                dritten Anbieters, der Ihre Daten gänzlich eigenverantwortlich verarbeitet. Bitte klicken Sie nicht
                auf
                die Verlinkungen auf unserer Webseite, wenn Sie keine Verarbeitung Ihrer Daten durch die dritten
                Anbieter, zu denen wir eine Verlinkung anbieten, wünschen.
                Trotz sorgfältiger inhaltlicher Kontrolle übernimmt Infinity Moments keine Haftung für den Inhalt
                der
                externen Links. Für den Inhalt der verlinkten Seiten ist ausschließlich deren Betreiber
                verantwortlich.<br/>
                Etwaige Links setzen wir, weil wir glauben, dass diese Webseiten für Sie interessant sein könnten.
                Auf
                den Inhalt und die Gestaltung dieser Webseiten haben wir weder Einfluss, noch können wir
                kontrollieren,
                wie der Anbieter der verlinkten Webseiten mit Ihren Daten umgeht, sodass diese Datenschutzerklärung
                und
                unser Verantwortungsbereich sich nicht auf diese Webseiten erstrecken; insbesondere kann keine
                Haftung
                für die Inhalte der verlinkten Webseiten übernommen werden. Wenn Sie Fragen hierzu haben, setzen Sie
                sich bitte direkt mit dem Anbieter der jeweiligen Webseiten in Verbindung.<br/>
                <br/>
                c. Zweck und Rechtfertigungsgrund: Der Einsatz der Plugins und der Verlinkung erfolgt zu dem Zweck,
                ihr
                Erlebnis auf der Webseite besser zu machen und Ihnen die Möglichkeit zu geben, Ihren persönlichen
                Account auf unserer Webseite individuell zu gestalten.
                Rechtsgrundlage für die Nutzung der Plugins und der Verlinkung ist Ihre Einwilligung, die Sie
                mittels
                den jeweiligen Klicks auf den jeweiligen Schaltflächen erklären, Art. 6 Abs. 1 lit. a. DS-GVO.
            </div>
        </div>
    )
}

export default PrivacyPage;