import React from "react";
import Title from "@components/ui/Title";

export const Dialog = ({children, loading, back, title, padding = 8}: {
    children: React.ReactNode,
    loading?: boolean,
    back?: boolean | string,
    title?: any,
    padding?: number
}) => {
    if (loading) {
        return (
            <div className="bg-white">
                <img className="w-20 mx-auto py-10 md:py-16" src="/icon.png" alt="!#"/>

                <div className="wrapper mx-auto text-center shadow__style p-8 rounded-3xl">
                    <span className="loading loading-infinity loading-lg"></span>
                </div>
            </div>
        )
    } else {
        return (
            <div className="bg-white">
                <img className="w-20 mx-auto py-10 md:py-16" src="/icon.png" alt="!#"/>

                <div className={`wrapper mx-auto text-center shadow__style p-${padding} rounded-3xl`}>
                    <Title center={true} back={back} title={title}/>
                    {children}
                </div>
            </div>
        )
    }
}