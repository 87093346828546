import React, {useContext} from "react";
import {Main} from "@components/layout/Main";
import Banner from "@components/moment/Banner";
import Fireworks from "@fireworks-js/react";
import {Context} from "../../index";
import Snowfall from "react-snowfall";

export interface moment {
    id: string,
    typeId: number,
    accessToken: string,
    allowJoin: boolean,
    users: {
        id: string,
        name: string,
        avatar: string,
    }[],
    images: any,
    data?: {
        spotify_url?: string;
        anniversary?: string;
        anniversary_style?: number;
        relstatus?: number;
        effects?: number;
        title?: string;
    }
    tree?: string;
    quotas?: {
        images?: number;
        timeline?: number;
    };
    milestones: any[]
}

export interface momentProps {
    fetch: () => void;
}

interface props {
    children?: React.ReactNode;
    fetch?: () => void;
}

export const Moment = ({children, fetch}: props) => {

    const {moment}: any = useContext(Context);

    return (
        <Main>
            <Banner fetch={fetch}/>
            {process.env.REACT_APP_NEWYEARS && moment && moment.data?.effects != 0 && (
                <Fireworks
                    options={{
                        rocketsPoint: {
                            min: 10,
                            max: 100
                        }
                    }}
                    style={{
                        top: 40,
                        left: 0,
                        zIndex: 0,
                        width: '100%',
                        height: '300px',
                        position: 'absolute',
                    }}
                />
            )}
            {
                process.env.REACT_APP_CHRISTMAS && moment && moment.data?.effects != 0 && (
                    <Snowfall/>
                )
            }
            {
                children
            }
        </Main>
    )
}