import React, {useEffect, useState} from "react";
import api from "@services/api.service";
import {Link} from "react-router-dom";
import ProtectedImage, {
    authenticateImage,
} from "@components/auth/ProtectedImage";
import authService from "@services/auth.service";
import {useTranslation} from "react-i18next";

interface Props {
    moment: any;
}

const Moment = ({moment}: Props) => {
    const [guser, setGuser]: any = useState(null);

    const [title, setTitle]: any = useState();

    const [banner, setBanner]: any = useState();

    const {t} = useTranslation<"translation", undefined>();

    useEffect(() => {
        if (moment.images && moment.images[0]) {
            authenticateImage(moment.images[0].image.url).then((url) => {
                setBanner(url);
            });
        } else {
            setBanner("/static/blank.png");
        }

        if (moment.typeId == 1 || moment.typeId == 2) {
            if (moment.users.length === 1) {
                api.get(`/v1/moment/${moment.id}/guser`).then((response) => {
                    setGuser(response.data);
                });
            }
        } else if (moment.typeId == 3 || moment.typeId == 4) {
            api.get(`/v1/moment/${moment.id}/title`).then((response) => {
                if (response && response.data) {
                    setTitle(response.data.value);
                }
            });
        }
    }, [moment.id]);

    const createdAt = new Date(moment.createdAt);

    const getOtherUser = (users: any) => {
        if (users.length == 2) {
            let u;
            for (const user of users) {
                if (user.id != authService.getUser()?.id) {
                    u = user;
                }
            }
            return u;
        }
    };

    if (!moment) {
        return null;
    }
    switch (moment.typeId) {
        case 1:
            return (
                <Link
                    to={`/${moment.id}`}
                    key={moment.id}
                    className="flex flex-col items-center mx-[0px]"
                >
                    <div
                        className="flex flex-col items-center justify-center h-[100px] md:h-[150px] w-[100px] md:w-[150px] rounded-[12px] shadow-2xl bg-white">
                        <div
                            style={{
                                backgroundImage: `url(${banner})`,
                            }}
                            className="relative flex items-center justify-center w-full h-full rounded-[12px] bg-no-repeat bg-center bg-cover bg-black bg-opacity-90"
                        >
                            <div className="absolute top h-full w-full bg-black rounded-[12px] bg-opacity-10"/>
                            {moment.users.length == 1 && guser && guser.image && guser.image.url && (
                                <div
                                    className="z-10 absolute bottom-[-28px] md:bottom-[-30px] h-[50px] w-[50px] border-4 border-white rounded-full overflow-hidden bg-gray-500">
                                    <ProtectedImage url={guser.image.url}/>
                                </div>
                            )}
                            {moment.users.length == 2 &&
                                getOtherUser(moment.users).avatar && (
                                    <div
                                        className="z-10 absolute bottom-[-28px] md:bottom-[-30px] h-[50px] w-[50px] border-4 border-white rounded-full overflow-hidden bg-gray-500">
                                        <ProtectedImage url={getOtherUser(moment.users).avatar}/>
                                    </div>
                                )}
                        </div>
                    </div>
                    <h1
                        className={`text-black text-md md:text-lg mt-[${
                            moment.users.length == 2 ||
                            (moment.users.length == 1 && guser && guser.image)
                                ? "28"
                                : "16"
                        }px] font-semibold`}
                    >
                        {moment.users.length == 1 && !guser && t("partner")}
                        {moment.users.length == 1 && guser && guser.name}
                        {moment.users.length == 2 && getOtherUser(moment.users).name}
                    </h1>
                </Link>
            );
        case 2:
            return (
                <Link
                    to={`/${moment.id}`}
                    key={moment.id}
                    className="flex flex-col items-center mx-[0px]"
                >
                    <div
                        className="flex flex-col items-center justify-center h-[100px] md:h-[150px] w-[100px] md:w-[150px] rounded-[12px] shadow-2xl bg-white">
                        <div
                            style={{
                                backgroundImage: `url(${banner})`,
                            }}
                            className="relative flex items-center justify-center w-full h-full rounded-[12px] bg-no-repeat bg-center bg-cover bg-black bg-opacity-90"
                        >
                            <div className="absolute top h-full w-full bg-black rounded-[12px] bg-opacity-10"/>
                            {moment.users.length == 1 && guser && guser.image && (
                                <div
                                    className="z-30 absolute bottom-[-28px] md:bottom-[-30px] h-[50px] w-[50px] border-4 border-white rounded-full overflow-hidden bg-gray-500">
                                    <ProtectedImage url={guser.image.url}/>
                                </div>
                            )}
                            {moment.users.length == 2 &&
                                getOtherUser(moment.users).avatar && (
                                    <div
                                        className="z-30 absolute bottom-[-28px] md:bottom-[-30px] h-[50px] w-[50px] border-4 border-white rounded-full overflow-hidden bg-gray-500">
                                        <ProtectedImage url={getOtherUser(moment.users).avatar}/>
                                    </div>
                                )}
                        </div>
                    </div>
                    <h1
                        className={`text-black text-base md:text-lg mt-[${
                            moment.users.length == 2 ||
                            (moment.users.length == 1 && guser && guser.image)
                                ? "28"
                                : "16"
                        }px] font-semibold`}
                    >
                        {moment.users.length == 1 && !guser && t("friend")}
                        {moment.users.length == 1 && guser && guser.name}
                        {moment.users.length == 2 && getOtherUser(moment.users).name}
                    </h1>
                </Link>
            );
        default:
            return (
                <Link
                    to={`/${moment.id}`}
                    key={moment.id}
                    className="flex flex-col items-center mx-[0px]"
                >
                    <div
                        className="flex flex-col items-center justify-center h-[100px] md:h-[150px] w-[100px] md:w-[150px] rounded-[12px] shadow-2xl bg-white">
                        <div
                            style={{
                                backgroundImage: `url(${banner})`,
                            }}
                            className="relative flex items-center justify-center w-full h-full rounded-[12px] bg-no-repeat bg-center bg-cover bg-black bg-opacity-90"
                        >
                            <div className="absolute top h-full w-full bg-black rounded-[12px] bg-opacity-10"/>
                        </div>
                    </div>
                    {title && (
                        <h1
                            className={`text-black text-base md:text-lg mt-[16px] font-semibold`}
                        >
                            {title}
                        </h1>
                    )}
                    {!title && (
                        <h1
                            className={`text-black text-base md:text-lg mt-[16px] font-semibold`}
                        >
                            {moment.typeId == 3 && t("family")}
                            {moment.typeId == 4 && t("friend_group")}
                        </h1>
                    )}
                </Link>
            );
    }
};

export default Moment;
