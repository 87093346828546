import {Main} from "@components/layout/Main";
import React from "react";
import Moments from "@components/dashboard/Moments";
import Products from "@components/dashboard/Products";
import Blog from "@components/dashboard/Blog";

const DashboardPage = () => {
    return (
        <Main>
            <div className="wrapper text-center mt-3 mb-3">
                <p className="font-black text-lg">Jetzt auch als App verfügbar</p>
                <div className="flex items-center mx-auto justify-center gap-2">
                    <a href="https://apps.apple.com/de/app/intatches/id6478170854" target="_blank">
                        <img
                            width={150}
                            src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/de-de?size=250x83&amp;releaseDate=1276560000&h=7e7b68fad19738b5649a1bfb78ff46e9"
                            alt="Laden im App Store"/>
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.moments.infinity.infinity_moments"
                       target="_blank">
                        <img
                            width={164}
                            alt="Jetzt bei Google Play"
                            src="https://play.google.com/intl/de_de/badges/images/generic/de_badge_web_generic.png"/>
                    </a>
                </div>
            </div>
            <Moments/>
            <Blog/>
            <Products/>
        </Main>
    )
}

export default DashboardPage;