import React, { useContext } from "react";
import api from "@services/api.service";
import { toast } from "react-toastify";
import { Context } from "../../../index";
import { useTranslation } from "react-i18next";

const ChristmasMenu = ({ show, fetch }: any) => {
  const { moment }: any = useContext(Context);

  const { t } = useTranslation<"translation", undefined>();

  const updateChristmas = (state: boolean) => {
    console.log(state);
    api
      .post(`/v1/moment/${moment?.id}/effects`, {
        value: state,
      })
      .then(() => {
        fetch();
        console.log(moment.id);
        toast.success(t("setting_updated_successfully"), {
          position: "bottom-right",
        });
      });
  };

  if (!show) {
    return null;
  } else {
    return (
      <>
        <div className="mb-3">
          <div className="mb-2">
            {t("infinity_moments_wishes")}
            <br />
            <br />
            {t("here_you_can_turn")}

            <br />
            <label
              htmlFor="christmas"
              className="inline-flex relative items-center cursor-pointer mt-3"
            >
              <input
                onClick={(e) => {
                  updateChristmas(e.currentTarget.checked);
                }}
                type="checkbox"
                defaultChecked={moment.data.effects != 0}
                id="christmas"
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-gray-700"></div>
              <span className="ml-3 text-md font-medium text-gray-900 dark:text-gray-300">
                {t("christmas_effect_activated?")}
              </span>
            </label>
          </div>
        </div>
      </>
    );
  }
};

export default ChristmasMenu;
