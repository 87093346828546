import React, {useContext, useState} from "react";
import {IconSettings} from "@tabler/icons-react";
import ProtectedImage from "@components/auth/ProtectedImage";
import {DateFormat, FormatDate, FormatDateDelta, FormatDatePercentage, RelStatus,} from "@components/moment/Dates";
import {useNavigate} from "react-router-dom";
import Icon from "@components/ui/Icon";
import AddUserModal from "@components/moment/modals/AddUserModal";
import {isDemo} from "@services/utils.service";
import AuthService from "@services/auth.service";
import {Context} from "../../index";
import {useTranslation} from "react-i18next";

const Banner = ({fetch}: any) => {
    const navigate = useNavigate();

    const [showAddUser, setShowAddUser] = useState(false);

    const {moment}: any = useContext(Context);

    const {t} = useTranslation();

    if (!moment) {
        return null;
    }

    let ms = '';
    switch (moment.data?.relstatus) {
        case 1:
            ms = "got_together";
            break;
        case 2:
            ms = "engaged";
            break;
        case 3:
            ms = "married";
            break;
        default:
            ms = "got_together";
            break;
    }

    const anniversary = isDemo() ? new Date("2021-01-11") : moment.milestones.find((milestone: any) => milestone.milestone == ms)?.date;

    return (
        <>
            <AddUserModal fetch={fetch} show={showAddUser} setShow={setShowAddUser}/>
            <div className="w-[95%] mx-auto md:w-[500px] max-h-[350px] relative z-[29]">
                {!isDemo() && (
                    <button
                        onClick={() => navigate(`/${moment.id}/settings`)}
                        className="z-10 absolute top-2 right-2 md:top-6 md:right-6"
                    >
                        <IconSettings color="white"/>
                    </button>
                )}

                {moment.typeId == 1 && (
                    <div
                        className="z-10 absolute top-[55%] left-[50%] -translate-x-[50%] -translate-y-[50%] w-full flex justify-center space-x-4 items-start">
                        <div>
                            {process.env.REACT_APP_CHRISTMAS && moment.data?.effects != 0 && (
                                <img
                                    className="absolute -mb-2 -ml-10 w-28 h-28 bottom-10 z-10"
                                    src="/static/event/hat_0.png"
                                    alt=""/>
                            )}
                            {process.env.REACT_APP_HALLOWEEN && moment.data?.effects != 0 && (
                                <img
                                    className="absolute -ml-6 -mb-2 w-16 h-16 bottom-10 z-10"
                                    src="/static/event/pumpkin.png"
                                    alt=""/>
                            )}
                            {process.env.REACT_APP_EASTER && moment.data?.effects != 0 && (
                                <img
                                    className="absolute mb-14 -ml-8 w-10 rotate-12 bottom-10 z-10"
                                    src="/static/event/egg_1.png"
                                    alt=""/>
                            )}
                            <ProtectedImage
                                className="relative bg-white w-20 h-20 rounded-full p-[2.5px] mb-2 mx-auto object-cover z-0"
                                url={moment.users[0].avatar}/>
                            <p className="text-white text-sm text-center md:text-lg font-black break-all">{moment.users[0].name}</p>
                        </div>

                        <div className="flex flex-col items-center text-center space-y-1 mx-auto">
                            <p className="text-white font-bold text-sm md:text-lg">
                                <FormatDate
                                    dateString={anniversary}
                                    format={DateFormat.FULL}
                                />
                            </p>
                            <div>
                                <Icon style={moment.data.icon} color="white" size={40}/>
                            </div>
                            <p className="text-white font-bold text-sm md:text-lg">
                                {anniversary && !isDemo() && (
                                    <>
                                        {RelStatus(moment.data?.relstatus)} {t("since")}
                                        <br/>
                                    </>
                                )}
                                {isDemo() && (
                                    <>
                                        {t("together")} {t("since")}
                                        <br/>
                                    </>
                                )}
                                <FormatDateDelta
                                    style={moment.data?.anniversary_style}
                                    dateString={anniversary}
                                    spaces={true}
                                />
                            </p>
                        </div>

                        {moment.users.length == 1 && !moment.guser && (
                            <div onClick={() => setShowAddUser(true)}>
                                {process.env.REACT_APP_CHRISTMAS && moment.data?.effects != 0 && (
                                    <img
                                        className="absolute -mb-2 -mr-10 w-28 h-28 bottom-10 z-10"
                                        src="/static/event/hat_1.png"
                                        alt=""/>
                                )}
                                {process.env.REACT_APP_HALLOWEEN && moment.data?.effects != 0 && (
                                    <img
                                        className="absolute ml-12 -mb-2 w-16 h-16 bottom-10 z-10"
                                        src="/static/event/pumpkin.png"
                                        alt=""/>
                                )}
                                {process.env.REACT_APP_EASTER && moment.data?.effects != 0 && (
                                    <img
                                        className="absolute -mb-20 ml-16 w-16 -rotate-12 bottom-10 z-10"
                                        src="/static/event/egg_2.png"
                                        alt=""/>
                                )}
                                <img alt="Unknown"
                                     className="bg-white w-20 h-20 rounded-full p-[2.5px] mb-2 object-cover"
                                     src="/static/unkown.png"/>
                                <button className="text-white underline text-sm md:text-lg font-black break-all">
                                    {t("add")} +
                                </button>
                            </div>
                        )}

                        {moment.users.length == 1 && moment.guser && (
                            <div onClick={() => setShowAddUser(true)}>
                                {process.env.REACT_APP_CHRISTMAS && moment.data?.effects != 0 && (
                                    <img
                                        className="absolute -mb-2 -mr-10 w-28 h-28 bottom-10 z-10"
                                        src="/static/event/hat_1.png"
                                        alt=""/>
                                )}
                                {process.env.REACT_APP_HALLOWEEN && moment.data?.effects != 0 && (
                                    <img
                                        className="absolute ml-12 -mb-2 w-16 h-16 bottom-10 z-10"
                                        src="/static/event/pumpkin.png"
                                        alt=""/>
                                )}
                                {process.env.REACT_APP_EASTER && moment.data?.effects != 0 && (
                                    <img
                                        className="absolute -mb-20 ml-16 w-16 -rotate-12 bottom-10 z-10"
                                        src="/static/event/egg_2.png"
                                        alt=""/>
                                )}
                                {moment.guser.image && (
                                    <ProtectedImage
                                        className="bg-white w-20 h-20 rounded-full p-[2.5px] mb-2 object-cover mx-auto"
                                        url={moment.guser.image.url}/>
                                )}
                                {!moment.guser.image && (
                                    <img alt="Unknown"
                                         className="bg-white w-20 h-20 rounded-full p-[2.5px] mb-2 object-cover"
                                         src="/static/unkown.png"/>
                                )}
                                <p className="text-white text-sm text-center md:text-lg font-black break-all">{moment.guser.name}</p>
                            </div>
                        )}

                        {moment.users.length == 2 && (
                            <div>
                                {process.env.REACT_APP_CHRISTMAS && moment.data?.effects != 0 && (
                                    <img
                                        className="absolute -mb-2 -mr-10 w-28 h-28 bottom-10 z-10"
                                        src="/static/event/hat_1.png"
                                        alt=""/>
                                )}
                                {process.env.REACT_APP_HALLOWEEN && moment.data?.effects != 0 && (
                                    <img
                                        className="absolute ml-12 -mb-2 w-16 h-16 bottom-10 z-10"
                                        src="/static/event/pumpkin.png"
                                        alt=""/>
                                )}
                                {process.env.REACT_APP_EASTER && moment.data?.effects != 0 && (
                                    <img
                                        className="absolute -mb-20 ml-16 w-16 -rotate-12 bottom-10 z-10"
                                        src="/static/event/egg_2.png"
                                        alt=""/>
                                )}
                                <ProtectedImage
                                    className="bg-white w-20 h-20 rounded-full p-[2.5px] mb-2 object-cover mx-auto"
                                    url={moment.users[1]?.avatar}/>
                                <p className="text-white text-sm text-center md:text-lg">{moment.users[1].name}</p>
                            </div>
                        )}
                    </div>
                )}
                {moment.typeId == 1 && (
                    <div
                        className="z-10 absolute top-[93%] left-[50%] -translate-x-[50%] -translate-y-[50%] w-full text-center">
                        {AuthService.getUser()?.birthdate && anniversary && (
                            <FormatDatePercentage style={moment.data.relstatus} anniversary={anniversary}/>
                        )}
                    </div>
                )}

                {moment.typeId == 2 && (
                    <div
                        className="z-10 absolute top-[55%] left-[50%] -translate-x-[50%] -translate-y-[50%] w-full flex justify-center space-x-4 items-start">
                        <div>
                            {process.env.REACT_APP_CHRISTMAS && moment.data?.effects != 0 && (
                                <img
                                    className="absolute -mb-2 -ml-10 w-28 h-28 bottom-10 z-10"
                                    src="/static/event/hat_0.png"
                                    alt=""/>
                            )}
                            {process.env.REACT_APP_HALLOWEEN && moment.data?.effects != 0 && (
                                <img
                                    className="absolute -ml-6 -mb-2 w-16 h-16 bottom-10 z-10"
                                    src="/static/event/pumpkin.png"
                                    alt=""/>
                            )}
                            {process.env.REACT_APP_EASTER && moment.data?.effects != 0 && (
                                <img
                                    className="absolute mb-14 -ml-12 w-10 rotate-12 bottom-10 z-10"
                                    src="/static/event/egg_1.png"
                                    alt=""/>
                            )}
                            <ProtectedImage
                                className="bg-white w-20 h-20 rounded-full p-[2.5px] mb-2 mx-auto object-cover"
                                url={moment.users[0]?.avatar}/>
                            <p className="text-white text-sm text-center md:text-lg font-black break-all">{moment.users[0]?.name}</p>
                        </div>

                        <div className="flex flex-col items-center text-center space-y-1 mx-auto">
                            <div className="mt-5">
                                <Icon style={moment.data.icon} color="white" size={40}/>
                            </div>
                        </div>

                        {moment.users.length == 1 && !moment.guser && (
                            <div onClick={() => setShowAddUser(true)}>
                                {process.env.REACT_APP_CHRISTMAS && moment.data?.effects != 0 && (
                                    <img
                                        className="absolute -mb-2 -mr-10 w-28 h-28 bottom-10 z-10"
                                        src="/static/event/hat_1.png"
                                        alt=""/>
                                )}
                                {process.env.REACT_APP_HALLOWEEN && moment.data?.effects != 0 && (
                                    <img
                                        className="absolute ml-12 -mb-2 w-16 h-16 bottom-10 z-10"
                                        src="/static/event/pumpkin.png"
                                        alt=""/>
                                )}
                                {process.env.REACT_APP_EASTER && moment.data?.effects != 0 && (
                                    <img
                                        className="absolute -mb-20 ml-24 w-16 -rotate-12 bottom-10 z-10"
                                        src="/static/event/egg_2.png"
                                        alt=""/>
                                )}
                                <img alt="Unknown"
                                     className="bg-white w-20 h-20 rounded-full p-[2.5px] mb-2 object-cover"
                                     src="/static/unkown.png"/>
                                <button className="text-white underline text-sm md:text-lg font-black break-all">
                                    {t("add")} +
                                </button>
                            </div>
                        )}

                        {moment.users.length == 1 && moment.guser && (
                            <div onClick={() => setShowAddUser(true)}>
                                {process.env.REACT_APP_CHRISTMAS && moment.data?.effects != 0 && (
                                    <img
                                        className="absolute -mb-2 -mr-10 w-28 h-28 bottom-10 z-10"
                                        src="/static/event/hat_1.png"
                                        alt=""/>
                                )}
                                {process.env.REACT_APP_HALLOWEEN && moment.data?.effects != 0 && (
                                    <img
                                        className="absolute ml-12 -mb-2 w-16 h-16 bottom-10 z-10"
                                        src="/static/event/pumpkin.png"
                                        alt=""/>
                                )}
                                {process.env.REACT_APP_EASTER && moment.data?.effects != 0 && (
                                    <img
                                        className="absolute -mb-20 ml-24 w-16 -rotate-12 bottom-10 z-10"
                                        src="/static/event/egg_2.png"
                                        alt=""/>
                                )}
                                {moment.guser.image && (
                                    <ProtectedImage
                                        className="bg-white w-20 h-20 rounded-full p-[2.5px] mb-2 object-cover mx-auto"
                                        url={moment.guser.image.url}/>
                                )}
                                {!moment.guser.image && (
                                    <img alt="Unknown"
                                         className="bg-white w-20 h-20 rounded-full p-[2.5px] mb-2 object-cover"
                                         src="/static/unkown.png"/>
                                )}
                                <p className="text-white text-sm text-center md:text-lg font-black break-all">{moment.guser.name}</p>
                            </div>
                        )}

                        {moment.users.length == 2 && (
                            <div>
                                {process.env.REACT_APP_CHRISTMAS && moment.data?.effects != 0 && (
                                    <img
                                        className="absolute -mb-2 -mr-10 w-28 h-28 bottom-10 z-10"
                                        src="/static/event/hat_1.png"
                                        alt=""/>
                                )}
                                {process.env.REACT_APP_HALLOWEEN && moment.data?.effects != 0 && (
                                    <img
                                        className="absolute ml-12 -mb-2 w-16 h-16 bottom-10 z-10"
                                        src="/static/event/pumpkin.png"
                                        alt=""/>
                                )}
                                {process.env.REACT_APP_EASTER && moment.data?.effects != 0 && (
                                    <img
                                        className="absolute -mb-20 ml-24 w-16 -rotate-12 bottom-10 z-10"
                                        src="/static/event/egg_2.png"
                                        alt=""/>
                                )}
                                <ProtectedImage
                                    className="bg-white w-20 h-20 rounded-full p-[2.5px] mb-2 object-cover mx-auto"
                                    url={moment.users[1]?.avatar}/>
                                <p className="text-white text-sm text-center md:text-lg font-black">{moment.users[1].name}</p>
                            </div>
                        )}
                    </div>
                )}

                {moment.typeId == 3 && (
                    <div
                        className="z-10 absolute top-[55%] left-[50%] -translate-x-[50%] -translate-y-[50%] w-full flex justify-center space-x-4 items-start">
                        <div className="flex flex-col items-center text-center space-y-1 mx-auto">
                            <p className="text-white font-bold text-2xl mb-5">
                                {moment.data.title}
                            </p>
                            <div className="flex -space-x-5">
                                {moment.users.slice(0, 5).map((user: any, i: number) => {
                                    return (
                                        <ProtectedImage
                                            key={i}
                                            className="bg-white w-14 h-14 rounded-full p-[2.5px] mb-2 object-cover"
                                            url={user?.avatar}/>
                                    )
                                })}
                                {moment.users.length > 5 && (
                                    <span
                                        className="inline-flex items-center justify-center h-14 w-14 rounded-full bg-gray-100 border-2 border-gray-200">
                                        <span
                                            className="text-xl leading-none">+{moment.users.length - 5}</span>
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                )}

                {moment.typeId == 4 && (
                    <div
                        className="z-10 absolute top-[55%] left-[50%] -translate-x-[50%] -translate-y-[50%] w-full flex justify-center space-x-4 items-start">
                        <div className="flex flex-col items-center text-center space-y-1 mx-auto">
                            <p className="text-white font-bold text-2xl mb-5">
                                {moment.data.title}
                            </p>
                            <div className="flex -space-x-5">
                                {moment.users.slice(0, 5).map((user: any, i: number) => {
                                    return (
                                        <ProtectedImage
                                            key={i}
                                            className="bg-white w-14 h-14 rounded-full p-[2.5px] mb-2 object-cover"
                                            url={user?.avatar}/>
                                    )
                                })}
                                {moment.users.length > 5 && (
                                    <span
                                        className="inline-flex items-center justify-center h-14 w-14 rounded-full bg-gray-100 border-2 border-gray-200">
                                        <span
                                            className="text-xl leading-none">+{moment.users.length - 5}</span>
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                )}

                {moment.images && moment.images["@banner"] && (
                    <ProtectedImage className="w-full rounded-2xl brightness-75 max-h-[350px] object-cover"
                                    url={moment.images["@banner"][0].url}/>
                )}
                {moment.images && !moment.images["@banner"] && (
                    <img className="w-full rounded-2xl brightness-75 max-h-[350px] object-cover"
                         src="/static/blank.png"
                         alt="banner"/>
                )}
            </div>
        </>
    );
};

export default Banner;
