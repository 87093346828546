import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import api from "@services/api.service";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const JoinMomentPage = () => {
  const navigate = useNavigate();
  const [search] = useSearchParams();

  const { t } = useTranslation<"translation", undefined>();

  useEffect(() => {
    if (search.get("code")) {
      api
        .post(`/v1/moment/join/${search.get("code")}`)
        .then(() => {
          toast.success(t("accession_successful"), {
            position: "bottom-right",
          });
          navigate("/");
        })
        .catch((e) => {
          if (e.response.data.message) {
            if (e.response.data.message == "Unauthorized") {
              toast.error(t("unable_to_join"), { position: "bottom-right" });
            } else if (e.response.data.message == "already-member") {
              toast.error(t("you_are_already_a_member"), {
                position: "bottom-right",
              });
            } else if (e.response.data.message == "limit-reached") {
              toast.error(
                t("Unable_to_join_maximum_number_of_members_reached"),
                { position: "bottom-right" }
              );
            } else if (e.response.data.message == "no-token") {
              toast.error(t("you_are_already_a_member_or_cannot_join"), {
                position: "bottom-right",
              });
            } else if (e.response.data.message == "overlaps") {
              toast.error(t("you_are_already_in_a_moment_with_this_user"), {
                position: "bottom-right",
              });
            }
          } else {
            toast.error(t("joining_not_possible"), {
              position: "bottom-right",
            });
          }
          navigate("/");
        });
    }
  }, []);

  return null;
};

export default JoinMomentPage;
