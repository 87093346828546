import {useEffect, useState} from "react";
import api from "@services/api.service";
import auth from "@services/auth.service";
import cache from "@services/cache.service";

const authenticateImage = async (url: string) => {
    if (url && url !== "") {
        if (!url.includes("cdn")) {
            return url;
        }
        if (cache.getImage(url)) {
            return cache.getImage(url).url;
        } else {
            return await api.get(`${url}/authorize`, api.getCustomAxiosConfig(auth.getAccessToken()), true).then((response) => {
                if (response.data && response.data.token) {
                    const signedUrl = `${url}?k=${response.data.token}`;
                    cache.cacheImage(url, signedUrl, response.data.expires);
                    return signedUrl;
                }
            });
        }
    }
}

const ProtectedImage = ({
                            url,
                            alt,
                            className,
                            addKey = true
                        }: { addKey?: boolean; url: string | undefined; alt?: string; className?: string }) => {
    const [source, setSource] = useState("");

    useEffect(() => {
        if (url && url !== "") {
            if (!url.includes("cdn")) {
                setSource(url);
                return;
            }
            if (cache.getImage(url)) {
                setSource(cache.getImage(url).url);
            } else {
                api.get(`${url}/authorize`, api.getCustomAxiosConfig(auth.getAccessToken()), true).then((response) => {
                    if (response.data && response.data.token) {
                        const signedUrl = `${url}?k=${response.data.token}`;
                        cache.cacheImage(url, signedUrl, response.data.expires);
                        setSource(signedUrl);
                    }
                });
            }
        }
    }, [url])

    if (addKey) {
        return (
            <img key={Date.now()} src={source || "/static/fallback.png"} alt={alt || ""} className={className}/>
        )
    } else {
        return (
            <img src={source || "/static/fallback.png"} alt={alt || ""} className={className}/>
        )
    }
}

export default ProtectedImage;

export {
    authenticateImage
}