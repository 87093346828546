import {useNavigate, useParams} from "react-router-dom";
import React, {FormEvent, useEffect, useState} from "react";
import {Dialog} from "@components/layout/Dialog";
import api from "@services/api.service";
import auth from "@services/auth.service";
import {toast} from "react-toastify";
import Button from "@components/ui/Button";
import Input from "@components/ui/Input";
import {useTranslation} from "react-i18next";
import {validEmail} from "@services/utils.service";

const ResetPage = () => {

    const {t} = useTranslation();

    const {resetId} = useParams();
    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [sent, setSent] = useState(false);

    const [pw, setPw] = useState("");
    const [cpw, setCpw] = useState("");

    const handleRequest = (e?: FormEvent) => {
        if (e) {
            e.preventDefault();
        }

        if (!validEmail(email)) {
            toast.error(t("toast_invalid_email"), {position: "bottom-right"});
            return;
        }

        api.post("/v1/auth/reset/request", {
            email: email
        }).then(() => {
            toast.success(t("toast_email_sent"), {position: "bottom-right"});
            setSent(true);
        }).catch(() => {
            toast.error(t("toast_error_occurred"), {position: "bottom-right"});
        })
    }

    const handleReset = (e: FormEvent) => {
        e.preventDefault();
        if (pw && cpw) {
            if (pw !== cpw) {
                toast.error(t("toast_passwords_dont_match"), {position: "bottom-right"});
                return;
            }
            api.post("/v1/auth/reset", {
                token: resetId,
                new: pw
            }).then(() => {
                toast.success(t("toast_password_reset_successfull"), {position: "bottom-right"})
                auth.logout(false);
                navigate("/");
            }).catch(() => {
                toast.error(t("toast_error_occurred"), {position: "bottom-right"});
            })
        }
    }

    useEffect(() => {
        if (resetId) {
            api.get(`/v1/auth/reset/${resetId}`).catch(() => {
                navigate("/");
            });
        }
    }, [])

    if (resetId) {
        return (
            <Dialog title={t("reset_password")}>
                <p className="mt-3">
                    {t("enter_new_password_and_confirm")}
                </p>
                <form onSubmit={(e) => handleReset(e)}>
                    <Input id="ac" label={t("new_password")}
                           value={pw}
                           onChange={(e) => setPw(e.currentTarget.value)}
                           type="password"
                           placeholder="********"
                           minLength={8}
                           required
                    />
                    <Input id="ac" label={t("repeat_new_password")}
                           placeholder="********"
                           minLength={8}
                           required
                           value={cpw}
                           onChange={(e) => setCpw(e.currentTarget.value)}
                           type="password"
                    />
                    <Button title={t("reset_password")} type="submit"/>
                </form>
            </Dialog>
        )
    } else {
        if (sent) {
            return (
                <Dialog title={t("reset_password")}>
                    <p className="mt-3 mb-3">
                        {t("reset_email_sent", {email: email})}
                    </p>
                    <Button title={t("resend_email")} onClick={handleRequest}/>
                </Dialog>
            )
        } else {
            return (
                <Dialog title={t("reset_password")}>
                    <p className="mt-3 mb-3">
                        {t("enter_email_to_reset")}
                    </p>
                    <form onSubmit={(e) => handleRequest(e)}>
                        <Input id="email"
                               label={t("email")}
                               value={email}
                               onChange={(e) => setEmail(e.currentTarget.value)}
                               type="email"
                               placeholder="info@infinity-moments.de"
                               required/>
                        <Button title={t("reset_password")} type="submit"/>
                    </form>
                </Dialog>
            )
        }
    }

}

export default ResetPage;