import { useState } from "react";
import { Cropper } from "react-cropper";
import "cropperjs/dist/cropper.css";
import api from "@services/api.service";
import { toast } from "react-toastify";
import { IconCloudUpload } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

const ImageUploader = ({
  id,
  endpoint,
  onFinish,
  fixedArea,
}: {
  id: number;
  fixedArea?: number;
  endpoint: string;
  onFinish?: (data?: any) => void;
}) => {
  const [image, setImage]: any = useState(null);
  const [progress, setProgress]: any = useState();
  const [upload, setUpload]: any = useState(false);

  const [cropper, setCropper]: any = useState();

  const { t } = useTranslation<"translation", undefined>();

  const onChange = (e: any) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);
    };
    reader.readAsDataURL(files[0]);
  };

  const onConfirm = () => {
    setUpload(true);
    cropper.getCroppedCanvas().toBlob(
      (blob: any) => {
        let fd = new FormData();
        fd.append("file", blob);
        api
          .post(endpoint, fd, {
            onUploadProgress: (pe: ProgressEvent) => {
              setProgress(`${Math.round((pe.loaded / pe.total) * 100)}%`);
            },
            ...api.getDefaultAxiosConfig(),
          })
          .then((response) => {
            setUpload(false);
            if (onFinish) {
              onFinish(response.data);
            }
            setImage(null);
          })
          .catch((e) => {
            console.log(e);
            setUpload(false);
            if (e && e.response.status == 413) {
              toast.error(t("image_too_big"), { position: "bottom-right" });
            } else if(e && e.response.status == 400) {
              toast.error(t('gallery_limit_reached'), {position: "bottom-right"});
            }
          });
      },
      "image/jpeg",
      0.4
    );
  };

  if (image) {
    return (
      <div>
        <Cropper
          style={{ height: 400, width: "100%" }}
          zoomable={false}
          initialAspectRatio={1}
          src={image}
          viewMode={1}
          minCropBoxHeight={100}
          minCropBoxWidth={100}
          background={false}
          responsive={true}
          autoCropArea={1}
          checkOrientation={false}
          onInitialized={(instance) => {
            setCropper(instance);
          }}
          guides={false}
          dragMode={"none"}
          cropBoxResizable={!fixedArea}
          aspectRatio={fixedArea}
        />
        {upload && (
          <div className="w-full bg-gray-200 rounded-full h-2.5">
            <div
              className="bg-blue-600 h-2.5 rounded-full"
              style={{ width: `${progress}` }}
            ></div>
          </div>
        )}
        {!upload && (
          <div className="inline-flex w-full">
            <button
              onClick={onConfirm}
              className="cursor-pointer mr-1 mt-1 w-full text-white bg-gray-700 hover:bg-gray-800 font-medium rounded-lg text-lg px-5 py-2.5 text-center inline-flex items-center"
            >
              {t("confirm")}
            </button>
            <button
              onClick={() => setImage(null)}
              className="cursor-pointer ml-1 mt-1 w-full text-white bg-red-700 hover:bg-gray-800 font-medium rounded-lg text-lg px-5 py-2.5 text-center inline-flex items-center"
            >
              {t("cancel")}
            </button>
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className="flex items-center justify-center w-full">
        <label
          htmlFor={`dropzone-file-${id}`}
          className="flex flex-col items-center justify-center w-full h-36 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
        >
          <div className="flex flex-col items-center justify-center pt-5 pb-6">
            <IconCloudUpload className="w-10 h-10 mb-3 text-gray-400" />
            <p className="mb-2 text-center text-md text-gray-500">
              <span className="font-semibold">
               {t('click_here_to_add_a_picture')}
              </span>
            </p>
            <p className="text-md text-gray-500">{t('jpg_jpeg')}</p>
          </div>
          <input
            id={`dropzone-file-${id}`}
            type="file"
            className="hidden"
            accept="image/jpeg,image/png"
            onChange={onChange}
          />
        </label>
      </div>
    );
  }
};

export default ImageUploader;
