import {useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import api from "@services/api.service";
import {Dialog} from "@components/layout/Dialog";
import Input from "@components/ui/Input";
import Button from "@components/ui/Button";
import {toast} from "react-toastify";

const EmergencyPage = () => {
    const {animalId} = useParams();

    const [emergencyPage, setEmergencyPage]: any = useState();
    const [rateLimit, setRateLimit] = useState(false);

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [address, setAddress] = useState("");
    const [message, setMessage] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        api.get(`/v1/animal/${animalId}/emergencyPage/public`).then((response) => {
            console.log(response.data);
            if (response.status == 200) {
                setEmergencyPage(response.data);
            } else {
                setEmergencyPage([]);
            }
        }).catch((error) => {
            if (error.response.status == 429) {
                setRateLimit(true);
            } else {
                setEmergencyPage([]);
            }
        });
    }, []);

    if (rateLimit) {
        return (
            <Dialog>
                <div className="text-lg">Zu viele Anfragen</div>
                <br/>
                <span>Bitte versuche es in einer Minute erneut</span>
            </Dialog>
        )
    }

    if (emergencyPage == null) {
        return (
            <Dialog>
                <span className="loading loading-spinner loading-sm mt-1 mr-2"></span>
            </Dialog>
        );
    } else if (emergencyPage.length == 0) {
        return (
            <Dialog>
                <span>Tier konnte nicht gefunden werden</span>
            </Dialog>
        );
    } else {
        return (
            <Dialog title="Notfallseite" padding={2}>
                <div className="mb-5 mt-5">
                    {emergencyPage.image && emergencyPage.image.url && (
                        <div className="flex justify-center">
                            <img
                                src={`${emergencyPage.image?.url.replace("/v1", "/v2")}/public`}
                                className="w-48 h-48 rounded-xl object-cover mb-4"
                                alt="Animal"/>
                        </div>
                    )}
                    <div className="flex flex-col items-center">
                        <div className="text-2xl text-center">{emergencyPage.animal.name}</div>
                        <div className="text-md text-center">Hier findest du wichtige Informationen zu mir. Wenn ich mich verlaufen habe, kontaktiere bitte mein zu Hause, damit ich sicher zurück kommen kann</div>
                    </div>
                    <div className="grid grid-cols-4 w-full mt-4 mb-5 px-3">
                        <div className="col-span-4 mb-1"><span className="text-xl">Ich wohne bei</span></div>
                        {Object.keys(emergencyPage.data).length == 0 && (
                            <div className="col-span-4 text-md text-center">Keine Kontaktdaten angegeben</div>
                        )}
                        {emergencyPage.data.name && (
                            <>
                                <div className="col-span-1 text-left">
                                    <span className="text-lg">Name</span>
                                </div>
                                <div className="col-span-3 text-left">
                                    <strong><span
                                        className="text-lg">{emergencyPage.data.name ?? "?"}</span></strong>
                                </div>
                            </>
                        )}
                        {emergencyPage.data.email && (
                            <>
                                <div className="col-span-1 mt-2 text-left">
                                    <span className="text-lg">E-Mail</span>
                                </div>
                                <div className="col-span-3 mt-2 text-left">
                                    <strong><span className="text-lg"><a className="underline break-words"
                                                                         href={`mailto:${emergencyPage.data.email ?? "?"}`}>{emergencyPage.data.email ?? "?"}</a></span></strong>
                                </div>
                            </>
                        )}
                        {emergencyPage.data.phone && (
                            <>
                                <div className="col-span-4 mt-2"></div>
                                <div className="col-span-1 text-left">
                                    <span className="text-lg">Telefon</span>
                                </div>
                                <div className="col-span-3 text-left">
                                    <strong><span
                                        className="text-lg break-words"><a className="underline break-words"
                                                                           href={`tel:${emergencyPage.data.phone ?? "?"}`}>{emergencyPage.data.phone ?? "?"}</a></span></strong>
                                </div>
                            </>
                        )}
                        {emergencyPage.data.phone_secondary && (
                            <>
                                <div className="col-span-4 mt-2"></div>
                                <div className="col-span-1 text-left">
                                    <span className="text-lg">Telefon #2</span>
                                </div>
                                <div className="col-span-3 text-left">
                                    <strong><span
                                        className="text-lg break-words"><a className="underline break-words"
                                                                           href={`tel:${emergencyPage.data.phone_secondary ?? "?"}`}>{emergencyPage.data.phone_secondary ?? "?"}</a></span></strong>
                                </div>
                            </>
                        )}
                        {emergencyPage.data.address && (
                            <>
                                <div className="col-span-4 mt-2"></div>
                                <div className="col-span-1 text-left">
                                    <span className="text-lg">Adresse</span>
                                </div>
                                <div className="col-span-3 text-left">
                                    <strong>
                                            <span className="text-lg break-words">
                                                {emergencyPage.data.address}
                                            </span>
                                    </strong>
                                </div>
                            </>
                        )}
                        {emergencyPage.data.info && (
                            <>
                                <div className="col-span-4 mt-2"></div>
                                <div className="col-span-1 text-left">
                                    <span className="text-lg">Infos</span>
                                </div>
                                <div className="col-span-3 text-left">
                                    <strong>
                                            <span className="text-lg break-words">
                                                {emergencyPage.data.info}
                                            </span>
                                    </strong>
                                </div>
                            </>
                        )}
                        <div className="col-span-4 mt-5"></div>
                        {emergencyPage.includeMedical && (
                            <>
                                <div className="col-span-4 mb-1"><span
                                    className="text-xl">Medizinische Informationen</span>
                                </div>
                                {Object.keys(emergencyPage.medical).length == 0 && (
                                    <div className="col-span-4 text-md text-center">Keine Medizinischen Infos angegeben</div>
                                )}
                                {emergencyPage.medical.tasso && (
                                    <>
                                        <div className="col-span-4 mt-2"></div>
                                        <div className="col-span-2 text-left">
                                            <span className="text-lg">Tasso-Kennung</span>
                                        </div>
                                        <div className="col-span-2 text-left">
                                            <strong>
                                            <span className="text-lg break-words">
                                                {emergencyPage.medical.tasso}
                                            </span>
                                            </strong>
                                        </div>
                                    </>
                                )}
                                {emergencyPage.medical.height && (
                                    <>
                                        <div className="col-span-4 mt-2"></div>
                                        <div className="col-span-2 text-left">
                                            <span className="text-lg">Höhe</span>
                                        </div>
                                        <div className="col-span-2 text-left">
                                            <strong>
                                            <span className="text-lg break-words">
                                                {emergencyPage.medical.height}
                                            </span>
                                            </strong>
                                        </div>
                                    </>
                                )}
                                {emergencyPage.medical.width && (
                                    <>
                                        <div className="col-span-4 mt-2"></div>
                                        <div className="col-span-2 text-left">
                                            <span className="text-lg">Breite</span>
                                        </div>
                                        <div className="col-span-2 text-left">
                                            <strong>
                                            <span className="text-lg break-words">
                                                {emergencyPage.medical.width}
                                            </span>
                                            </strong>
                                        </div>
                                    </>
                                )}
                                {emergencyPage.medical.length && (
                                    <>
                                        <div className="col-span-4 mt-2"></div>
                                        <div className="col-span-2 text-left">
                                            <span className="text-lg">Länge</span>
                                        </div>
                                        <div className="col-span-2 text-left">
                                            <strong>
                                            <span className="text-lg break-words">
                                                {emergencyPage.medical.length}
                                            </span>
                                            </strong>
                                        </div>
                                    </>
                                )}
                                {emergencyPage.medical.weight && (
                                    <>
                                        <div className="col-span-4 mt-2"></div>
                                        <div className="col-span-2 text-left">
                                            <span className="text-lg">Gewicht</span>
                                        </div>
                                        <div className="col-span-2 text-left">
                                            <strong>
                                            <span className="text-lg break-words">
                                                {emergencyPage.medical.weight}
                                            </span>
                                            </strong>
                                        </div>
                                    </>
                                )}
                                {emergencyPage.medical.vet && (
                                    <>
                                        <div className="col-span-4 mt-2"></div>
                                        <div className="col-span-2 text-left">
                                            <span className="text-lg">Tierarzt</span>
                                        </div>
                                        <div className="col-span-2 text-left">
                                            <strong>
                                            <span className="text-lg break-words">
                                                {emergencyPage.medical.vet}
                                            </span>
                                            </strong>
                                        </div>
                                    </>
                                )}
                                {emergencyPage.medical.known_illnesses && (
                                    <>
                                        <div className="col-span-4 mt-2"></div>
                                        <div className="col-span-2 text-left">
                                            <span className="text-lg">Bekannte Erkrankungen</span>
                                        </div>
                                        <div className="col-span-2 text-left">
                                            <strong>
                                            <span className="text-lg break-words">
                                                {emergencyPage.medical.known_illnesses}
                                            </span>
                                            </strong>
                                        </div>
                                    </>
                                )}
                                {emergencyPage.medical.other_medical && (
                                    <>
                                        <div className="col-span-4 mt-2"></div>
                                        <div className="col-span-2 text-left">
                                            <span className="text-lg">Sonstige Infos</span>
                                        </div>
                                        <div className="col-span-2 text-left">
                                            <strong>
                                            <span className="text-lg break-words">
                                                {emergencyPage.medical.other_medical}
                                            </span>
                                            </strong>
                                        </div>
                                    </>
                                )}</>
                        )}
                        <div className="col-span-4 mt-5"></div>
                        {emergencyPage.contactForm && (
                            <>
                                <div className="col-span-4 mb-1"><span
                                    className="text-xl">Kontaktformular</span>
                                </div>
                                <div className="col-span-4 text-md text-center">Kontaktiere Menschen bei denen ich wohne</div>
                                <div className="col-span-4 mr-5">
                                    <form onSubmit={(e) => {
                                        e.preventDefault();
                                        console.log(name, email, phone, address, message);
                                        setLoading(true);
                                        api.post(`/v1/animal/${animalId}/emergencyPage/contactForm`, {
                                            name: name,
                                            email: email,
                                            phone: phone,
                                            address: address,
                                            message: message
                                        }).then((response) => {
                                            if (response.status == 200 || response.status == 201) {
                                                toast.success("Nachricht erfolgreich gesendet");
                                                setName("");
                                                setEmail("");
                                                setPhone("");
                                                setAddress("");
                                                setMessage("");
                                            }
                                            setLoading(false);
                                        }).catch(() => {
                                            toast.error("Bitte warte bevor du eine neue Nachricht sendest");
                                            setLoading(false);
                                        });
                                    }}>
                                        <Input id="name" label="Name"
                                               type="text"
                                               placeholder="Max Mustermann"
                                               autoComplete="name"
                                               required
                                               value={name}
                                               onChange={(e) => setName(e.currentTarget.value)}/>
                                        <Input id="email" label="E-Mail"
                                               type="email"
                                               placeholder="max@mustermann.com"
                                               autoComplete="email"
                                               required
                                               value={email}
                                               onChange={(e) => setEmail(e.currentTarget.value)}/>
                                        <Input id="phone" label="Telefonnummer"
                                               type="tel"
                                               placeholder="+49..."
                                               autoComplete="phone"
                                               required
                                               value={phone}
                                               onChange={(e) => setPhone(e.currentTarget.value)}/>
                                        <Input id="address" label="Adresse (optional)"
                                               type="text"
                                               placeholder="Musterstraße 1..."
                                               autoComplete="email"
                                               value={address}
                                               onChange={(e) => setAddress(e.currentTarget.value)}/>
                                        <Input id="message" label="Nachricht"
                                               type="text"
                                               placeholder="Hallo, ..."
                                               required
                                               value={message}
                                               onChange={(e) => setMessage(e.currentTarget.value)}/>
                                        <Button content={loading ?
                                            <span className="loading loading-infinity loading-md"/> :
                                            <span className="text-md">Absenden</span>}/>
                                    </form>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </Dialog>
        );
    }
}

export default EmergencyPage;