import {getMonthName} from "@services/utils.service";
import React from "react";
import AuthService from "@services/auth.service";
import {useTranslation} from "react-i18next";

enum DateFormat {
    FULL,
    MONTH_SHORT,
    MONTH_SHORT_YEAR,
}

const DateStyle = ({style}: any) => {
    const {t} = useTranslation();
    if (style == 1) {
        return <>{t("days")}</>;
    } else if (style == 2) {
        return <>{t("months")}</>;
    } else if (style == 3) {
        return <>{t("years")}</>;
    } else {
        return <>{t("days")}</>;
    }
};

const RelStatus = (style?: number) => {
    const {t} = useTranslation();
    if (style == 1) {
        return t("together");
    } else if (style == 2) {
        return t("engaged");
    } else if (style == 3) {
        return t("married");
    } else {
        return t("together");
    }
};

const FormatDatePercentage = ({
                                  anniversary,
                                  style,
                              }: {
    anniversary: string;
    style: number;
}) => {
    if (!AuthService.getUser()?.birthdate || !anniversary) {
        return <></>;
    }

    const now = new Date();
    const a = new Date(anniversary);
    const birthdate = new Date(AuthService.getUser()?.birthdate as string);
    const lduration = now.getTime() - birthdate.getTime();
    const aduration = now.getTime() - a.getTime();
    const percent =
        Math.round(((aduration / lduration) * 100 + Number.EPSILON) * 100) / 100;

    const {t} = useTranslation();
    return (
        <div className="text-white text-sm font-bold rounded-xl bg-black bg-opacity-50">
          <span>
            {t("percent_of_your_life", {percent: percent, relstatus: RelStatus(style).toLowerCase()})}
          </span>
        </div>
    );
};

const FormatDate = ({
                        dateString,
                        format,
                    }: {
    dateString: String | undefined;
    format?: DateFormat;
}) => {
    const date = new Date(dateString as string);

    if (!date || isNaN(date.getTime())) {
        return <React.Fragment/>;
    }
    if (format == DateFormat.FULL) {
        return (
            <>{`${date.getDate()}. ${getMonthName(
                date.getMonth(),
                false
            )} ${date.getFullYear()}`}</>
        );
    } else {
        return <>{date?.toLocaleDateString()}</>;
    }
};

const FormatDateDelta = ({
                             style,
                             dateString,
                             spaces,
                         }: {
    style?: number;
    dateString: String | undefined;
    spaces?: boolean;
}) => {
    const date = new Date(dateString as string);

    const {t} = useTranslation();

    if (!date || isNaN(date.getTime())) {
        return <React.Fragment/>;
    }
    if (style == 3) {
        const value = Math.floor(
            (new Date().getTime() - date.getTime() + 86400000) / 31556952000
        );
        return (
            <>
                {spaces && " "}
                {value == 1 ? t("one") : value}
                {spaces && " "}
                {value == 1 ? t("year") : t("years")}
            </>
        );
    } else if (style == 2) {
        const value = Math.floor(
            (new Date().getTime() - date.getTime() + 1) / 2629800000
        );
        return (
            <>
                {spaces && " "}
                {value == 1 ? t("one") : value}
                {spaces && " "}
                {value == 1 ? t("month") : t("months")}
            </>
        );
    } else {
        const value = Math.ceil(
            (new Date().getTime() - date.getTime() + 1) / 86400000
        );
        return (
            <>
                {spaces && " "}
                {value == 1 ? t("one") : value}
                {spaces && " "}
                {value == 1 ? t("day") : t("days")}
            </>
        );
    }
};

export {
    FormatDatePercentage,
    FormatDateDelta,
    FormatDate,
    DateFormat,
    DateStyle,
    RelStatus,
};
