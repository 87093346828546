import React, {useContext, useEffect, useState} from "react";
import Title from "./settings/Title";
import api from "@services/api.service";
import {Context} from "../../index";
import EditBucketListModal from "@components/moment/modals/EditBucketListModal";
import {isDemo} from "@services/utils.service";
import {useTranslation} from "react-i18next";

const BucketList = () => {
    const {moment}: any = useContext(Context);

    const [isChecked, setIsChecked] = useState(false);

    const [bucketList, setBucketList]: any = useState([]);

    const {t} = useTranslation<"translation", undefined>();

    const fetchBucketList = () => {
        api.get(`/v1/moment/${moment.id}/sector/lists`).then((response) => {
            api.get(`/v1/moment/${moment.id}/sector/lists/${response.data[0].id}`).then((response) => {
                setBucketList(response.data);
            });
        });
    };

    const [showEditBucketList, setShowEditBucketList] = useState(false);

    const handleChangeState = (index: number, state: boolean) => {
        if (isDemo()) {
            return;
        }

        api.get(`/v1/moment/${moment.id}/sector/lists`).then((response) => {
            api
                .patch(`/v1/moment/${moment.id}/sector/lists/${response.data[0].id}/${index}`, {
                    partial: {
                        completedAt: state ? new Date() : null
                    },
                })
                .then(() => {
                    fetchBucketList();
                });
        });


    };

    useEffect(() => {
        if (moment) {
            if (isDemo()) {
                if (moment.typeId == 1 || moment.typeId == 2) {
                    setBucketList([
                        {index: 1, name: t("crossing_the_alps"), state: true},
                        {index: 2, name: t("driving_an_r8"), state: true},
                        {index: 3, name: t("breakfast_by_the_sea"), state: true},
                        {index: 4, name: t("spontaneous_short_trip"), state: true},
                        {index: 5, name: t("sledding_on_feldberg"), state: true},
                        {index: 6, name: t("celebrate_in_istanbul"), state: true},
                        {index: 7, name: t("first_barbecue_of_2023"), state: true},
                        {index: 8, name: t("2_weeks_vacation_in_a_row"), state: false},
                        {index: 9, name: t("safari_in_africa"), state: false},
                        {index: 10, name: t("growing_old_together"), state: false},
                        {
                            index: 11,
                            name: t("go_swimming_in_hot_springs"),
                            state: false,
                        },
                        {index: 12, name: t("canoeing"), state: false},
                        {index: 12, name: t("inaugurate_new_office"), state: false},
                        {index: 12, name: t("festival_in_summer"), state: false},
                        {index: 12, name: t("short_trip_to_the_sea"), state: false},
                        {
                            index: 12,
                            name: t("hike_to_assmannshausen"),
                            state: false,
                        },
                        {
                            index: 12,
                            name: t("sleeping_on_the_balcony_in_summer"),
                            state: false,
                        },
                        {index: 12, name: t("karneval_in_rio_de_janeiro"), state: false},
                    ]);
                } else if (moment.typeId == 3) {
                    setBucketList([
                        {index: 1, name: t("alpine_crossing"), state: true},
                        {index: 2, name: t("great_family_vacation"), state: true},
                        {index: 3, name: t("breakfast_by_the_sea_"), state: true},
                        {index: 4, name: t("tobogganing_on_the_feldberg"), state: true},
                        {index: 5, name: t("safari_in_africa_"), state: false},
                        {index: 6, name: t("travel_to_nania"), state: false},
                        {index: 7, name: t("escape_room"), state: false},
                        {index: 8, name: t("yodeling_together"), state: false},
                        {index: 9, name: t("bowling_evening"), state: false},
                    ]);
                }
                return;
            }
            fetchBucketList();
        }
    }, [moment]);

    return (
        <>
            <EditBucketListModal
                fetch={fetchBucketList}
                show={showEditBucketList}
                setShow={setShowEditBucketList}
                bucketList={bucketList}
            />
            <div className="wrapper">
                <div className="flex justify-between items-end py-6">
                    <Title title={t("bucketlist")}/>
                    {!isDemo() && (
                        <button>
                            <p
                                onClick={() => setShowEditBucketList(true)}
                                className="underline font-gilroy font-black text-lg md:text-xl"
                            >
                                {t("edit")}
                            </p>
                        </button>
                    )}
                </div>
                {bucketList.length == 0 && (
                    <p className="font-bold text-sm">
                        {t("you_havent_added_any_entries_yet")}
                    </p>
                )}
                {bucketList.map((item: any, i: number) => {
                    return (
                        <label key={i} className="box">
                            <p
                                className={`${
                                    item.state ? "line-through text-black-faded" : ""
                                } text-base md:text-xl font-medium `}
                            >
                                {item.name}
                            </p>
                            <input
                                type="checkbox"
                                checked={item.completedAt != null}
                                onChange={() => handleChangeState(item.index, item.completedAt == null)}
                            />
                            <span className="checkmark"></span>
                        </label>
                    );
                })}
            </div>
        </>
    );
};

export default BucketList;
