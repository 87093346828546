import React, { useContext, useState } from "react";
import Modal from "@components/ui/Modal";
import { toast } from "react-toastify";
import api from "@services/api.service";
import { Context } from "../../../index";
import Button from "@components/ui/Button";
import { useTranslation } from "react-i18next";

const AddTimelineModal = ({ show, setShow, fetch }: any) => {
  const { moment }: any = useContext(Context);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage]: any = useState();
  const [date, setDate]: any = useState();
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation<"translation", undefined>();

  const handleCreate = () => {
    setName("");
    setDescription("");
    setDate("");
    setImage(null);
    if (!date) {
      toast.error(t("please_enter_a_date"), { position: "bottom-right" });
      return;
    }
    if (new Date(date) > new Date()) {
      toast.error(t("please_select_a_date_in_the_past"), {
        position: "bottom-right",
      });
      return;
    }
    if (!name) {
      toast.error(t("please_enter_a_name"), { position: "bottom-right" });
      return;
    }
    setLoading(true);
    let fd = new FormData();
    fd.append("name", name);
    fd.append("date", date);
    if (description) {
      fd.append("description", description);
    }
    if (image) {
      fd.append("file", image);
    }
    api
      .post(`/v1/moment/${moment.id}/sector/insider`, fd)
      .then(() => {
        setShow(false);
        setName("");
        setDescription("");
        setDate("");
        setImage(null);
        fetch();
        setLoading(false);
      })
      .catch((e) => {
        if (e && e.response && e.response.status == 413) {
          toast.error(t("image_too_big"), { position: "bottom-right" });
        }
        setLoading(false);
      });
  };

  return (
    <Modal title={t("timeline")} show={show} setShow={setShow}>
      <div className="flex flex-col">
        <label className="font-black mb-1">{t("date")}</label>
        <input
          type="date"
          id="date"
          max={new Date().toISOString().substring(0, 10)}
          className="border-2 outline-none border-border-grey rounded-2xl py-3 px-4 placeholder:font-black mb-1"
          defaultValue={date}
          value={date || ""}
          onChange={(e) => setDate(e.currentTarget.value)}
          placeholder={""}
        />
        <label className="font-black mb-1">{t("memory")}</label>
        <input
          type="text"
          id="name"
          className="border-2 outline-none border-border-grey rounded-2xl py-3 px-4 placeholder:font-black mb-1"
          value={name}
          maxLength={255}
          onChange={(e) => setName(e.currentTarget.value)}
          placeholder={""}
        />
        <label htmlFor="description" className="font-black mb-1">
          {t("description")}
        </label>
        <textarea
          id="description"
          className="border-2 outline-none border-border-grey rounded-2xl py-3 px-4 placeholder:font-black mb-1"
          value={description}
          onChange={(e) => setDescription(e.currentTarget.value)}
          placeholder={""}
        />
        <label htmlFor="description" className="font-black mb-1">
          {t("picture")}
        </label>
        <input
          type="file"
          className="border-2 outline-none border-border-grey rounded-2xl py-3 px-4 placeholder:font-black mb-1"
          onChange={(e) => {
            if (e.currentTarget.files && e.currentTarget.files[0]) {
              setImage(e.currentTarget.files[0]);
            }
          }}
        />
      </div>
      <Button
        content={
          loading ? (
            <span className="loading loading-infinity loading-xs"></span>
          ) : (
            t("create")
          )
        }
        onClick={handleCreate}
      />
    </Modal>
  );
};

export default AddTimelineModal;
