import React from "react";
import Title from "@components/ui/Title";
import {IconArrowLeft} from "@tabler/icons-react";
import {useNavigate} from "react-router-dom";

const PageTitle = ({title, noBack}: { title: string, noBack?: boolean }) => {
    const navigate = useNavigate();
    return (
        <div className="wrapper flex items-center space-x-4 pb-4">
            {!noBack && (
                <button onClick={() => navigate(-1)}>
                    <IconArrowLeft color="black"/>
                </button>
            )}
            <Title title={title}/>
        </div>
    );
};

export default PageTitle;
