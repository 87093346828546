import React, {useState} from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter, Route, Routes} from "react-router-dom";

import "@styles/index.css";
import "react-toastify/dist/ReactToastify.css";
import {ToastContainer} from "react-toastify";
import DashboardPage from "@pages/Dashboard";
import LoginPage from "@pages/account/Login";
import ProtectedRoute from "@components/auth/ProtectedRoute";
import LogoutPage from "@pages/account/Logout";
import MomentResolver from "@pages/moments/MomentResolver";
import TokenResolver from "@pages/token/TokenResolver";
import RegisterPage from "@pages/account/Register";
import VerifyPage from "@pages/account/Verify";
import DemoPage from "@pages/Demo";
import CreateMomentPage from "@pages/moments/CreateMoment";
import SettingsPage from "@pages/account/Settings";
import JoinMomentPage from "@pages/moments/JoinMoment";
import ResetPage from "@pages/account/Reset";
import MaintenancePage from "@pages/Maintenance";
import FamilyDemoPage from "@pages/FamilyDemo";
import NotfoundPage from "@pages/Notfound";

import "./i18n";
import VerifyEmailChangePage from "@pages/account/VerifyEmailChange";
import NewsListPage from "@pages/news/NewsList";
import NewsPage from "@pages/news/News";
import {Socket} from "socket.io-client";
import TermsOfServicePage from "@pages/TermsOfService";
import PrivacyPage from "@pages/Privacy";
import EmergencyPage from "@pages/animal/EmergencyPage";

export const Context: any = React.createContext(null);

const App = () => {

    const [overlay, setOverlay] = useState(false);
    const [moment, setMoment] = useState();
    const [fetchMoment, setFetchMoment] = useState();
    const [socket, setSocket] = useState<Socket>();

    const toggleOverlay = () => {
        setOverlay(!overlay);
    };

    if (process.env.REACT_APP_MAINTENANCE == "true") {
        return (
            <BrowserRouter>
                <Routes>
                    <Route path="*" element={<MaintenancePage/>}/>
                </Routes>
            </BrowserRouter>
        )
    } else {
        return (
            <Context.Provider value={{
                overlay: overlay,
                setOverlay: setOverlay,
                toggleOverlay: toggleOverlay,
                moment: moment,
                setMoment: setMoment,
                fetchMoment: fetchMoment,
                setFetchMoment: setFetchMoment,
                socket: socket,
                setSocket: setSocket
            }}>
                <BrowserRouter>
                    <ToastContainer/>
                    <Routes>
                        <Route index element={
                            <ProtectedRoute component={<DashboardPage/>}/>
                        }/>
                        <Route path="/login" element={<LoginPage/>}/>
                        <Route path="/p/:resetId" element={<ResetPage/>}/>
                        <Route path="/reset" element={<ResetPage/>}/>
                        <Route path="/logout" element={<LogoutPage/>}/>
                        <Route path="/demo" element={<DemoPage/>}/>
                        <Route path="/demo/family" element={<FamilyDemoPage/>}/>
                        <Route path="/settings" element={
                            <ProtectedRoute component={<SettingsPage/>}/>
                        }/>
                        <Route path="/register/:tokenId" element={<RegisterPage/>}/>
                        <Route path="/t/:tokenId" element={<TokenResolver/>}/>
                        <Route path="/t/:tokenId/:animalId" element={<TokenResolver/>}/>
                        <Route path="/c/:tokenId/:type" element={
                            <ProtectedRoute component={<CreateMomentPage/>}/>
                        }/>
                        <Route path="/e/:animalId" element={<EmergencyPage/>}/>
                        <Route path="/join" element={
                            <ProtectedRoute component={<JoinMomentPage/>}/>
                        }/>
                        <Route path="/v/:verificationId" element={<VerifyPage/>}/>
                        <Route path="/ec/:verificationId" element={<VerifyEmailChangePage/>}/>
                        <Route path="/news" element={
                            <ProtectedRoute component={<NewsListPage/>}/>}
                        />
                        <Route path="/news/:id" element={
                            <ProtectedRoute component={<NewsPage/>}/>}
                        />
                        <Route path="/:id" element={
                            <ProtectedRoute component={<MomentResolver/>}/>}
                        />
                        <Route path="/:id/settings" element={
                            <ProtectedRoute component={<MomentResolver/>}/>}
                        />
                        <Route path="/:id/tree" element={
                            <ProtectedRoute component={<MomentResolver/>}/>}
                        />
                        <Route path="/:id/familytree" element={
                            <ProtectedRoute component={<MomentResolver/>}/>}
                        />
                        <Route path="/tos" element={<TermsOfServicePage/>}/>
                        <Route path="/privacy" element={<PrivacyPage/>}/>
                        <Route path="*" element={<NotfoundPage/>}/>
                    </Routes>
                </BrowserRouter>
            </Context.Provider>
        )
    }
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(<App/>);