import React, {useContext, useEffect, useState} from "react";
// @ts-ignore
import f3 from "family-chart";

import "@components/moment/familytree/FamilyTree.style.css";
import {Form} from "@components/moment/familytree/Form";
import api from "@services/api.service";
import {toast} from "react-toastify";
import {useNavigate} from "react-router-dom";
import {Context} from "../../../index";
import {isDemo} from "@services/utils.service";
import Title from "@components/moment/settings/Title";
import {IconCheck, IconMaximize, IconMinimize} from "@tabler/icons-react";
import Modal from "@components/ui/Modal";
import {useTranslation} from "react-i18next";

interface props {
    fullscreen: boolean;
}

const FamilyTree = ({fullscreen}: props) => {
    const {moment}: any = useContext(Context);

    const ref: any = React.createRef();

    const [editing, setEditing] = useState(false);
    const [store, setStore]: any = useState();
    const [view, setView]: any = useState();
    const [init, setInit]: any = useState(false);
    const [updating, setUpdating]: any = useState(false);

    const [tree, setTree]: any = useState(null);

    const {t} = useTranslation();

    const fetch = () => {
        api.get(`/v1/moment/${moment.id}/sector/tree`).then((response) => {
            console.log("tree/fetch");
            if (response.data && response.data.length != 0) {
                setTree(response.data);
            } else {
                setTree([
                    {
                        id: "0",
                        rels: {},
                        data: {
                            "first name": "Max",
                            "last name": "Mustermann",
                            avatar: null,
                        },
                    },
                ]);
            }
        });
    };

    const update = (data: any) => {
        if (moment && moment.id) {
            setUpdating(true);
            api
                .post(`/v1/moment/${moment.id}/sector/tree`, {
                    data: data,
                })
                .then(() => {
                    setTimeout(() => {
                        setUpdating(false);
                    }, 500);
                    console.log("tree/update");
                })
                .catch(() => {
                    setUpdating(true);
                    toast.error(
                        "Ein Fehler ist aufgetreten, bitte versuche es später erneut.",
                        {position: "bottom-right"}
                    );
                });
        }
    };

    useEffect(() => {
        !isDemo() && fetch();
    }, []);

    useEffect(() => {
        if (tree && !view && !store) {
            const store = f3.createStore({
                data: isDemo() ? moment.tree : tree,
                node_separation: 250,
                level_separation: 150,
            });
            setStore(store);
            const view = f3.d3AnimationView({
                store,
                cont: ref.current,
                card_edit,
            });

            setView(view);
        }
    }, [tree]);

    useEffect(() => {
        if (!moment || !store || !view || !tree) {
            return;
        }

        if (editing) {
            view.setCard(
                f3.elements.Card({
                    store,
                    svg: view.svg,
                    card_dim,
                    card_display,
                    mini_tree: true,
                    link_break: false,
                    cardEditForm,
                    addRelative: f3.handlers.AddRelative({
                        store,
                        cont,
                        card_dim,
                        cardEditForm,
                        labels: {
                            mother: "Elternteil hinzufügen",
                            father: "Elternteil hinzufügen",
                            spouse: "Partner hinzufügen",
                            son: "Kind hinzufügen",
                            daughter: "Kind hinzufügen",
                        },
                    }),
                })
            );
        } else {
            view.setCard(
                f3.elements.Card({
                    store,
                    svg: view.svg,
                    card_dim,
                    card_display,
                    mini_tree: false,
                    link_break: false,
                    addRelative: f3.handlers.AddRelative({
                        store,
                        cont,
                        card_dim,
                        cardEditForm,
                        labels: {
                            mother: "Elternteil hinzufügen",
                            father: "Elternteil hinzufügen",
                            spouse: "Partner hinzufügen",
                            son: "Kind hinzufügen",
                            daughter: "Kind hinzufügen",
                        },
                    }),
                })
            );
        }

        if (!init) {
            store.setOnUpdate((props: any) => {
                if (!props) {
                    update(store.getData());
                    store.update.tree({initial: true, tree_position: "inherit"});
                }
                view.update(props);
            });
            setInit(true);
        }

        store.update.tree({initial: true, tree_position: "inherit"});
    }, [editing, store]);

    const [edit, setEdit] = useState(false);

    const navigate = useNavigate();

    if (!moment) {
        return null;
    }

    const cont = document.querySelector("#FamilyChart");
    const card_dim = {
        w: 220,
        h: 70,
        text_x: 75,
        text_y: 15,
        img_w: 60,
        img_h: 60,
        img_x: 5,
        img_y: 5,
    };
    const d1 = (d: any) => `${d.data["first name"] || ""}`,
        d2 = (d: any) => `${d.data["last name"] || ""}`,
        d3 = (d: any) =>
            `${
                d.data["birthday"]
                    ? new Date(d.data["birthday"]).getDate() +
                    "." +
                    (new Date(d.data["birthday"]).getMonth() + 1) +
                    "." +
                    new Date(d.data["birthday"]).getFullYear()
                    : ""
            }`;

    d1.create_form = "{first name}";
    d2.create_form = "{last name}";
    d3.create_form = "{birthday}";

    const card_display = [d1, d2, d3];
    const card_edit = [
        {type: "text", placeholder: "Vorname", key: "first name"},
        {type: "text", placeholder: "Nachname", key: "last name"},
        {type: "date", placeholder: "Geburtsdatum", key: "birthday"},
    ];

    const cardEditForm = (props: any) => {
        const postSubmit = props.postSubmit;
        props.postSubmit = (ps_props: any) => {
            postSubmit(ps_props);
        };

        setEdit(true);
        setTimeout(() => {
            Form({
                ...props,
                card_edit,
                card_display,
                edit: {
                    modal: document.getElementById("edit"),
                    open: () => setEdit(true),
                    close: () => setEdit(false),
                },
            });
        });
    };

    return (
        <>
            <Modal show={edit} setShow={setEdit}>
                <div id="edit"></div>
            </Modal>
            <div className="wrapper flex justify-between items-end py-5 mt-5">
                <Title title={t("family_tree")}/>
                {!isDemo() && (
                    <button className="flex">
                        {editing && updating && (
                            <span className="loading loading-spinner loading-sm mt-1 mr-2"></span>
                        )}
                        {editing && !updating && (
                            <IconCheck color="green" className="mt-1 mr-2"/>
                        )}
                        <p
                            onClick={() => setEditing((edit) => !edit)}
                            className="underline font-gilroy font-black text-lg md:text-xl"
                        >
                            {editing ? t("ready") : t("edit")}
                        </p>
                    </button>
                )}
            </div>
            <div className="wrapper">
                <div className="w-full relative h-full">
                    {!fullscreen && !isDemo() && (
                        <button
                            onClick={() => {
                                setEditing(true);
                                setEditing(false);
                                navigate(`/${moment.id}/tree`);
                            }}
                            className="z-10 absolute top-2 right-2 text-white bg-black font-medium rounded-lg text-md px-3 py-3 text-center"
                        >
                            <IconMaximize size={24}/>
                        </button>
                    )}
                    {fullscreen && !isDemo() && (
                        <button
                            onClick={() => {
                                setEditing(true);
                                setEditing(false);
                                navigate(`/${moment.id}`);
                            }}
                            className="z-10 absolute top-2 right-2 text-white bg-black font-medium rounded-lg text-md px-3 py-3 text-center"
                        >
                            <IconMinimize size={24}/>
                        </button>
                    )}
                    <div
                        ref={ref}
                        className={`${fullscreen ? "full " : ""}f3 border-2 my-3`}
                        id="FamilyChart"
                    />
                </div>
            </div>
        </>
    );
};

export default FamilyTree;
