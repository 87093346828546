import {Main} from "@components/layout/Main";
import React, {useEffect, useState} from "react";
import api from "@services/api.service";
import apiService from "@services/api.service";
import {toast} from "react-toastify";
import ChangePasswordModal from "@components/settings/ChangePasswordModal";
import ProtectedImage from "@components/auth/ProtectedImage";
import {IconChevronRight, IconLock, IconPencil, IconTrash,} from "@tabler/icons-react";
import {useTranslation} from "react-i18next";
import AuthService from "@services/auth.service";
import authService from "@services/auth.service";
import {useNavigate} from "react-router-dom";
import ChangeAvatarModal from "@components/settings/ChangeAvatarModal";
import Title from "@components/ui/Title";
import DeleteAccountModal from "@components/settings/DeleteAccountModal";

const SettingsPage = () => {
    const navigate = useNavigate();
    const [selectedLang, setSelectedLang] = useState("de");

    const {t, i18n} = useTranslation();

    const locales: any = {
        de: {title: "Deutsch", flag: "./static/flags/german.png"},
        en: {title: "English", flag: "./static/flags/english.png"},
        //fr: { title: "Français", flag: "./static/flags/french.png" },
    };

    const [showChangeAvatar, setShowChangeAvatar] = useState(false);

    const [user, setUser]: any = useState();

    const [username, setUsername] = useState("");
    const [email, setEmail] = useState("");
    const [usernameLoading, setUsernameLoading] = useState(false);
    const [birthdate, setBirthdate] = useState("");
    const [birthdateLoading, setBirthdateLoading] = useState(false);

    useEffect(() => {
        setUsernameLoading(false);
        fetchUser();

        const retrv: string | null = localStorage.getItem("i18nextLng");
        if (retrv) {
            if (retrv == "de-DE") {
                setSelectedLang("de");
            } else {
                setSelectedLang(retrv);
            }
        } else {
            setSelectedLang("de");
        }
    }, []);

    const fetchUser = (storeUser?: boolean) => {
        api.get("/v1/user").then((response) => {
            setUser(response.data);
            setUsername(response.data.name);
            setEmail(response.data.email);
            setBirthdate(response.data.birthdate);
            setUsernameLoading(false);
            setBirthdateLoading(false);
            if (storeUser) {
                AuthService.changeUsername(response.data.name);
                AuthService.changeBirthdate(response.data.birthdate);
            }
        });
    };

    const handleChangeUsername = () => {
        if (user.name != username) {
            setUsernameLoading(true);
            api
                .post("/v1/user", {
                    name: username.trim(),
                })
                .then(() => {
                    fetchUser(true);
                    toast.success(t("toast_username_changed"), {
                        position: "bottom-right",
                    });
                })
                .catch((e) => {
                    toast.error(t("toast_invalid_username"), {
                        position: "bottom-right",
                    });
                    setUsernameLoading(false);
                });
        }
    };

    const handleChangeEmail = () => {
        if (email == "") {
            setEmail(user.email);
        }
        if (email && email != user.email) {
            apiService
                .post("/v1/user/email", {
                    email: email,
                })
                .then((response) => {
                    toast.success(t("please_click_on_the_link_in_the_email"), {
                        position: "bottom-right",
                    });
                    authService.logout(true);
                    navigate("/login");
                })
                .catch((e) => {
                    console.log(e);
                    if (e && e.response.data.message[0]) {
                        if (e.response.data.message[0] == "email must be an email") {
                            toast.error(t("please_enter_a_valid_email"), {
                                position: "bottom-right",
                            });
                        }
                    } else if (
                        e &&
                        e.response.data.message &&
                        e.response.data.message == "email-already-used"
                    ) {
                        toast.error(t("email_already_in_use"), {
                            position: "bottom-right",
                        });
                    }
                    setEmail(user.email);
                });
        }
    };

    const handleChangeBirthdate = () => {
        if (user.birthdate != birthdate) {
            setBirthdateLoading(true);
            api
                .post("/v1/user", {
                    birthdate: birthdate,
                })
                .then(() => {
                    fetchUser(true);
                    toast.success(t("toast_birthdate_changed"), {
                        position: "bottom-right",
                    });
                })
                .catch((e) => {
                    setBirthdateLoading(false);
                    toast.error(t("toast_invalid_birthdate"), {
                        position: "bottom-right",
                    });
                });
        }
    };

    const [showChangePassword, setShowChangePassword] = useState(false);
    const [showDeleteAccount, setShowDeleteAccount] = useState(false);

    // change language
    const handleLang = async (locale: string) => {
        i18n.changeLanguage(locale);
        setSelectedLang(locale);
        setIsListOpen(false);

        await api
            .post("/v1/user", {
                language: locale,
            })
            .then(() => {
                fetchUser(true);
            });
    };

    const [isListOpen, setIsListOpen] = useState(false);
    //handle drop down list
    const handleDropdown = () => {
        setIsListOpen(!isListOpen);
    };

    return (
        <Main>
            <div className="wrapper mb-3">
                <Title title={t("settings")} center={false} back={true}/>
            </div>
            <div className="wrapper relative mb-8 flex justify-center">
                <ChangeAvatarModal
                    show={showChangeAvatar}
                    setShow={setShowChangeAvatar}
                />
                <div className="relative">
                    <button
                        onClick={() => setShowChangeAvatar(true)}
                        className="absolute bottom-8 right-0 bg-white shadow__style p-3 rounded-xl"
                    >
                        <IconPencil color="black"/>
                    </button>
                    <ProtectedImage
                        url={user?.avatar}
                        className="w-28 h-28 rounded-full object-cover mb-4"
                    />

                    <button
                        onClick={() => setShowChangeAvatar(true)}
                        className="text-black font-black"
                    >
                        {t("change_avatar")}
                    </button>
                </div>
            </div>
            <div className="wrapper mb-4">
                <div className="flex flex-col space-y-1">
                    <label htmlFor="username" className="font-extrabold">
                        {t("username")}
                    </label>
                    <div
                        className="flex justify-between items-center border-2 border-border-grey rounded-2xl py-2 px-4 mb-4">
                        <div className="flex flex-col flex-1">
                            <input
                                name="username"
                                className="text-black-faded outline-none rounded placeholder:font-black pr-4"
                                type="text"
                                placeholder={user ? user.name : ""}
                                value={username}
                                minLength={3}
                                spellCheck={false}
                                autoComplete="none"
                                maxLength={20}
                                onChange={(e) => setUsername(e.currentTarget.value)}
                                onKeyDown={(e) =>
                                    e.key === "Enter" ? handleChangeUsername() : null
                                }
                                onBlur={handleChangeUsername}
                            />
                        </div>
                        <button className="shadow__style p-3 rounded-xl w-12 h-12">
                            {usernameLoading ? (
                                <span className="loading loading-infinity loading-md self-center align-middle"></span>
                            ) : (
                                <IconPencil color="black"/>
                            )}
                        </button>
                    </div>
                </div>
            </div>
            <div className="wrapper mb-4">
                <div className="flex flex-col space-y-1">
                    <label htmlFor="username" className="font-extrabold">
                        {t("email")}
                    </label>
                    <div
                        className="flex justify-between items-center border-2 border-border-grey rounded-2xl py-2 px-4 mb-4">
                        <div className="flex flex-col flex-1">
                            <input
                                name="email"
                                className="text-black-faded outline-none rounded placeholder:font-black pr-4"
                                type="email"
                                placeholder={email ? user.email : ""}
                                value={email}
                                minLength={3}
                                spellCheck={false}
                                autoComplete="none"
                                onChange={(e) => setEmail(e.currentTarget.value)}
                                onKeyDown={(e) =>
                                    e.key === "Enter" ? handleChangeEmail() : null
                                }
                                onBlur={handleChangeEmail}
                            />
                        </div>
                        <button className="shadow__style p-3 rounded-xl w-12 h-12">
                            {usernameLoading ? (
                                <span className="loading loading-infinity loading-md self-center align-middle"></span>
                            ) : (
                                <IconPencil color="black"/>
                            )}
                        </button>
                    </div>
                </div>
                <p className="text-sm mt-1">{t("email_desc")}</p>
            </div>
            <div className="wrapper mb-8">
                <div className="flex flex-col space-y-1">
                    <label htmlFor="username" className="font-extrabold">
                        {t("birthdate")}
                    </label>
                    <div
                        className="flex justify-between items-center border-2 border-border-grey rounded-2xl py-2 px-4 mb-1">
                        <div className="flex flex-col flex-1">
                            <input
                                name="birthdate"
                                className="text-black-faded outline-none rounded placeholder:font-black pr-4"
                                type="date"
                                placeholder={birthdate ? user.birthdate : ""}
                                value={birthdate}
                                max={new Date().toISOString().split("T")[0]}
                                onChange={(e) => setBirthdate(e.currentTarget.value)}
                                onKeyDown={(e) =>
                                    e.key === "Enter" ? handleChangeBirthdate() : null
                                }
                                onBlur={handleChangeBirthdate}
                            />
                        </div>
                        <button className="shadow__style p-3 rounded-xl w-12 h-12">
                            {birthdateLoading ? (
                                <span className="loading loading-infinity loading-md self-center align-middle"></span>
                            ) : (
                                <IconPencil color="black"/>
                            )}
                        </button>
                    </div>
                    <p className="text-sm mt-1">{t("birthDate_desc")}</p>
                </div>
            </div>
            <div className="wrapper mb-8">
                <div className="flex flex-col space-y-1">
                    <label htmlFor="username" className="font-extrabold">
                        {t("language")}
                    </label>
                    <div
                        className="flex justify-between items-center relative border-2 border-border-grey rounded-2xl py-2 px-4 mb-1">
                        <div className="bg-transparent flex items-center space-x-2 text-[#100607] text-[16px]">
                            {selectedLang === "en" ? (
                                <img className="w-6" src={locales[selectedLang].flag} alt=""/>
                            ) : selectedLang === "fr" ? (
                                <img className="w-6" src={locales[selectedLang].flag} alt=""/>
                            ) : selectedLang === "de" ? (
                                <img className="w-8" src={locales[selectedLang].flag} alt=""/>
                            ) : null}
                            <span>{locales[selectedLang]?.title ? locales[selectedLang]?.title : ""}</span>
                        </div>
                        <div
                            className={`${
                                isListOpen ? "" : "hidden"
                            } bg-white absolute left-0 -bottom-24 w-full z-50 flex flex-col flex-1 shadow__style p-3 rounded-xl`}
                        >
                            {Object.keys(locales).map((locale) => (
                                <button
                                    key={locale}
                                    onClick={() => handleLang(locale)}
                                    className="bg-transparent flex items-center space-x-2 cursor-pointer text-[#100607] text-[16px] mb-2"
                                >
                                    <img
                                        className={locales[locale].title === "german" ? "w-8 -mx-1" : "w-6"}
                                        src={locales[locale].flag}
                                        alt=""
                                    />
                                    <span>{locales[locale].title}</span>
                                </button>
                            ))}
                        </div>
                        <button
                            onClick={handleDropdown}
                            className="shadow__style p-3 rounded-xl w-12 h-12"
                        >
                            <IconPencil color="black"/>
                        </button>
                    </div>
                </div>
            </div>
            <div className="wrapper">
                <ChangePasswordModal
                    show={showChangePassword}
                    setShow={setShowChangePassword}
                />
                <div className="flex justify-between items-center">
                    <div className="flex items-center">
                        <button className="bg-gray-200 p-3 rounded-xl mr-4">
                            <IconLock color="black"/>
                        </button>

                        <button
                            onClick={() => setShowChangePassword(true)}
                            className="font-black"
                        >
                            {t("change_password")}
                        </button>
                    </div>

                    <button
                        onClick={() => setShowChangePassword(true)}
                        className="p-3 rounded-xl"
                    >
                        <IconChevronRight color="black"/>
                    </button>
                </div>
            </div>
            <div className="wrapper mt-3">
                <DeleteAccountModal
                    show={showDeleteAccount}
                    setShow={setShowDeleteAccount}
                />
                <div className="flex justify-between items-center">
                    <div className="flex items-center">
                        <button className="bg-red-200 p-3 rounded-xl mr-4">
                            <IconTrash color="red"/>
                        </button>

                        <button
                            onClick={() => setShowDeleteAccount(true)}
                            className="font-bold text-red-600"
                        >
                            {t("delete_account")}
                        </button>
                    </div>

                    <button
                        onClick={() => setShowDeleteAccount(true)}
                        className="p-3 rounded-xl"
                    >
                        <IconChevronRight color="red"/>
                    </button>
                </div>
            </div>
        </Main>
    );
};

export default SettingsPage;