import {useNavigate, useParams} from "react-router-dom";

import api from "@services/api.service";
import React, {useEffect, useState} from "react";
import authService from "@services/auth.service";
import {toast} from "react-toastify";
import {Dialog} from "@components/layout/Dialog";
import Button from "@components/ui/Button";
import {useTranslation} from "react-i18next";

const TokenResolver = () => {
    const {tokenId, animalId} = useParams();
    const navigate = useNavigate();

    const [token, setToken]: any = useState();
    const [conflict, setConflict] = useState(false);

    const {t} = useTranslation();

    useEffect(() => {
        if (animalId != null) {
            api.get(`/v1/animal/${animalId}/emergencyPage/public`).then((response) => {
                if (response.status == 200) {
                    navigate(`/e/${animalId}`);
                }
            }).catch(() => {
                api.get(`/v1/token/${tokenId}`).then((response) => {
                    setToken(response.data);
                }).catch(() => {
                    navigate("/login");
                });
            });
        } else {
            if (authService.loggedIn()) {
                api.get(`/v1/token/${tokenId}/auth`).then((response) => {
                    setToken(response.data);
                    if (response.data && response.data.target && response.data.target.id) {
                        navigate(`/${response.data.target.id}`)
                    }
                }).catch(() => {
                    navigate("/login");
                });
            } else {
                api.get(`/v1/token/${tokenId}`).then((response) => {
                    setToken(response.data);
                }).catch(() => {
                    navigate("/login");
                });
            }
        }
    }, [tokenId])

    const handleClaim = (force?: boolean) => {
        api.post(`/v1/token/${tokenId}/claim`, {
            force: force
        }).then((response) => {
            if (response.status == 200) {
                api.get(`/v1/token/${tokenId}/auth`).then((response) => {
                    setToken(response.data);
                    toast.success(t("product_activated_successfully"), {position: "bottom-right"});
                    navigate(`/t/${tokenId}`);
                });
            }
        }).catch((e) => {
            if (e && e.response && e.response.status === 409) {
                setConflict(true);
            }
        });
    }


    if (authService.loggedIn()) {
        if (token?.redeemedAt && token?.userId) {
            return (
                <Dialog title={t("activation_successfull")}>
                    <p>
                        Du hast folgendes Produkt erfolgreich aktiviert und an deinen Benutzer
                        ({authService.getUser()?.email}) gebunden
                    </p>
                    <div className="flex justify-center mt-3">
                        <img
                            src={`/static/products/${token?.productId}.png`}
                            alt="Product"
                            className="rounded-lg w-28 h-20 sm:28 sm:h-20 object-cover"
                        />
                    </div>
                    <p className="mb-3 text-md break-words">{token?.product?.name}</p>
                    <p className="mb-3 text-sm">{t("please_return_to_dashboard")}</p>
                    <Button title="Zum Dashboard" onClick={() => navigate("/")}/>
                </Dialog>
            )
        } else {
            if (conflict) {
                return (
                    <Dialog title={t("activate_product")} loading={!token}>
                        <div
                            className="p-4 mb-4 text-md text-red-700 bg-red-100 rounded-lg dark:bg-red-200 dark:text-red-800"
                            role="alert">
                            <span className="font-bold text-lg">Achtung!</span>
                            {" "}
                            Du kannst dieses Armband nicht aktivieren,
                            da du bereits mit deiner E-Mail Adresse einen Account erstellt hast,
                            dein Partner/Freund*in muss einen eigenen Account mit einer anderen E-Mail Adresse
                            erstellen.
                        </div>
                        <p>
                            Für eine kleine Anleitung zur Einrichtung kannst du dir <a
                            className="text-blue-500 underline"
                            href="https://infinity-moments.com/blogs/news/einrichtung-deines-armbandes">dieses
                            Video</a> anschauen.
                        </p>
                        <br/>
                        <p>
                            Solltest du Fragen bezüglich der Einrichtung der Armbänder haben,
                            kannst du dich jederzeit an unseren Support-Chat auf unserer Seite wenden.
                        </p>
                        <button onClick={() => navigate("/")} type="submit"
                                className="mt-5 w-full h-12 text-white bg-gray-700 hover:bg-gray-800 focus:outline-none font-medium rounded-lg text-md px-5 py-3 text-center">
                            Abbrechen
                        </button>
                    </Dialog>
                )
            } else {
                return (
                    <Dialog title="Produkt aktivieren?" loading={!token}>
                        <p>Möchtest du dieses Produkt permanent mit deinem
                            Account ({authService.getUser()?.email}) verknüpfen?</p>
                        <br/>
                        <Button title="Bestätigen" onClick={() => handleClaim(false)} type="submit"/>
                    </Dialog>
                )
            }
        }
    } else {
        return (
            <Dialog title="Willkommen">
                <p className="mt-3 mb-3">
                    Deine Infinity Moments warten bereits auf Dich. Verknüpfe das Armband mit deiner E-Mail
                    Adresse oder melde dich mit deinen persönlichen Anmeldedaten an.
                </p>

                <Button title="Einloggen"
                        onClick={() => navigate(`/login?continue=/t/${tokenId}&b`)} type="submit"/>
                <div className="inline-flex justify-center items-center w-full">
                    <hr className="my-4 w-64 h-px bg-gray-200 border-0 dark:bg-gray-700"/>
                    <span
                        className="absolute left-1/2 px-1 font-medium text-gray-900 bg-white -translate-x-1/2">oder</span>
                </div>
                <Button title="Account erstellen"
                        onClick={() => navigate(`/register/${tokenId}?b`)} type="submit"/>
            </Dialog>
        )
    }
}

export default TokenResolver;