import Modal from "@components/ui/Modal";
import React, {useContext, useEffect, useState} from "react";
import {Context} from "../../../index";
import {IconChevronRight, IconCloudUpload, IconLock, IconMailOpened, IconSettings,} from "@tabler/icons-react";
import api from "@services/api.service";
import {toast} from "react-toastify";
import Setting from "@components/moment/settings/Setting";
import Button from "@components/ui/Button";
import ProtectedImage from "@components/auth/ProtectedImage";
import {Cropper} from "react-cropper";
import Input from "@components/ui/Input";
import {useTranslation} from "react-i18next";

const AddUserModal = ({fetch, show, setShow}: any) => {
    const {moment}: any = useContext(Context);

    useEffect(() => {
        if (!show) {
            setInviteUser(false);
            setConfigureUser(false);
            setName(moment.guser && moment.guser.name ? moment.guser.name : "");
        }
    }, [show]);

    const handleToggle = () => {
        api.put(`/v1/moment/${moment.id}/join`).then((response: any) => {
            setShowAccessCode(response.data.join);
        });
    };

    const [showAccessCode, setShowAccessCode]: any = useState(moment.allowJoin);

    const [inviteUser, setInviteUser] = useState(false);
    const [configureUser, setConfigureUser] = useState(false);

    const [name, setName] = useState(
        moment.guser && moment.guser.name ? moment.guser.name : ""
    );
    const [image, setImage]: any = useState();
    const [loading, setLoading] = useState(false);
    const [cropper, setCropper]: any = useState();
    const [progress, setProgress] = useState("");

    const {t} = useTranslation<"translation", undefined>();

    const handleAddGUser = () => {
        if (!name) {
            toast.error(t("please_enter_a_name"), {position: "bottom-right"});
            return;
        }
        setLoading(true);
        let fd = new FormData();
        fd.append("name", name);

        if (image) {
            cropper.getCroppedCanvas().toBlob(
                (blob: any) => {
                    if (blob) {
                        fd.append("file", blob);
                    }
                    api
                        .post(`/v1/moment/${moment.id}/guser`, fd, {
                            onUploadProgress: (pe: ProgressEvent) => {
                                setProgress(`${Math.round((pe.loaded / pe.total) * 100)}%`);
                            },
                            ...api.getDefaultAxiosConfig(),
                        })
                        .then(() => {
                            setImage(null);
                            fetch();
                            setLoading(false);
                            setShow(false);
                        })
                        .catch((e) => {
                            setLoading(false);
                            if (e && e.response.status == 413) {
                                toast.error(t("image_too_big"), {position: "bottom-right"});
                            }
                        });
                },
                "image/jpeg",
                0.4
            );
        } else {
            api
                .post(`/v1/moment/${moment.id}/guser`, fd)
                .then(() => {
                    fetch();
                    setLoading(false);
                })
                .catch((e) => {
                    setLoading(false);
                    if (e && e.response.status == 413) {
                        toast.error(t("image_too_big"), {position: "bottom-right"});
                    }
                });
        }
    };

    const handleGUserImageChange = (e: any) => {
        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result as any);
        };
        reader.readAsDataURL(files[0]);
    };

    const handleDeleteGUser = () => {
        api.delete(`/v1/moment/${moment.id}/guser`).then(() => {
            fetch();
            setName("");
            setImage(null);
        });
    };

    if (!moment) {
        return null;
    }

    return (
        <Modal show={show} setShow={setShow}>
            {!inviteUser && !configureUser && (
                <>
                    {moment.settings && moment.settings.ghostuser && (
                        <div className="flex justify-between items-center">
                            <div className="flex items-center">
                                <button className="bg-gray-200 p-3 rounded-xl mr-4">
                                    <IconSettings color="black"/>
                                </button>

                                <button
                                    onClick={() => setConfigureUser(true)}
                                    className="font-black"
                                >
                                    {t("configure")}
                                </button>
                            </div>

                            <button
                                onClick={() => setConfigureUser(true)}
                                className="p-3 rounded-xl"
                            >
                                <IconChevronRight color="black"/>
                            </button>
                        </div>
                    )}
                    <div className="flex justify-between items-center">
                        <div className="flex items-center">
                            <button className="bg-gray-200 p-3 rounded-xl mr-4">
                                <IconMailOpened color="black"/>
                            </button>

                            <button
                                onClick={() => setInviteUser(true)}
                                className="font-black"
                            >
                                {t("invite")}
                            </button>
                        </div>

                        <button
                            onClick={() => setInviteUser(true)}
                            className="p-3 rounded-xl"
                        >
                            <IconChevronRight color="black"/>
                        </button>
                    </div>
                </>
            )}
            {inviteUser && (
                <>
                    {t("around")} {moment.typeId == 1 && t("your_partner")}
                    {moment.typeId == 2 && t("your_friend")}
                    {moment.typeId == 3 && t("a_family_member")}{" "}
                    {t("to_your_moment_to_add")}
                    <Setting
                        icon={<IconLock/>}
                        name={t("allow_joining")}
                        end={
                            <div>
                                <label className="switch">
                                    <input
                                        type="checkbox"
                                        defaultChecked={showAccessCode}
                                        onClick={handleToggle}
                                    />
                                    <span className="slider round"></span>
                                </label>
                            </div>
                        }
                    />
                    {showAccessCode && (
                        <>
                            <p>
                                {t("show")} {moment.typeId == 1 && t("your_partner")}
                                {moment.typeId == 2 && t("your_friend")}
                                {moment.typeId == 3 && t("your_family_member")}{" "}
                                {t("this_code_to_allow_him_to_join")}
                            </p>
                            <div className="text-center">
                                <kbd
                                    className="px-2 py-1.5 text-2xl font-semibold text-gray-800 bg-gray-100 border border-gray-200 rounded-lg">
                                    {moment.accessToken}
                                </kbd>
                            </div>
                            <p>
                                {t("you_can")} {moment.typeId == 1 && t("him_her")}
                                {moment.typeId == 2 && t("him_her")}
                                {moment.typeId == 3 && t("them")} {t("also_send_this_link")}
                            </p>
                            <input
                                className="mt-2 block w-full p-4 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                value={`https://infinity.moments.name/join?code=${moment.accessToken}`}
                                disabled
                            />
                            <Button
                                title={t("copy_link")}
                                onClick={async () => {
                                    await navigator.clipboard.writeText(
                                        `https://infinity.moments.name/join?code=${moment.accessToken}`
                                    );
                                    toast.success(t("link_copied"), {position: "bottom-right"});
                                }}
                            />
                        </>
                    )}
                </>
            )}
            {configureUser && (
                <>
                    {moment.guser && (
                        <>
                            <div className="grid grid-cols-3 items-center text-center">
                                <ProtectedImage
                                    className="justify-self-start w-16 h-16 object-cover rounded-full mx-auto"
                                    url={moment.guser.image?.url}
                                />
                                <span className="text-xl text-center font-medium">
                  {moment.guser.name}
                </span>
                            </div>
                            <button
                                onClick={handleDeleteGUser}
                                className="flex w-full justify-center items-center bg-red-700 text-white py-3 rounded-xl mt-4"
                            >
                                <span className="md:text-xl font-bold">{t("delete")}</span>
                            </button>
                        </>
                    )}
                    {!moment.guser && (
                        <>
                            {image && (
                                <div>
                                    <Cropper
                                        style={{height: 400, width: "100%"}}
                                        zoomable={false}
                                        initialAspectRatio={1}
                                        src={image}
                                        viewMode={1}
                                        minCropBoxHeight={100}
                                        minCropBoxWidth={100}
                                        background={false}
                                        responsive={true}
                                        autoCropArea={1}
                                        checkOrientation={false}
                                        onInitialized={(instance) => {
                                            setCropper(instance);
                                        }}
                                        guides={false}
                                        dragMode={"none"}
                                    />
                                    {loading && (
                                        <div className="w-full bg-gray-200 rounded-full h-2.5">
                                            <div
                                                className="bg-blue-600 h-2.5 rounded-full"
                                                style={{width: `${progress}`}}
                                            ></div>
                                        </div>
                                    )}
                                    {!loading && (
                                        <div className="inline-flex w-full">
                                            <button
                                                onClick={() => setImage(null)}
                                                className="cursor-pointer ml-1 mt-1 w-full text-white bg-red-700 hover:bg-gray-800 font-medium rounded-lg text-lg px-5 py-2.5 text-center inline-flex items-center"
                                            >
                                                {t("cancel")}
                                            </button>
                                        </div>
                                    )}
                                </div>
                            )}
                            {!image && (
                                <div className="flex items-center justify-center w-full">
                                    <label
                                        htmlFor={`dropzone-file-gu`}
                                        className="flex flex-col items-center justify-center w-full h-36 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 hover:bg-gray-100"
                                    >
                                        <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                            <IconCloudUpload className="w-10 h-10 mb-3 text-gray-400"/>
                                            <p className="mb-2 text-center text-md text-gray-500">
                        <span className="font-semibold">
                          {t("click_here_to_add_a_picture")}
                        </span>
                                            </p>
                                            <p className="text-md text-gray-500">{t("jpg_jpeg")}</p>
                                        </div>
                                        <input
                                            id={`dropzone-file-gu`}
                                            type="file"
                                            className="hidden"
                                            accept="image/jpeg,image/png"
                                            onChange={handleGUserImageChange}
                                        />
                                    </label>
                                </div>
                            )}
                            <Input
                                label={t("name")}
                                id="name"
                                type="text"
                                minLength={3}
                                maxLength={20}
                                value={name}
                                onChange={(e) => setName(e.currentTarget.value)}
                                placeholder={`${t("name")}`}
                                required
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        handleAddGUser();
                                    }
                                }}
                            />
                            <Button onClick={handleAddGUser} title={t("save")}/>
                        </>
                    )}
                </>
            )}
        </Modal>
    );
};

export default AddUserModal;
