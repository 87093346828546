import React, { useContext, useState } from "react";
import { Context } from "../../../index";
import api from "@services/api.service";
import Button from "@components/ui/Button";
import { useTranslation } from "react-i18next";

const IconMenu = ({ show, fetch }: any) => {
  const { moment }: any = useContext(Context);

  const [title, setTitle] = useState(
    moment.data.title ? moment.data.title : ""
  );

  const { t } = useTranslation();

  const handleUpdateTitle = () => {
    api
      .post(`/v1/moment/${moment.id}/title`, {
        value: title,
      })
      .then(() => {
        fetch();
      });
  };

  if (!show) {
    return null;
  } else {
    return (
      <div className="flex justify-between space-x-4 items-center py-2 top__fade__ani">
        <div className="flex flex-col w-full">
          <label htmlFor="song" className="font-black mb-1">
            {moment.typeId == 3 && t("family_name")}
            {moment.typeId == 4 && t("group_name")}
          </label>
          <input
            name="song"
            className="border-2 outline-none border-border-grey rounded-2xl py-3 px-4 placeholder:font-black mb-2"
            type="text"
            placeholder="Name..."
            required
            value={title}
            onChange={(e) => setTitle(e.currentTarget.value)}
          />
          <Button onClick={handleUpdateTitle} title={t("save")} />
        </div>
      </div>
    );
  }
};

export default IconMenu;
