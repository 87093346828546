import {Moment} from "@pages/moments/Moment";
import {SpotifyWidget} from "@components/moment/SpotifyWidget";
import React from "react";
import FamilyTree from "@components/moment/familytree/FamilyTree";
import Poll from "@components/moment/Poll";
import Gallery from "@components/moment/Gallery";
import Timeline from "@components/moment/Timeline";
import BucketList from "@components/moment/BucketList";

const FriendGroupMoment = ({fetch, moment}: any) => {
    return (
        <Moment fetch={fetch}>
            <Gallery fetch={fetch}/>
            <SpotifyWidget fetch={fetch}/>
            <BucketList/>
            <Poll/>
            <Timeline/>
        </Moment>
    )
}

export default FriendGroupMoment;