// Check for required variables that are not initialized
if (!process.env.REACT_APP_API_HOST) {
    throw new Error("No api host defined");
}

// Map config object for easier access
const config = {
    api: {
        host: process.env.REACT_APP_API_HOST,
    },
};

export default config;