import React, {useEffect, useState} from "react";
import Title from "@components/ui/Title";
import {IconPlus} from "@tabler/icons-react";
import api from "@services/api.service";
import AddMomentModal from "@components/dashboard/AddMomentModal";
import Moment from "@components/dashboard/Moment";
import {useTranslation} from "react-i18next";

const Moments = () => {
    const [moments, setMoments] = useState([]);
    const [loading, setLoading] = useState(true);

    const [showAddMomentModal, setShowAddMomentModal] = useState(false);

    const [noInactives, setNoInactives] = useState(false);

    const {t} = useTranslation<"translation", undefined>();

    useEffect(() => {
        api.get("/v1/user/moments").then((response) => {
            setMoments(response.data || []);
            setLoading(false);
        });
        api.get("/v1/user/moments/inactive").then((response) => {
            if (response.data && response.data.create && response.data.join) {
                if (
                    response.data.create.length == 0 &&
                    response.data.join.length == 0
                ) {
                    setNoInactives(true);
                }
            }
        });
    }, []);

    return (
        <div className="pb-8 w-[90%] md:w-[500px] mx-auto">
            <div className="text-center">
                <div className="">
                    <Title
                        title={t("your_moments")}
                        desc={
                            !loading && moments.length == 0
                                ? t("you_have_not_created_a_moment")
                                : ""
                        }
                    />

                    <div className="grid grid-flow-row grid-cols-3 md:grid-cols-3 gap-x-2 md:gap-x-10 w-full mt-[20px]">
                        {moments.map((moment: any) => {
                            return <Moment key={moment.id} moment={moment}/>;
                        })}
                        {loading && <div className="flex flex-col"/>}
                        {loading && (
                            <div className="flex flex-col">
                                <span className="loading loading-infinity loading-lg"></span>
                            </div>
                        )}
                        <AddMomentModal
                            show={showAddMomentModal}
                            setShow={setShowAddMomentModal}
                        />
                        {!loading && !noInactives && (
                            <div
                                onClick={() => setShowAddMomentModal(true)}
                                className="flex justify-self-center flex-col mx-[0px] pt-[10px] md:pt-[20px]"
                            >
                                <div
                                    className="flex flex-col items-center justify-center h-[80px] md:h-[112px] w-[80px] md:w-[112px] rounded-[12px] bg-[#d1d5db]">
                                    <div className="btn btn-square text-white rounded-lg bg-black">
                                        <IconPlus/>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Moments;
