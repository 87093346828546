import React, {useContext, useEffect, useState} from "react";
import FamilyTree from "./familytree.js";
import "@components/moment/familytree/FamilyTree.style.css";
import {useSearchParams} from "react-router-dom";
import {Context} from "../../../index";
import api from "@services/api.service";
import ImageUploader from "@components/upload/ImageUploader";
import Modal from "@components/ui/Modal";

const Tree = () => {
    const {moment}: any = useContext(Context);

    const [searchParams] = useSearchParams();

    const [showImagePicker, setShowImagePicker] = useState(false);
    const [sender, setSender]: any = useState();
    const [args, setArgs]: any = useState();

    const ref: any = React.createRef();

    const [family, setFamily]: any = useState();

    if (!moment) {
        return null;
    }

    useEffect(() => {
        let fam: FamilyTree = new FamilyTree(ref.current, {
            mode: "light",
            scaleInitial: FamilyTree.match.boundary,
            template: "hugo",
            enableSearch: false,
            enableTouch: true,
            mouseScrool: FamilyTree.action.none,
            nodeMouseClick: FamilyTree.action.pan,
            nodeTreeMenu: searchParams.has("edit"),
            nodeBinding: {
                field_0: "name",
                field_1: "birthday",
                img_0: "photo"
            },
            editForm: {
                buttons: {
                    pdf: null,
                    share: null,
                    //image: {
                    //    icon: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon icon-tabler icons-tabler-outline icon-tabler-camera-selfie"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M5 7h1a2 2 0 0 0 2 -2a1 1 0 0 1 1 -1h6a1 1 0 0 1 1 1a2 2 0 0 0 2 2h1a2 2 0 0 1 2 2v9a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-9a2 2 0 0 1 2 -2"/><path d="M9.5 15a3.5 3.5 0 0 0 5 0"/><path d="M15 11l.01 0"/><path d="M9 11l.01 0"/></svg>`,
                    //    text: 'Bild ändern'
                    //},
                },
                photoBinding: "photo",
                generateElementsFromFields: false,
                elements: [
                    {type: "textbox", label: "Name", binding: "name"},
                    {type: "date", label: "Geburtsdatum", binding: "birthday"},
                    {
                        type: "select", options: [
                            {value: null, text: "Keine Angabe"},
                            {value: "male", text: "Männlich"},
                            {value: "female", text: "Weiblich"}, {
                                value: "diverse", text: "Divers"
                            }],
                        label: "Geschlecht", binding: "gender"
                    },
                ],
                cancelBtn: "Abbrechen",
                saveAndCloseBtn: "Speichern",
                addMore: "",
                addMoreBtn: "",
                addMoreFieldName: ""
            }
        });

        setFamily(fam);

        fam.editUI.on('button-click', (sender: any, args: any) => {
            if (args.name == 'image') {
                setSender(sender);
                setArgs(args);
                setShowImagePicker(true);
            }
        });

        api.get(`/v1/moment/${moment.id}/sector/familytree`).then((response) => {
            if (response.data.length == 0) {
                fam.load([{id: 1, name: "Unbekannt"}]);
                api.post(`/v1/moment/${moment.id}/sector/familytree`, {
                    addNodesData: [{id: 1, name: "Unbekannt"}],
                    updateNodesData: [],
                    removeNodeId: null,
                });
            } else {
                fam.load(response.data);
            }
        });

        fam.onUpdateNode((args: any) => {
            api.post(`/v1/moment/${moment.id}/sector/familytree`, args);
        });

        fam.onNodeClick((args: any) => {
            if (searchParams.has("edit")) {
                fam.editUI.show(args.node.id, false);
            }
        });

    }, []);

    const handleUpload = (data: any) => {
        if (data.url && sender && args) {
            const node = family.get(args.nodeId);
            console.log(node);
            family.updateNode({...node, photo: data.url}, () => null, true);
            sender.setAvatar(data.url);
            setShowImagePicker(false);
            sender.hide();
        }
    }

    const css = `
        html, body {
            width: 100%;
            height: 100%;
            padding: 0;
            margin: 0;
            overflow: hidden;
        }
        
        #tree {
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            width: 100%;
            height: 100%;
        }
    `

    return (
        <>
            <style>{css}</style>
            <Modal show={showImagePicker} setShow={setShowImagePicker}>
                <ImageUploader fixedArea={1} id={1} endpoint={`/v1/moment/${moment.id}/sector/familytree/images`}
                               onFinish={handleUpload}/>
            </Modal>
            <div id="tree" ref={ref}></div>
        </>
    );

};

export default Tree;
