import {Navigate} from "react-router-dom";
import AuthService from "@services/auth.service";
import React from "react";

const ProtectedRoute = (props: { component: React.ReactNode }) => {
    if (!AuthService.loggedIn()) {
        return <Navigate to={`/login?continue=${window.location.pathname}`}/>;
    }

    return (
        <>
            {props.component}
        </>
    )
}

export default ProtectedRoute;