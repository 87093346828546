import {useNavigate, useParams} from "react-router-dom";
import React, {useContext, useEffect} from "react";

import api from "@services/api.service";
import CoupleMoment from "@pages/moments/types/CoupleMoment";
import {FriendshipMoment} from "@pages/moments/types/FriendshipMoment";
import FamilyMoment from "@pages/moments/types/FamilyMoment";
import FamilyMomentTree from "@pages/moments/types/FamilyMomentTree";
import {Context} from "../../index";
import MomentSettings from "@pages/moments/MomentSettings";
import {io, Socket} from "socket.io-client";
import config from "@services/config.service";
import auth from "@services/auth.service";
import FriendGroupMoment from "@pages/moments/types/FriendGroupMoment";
import Tree from "@components/moment/tree/Tree";

const MomentResolver = () => {
    const {moment, setMoment, setFetchMoment, fetchMoment}: any = useContext(Context);
    const {socket, setSocket}: any = useContext(Context);

    const {id} = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        fetch();
    }, []);

    const fetch = () => {
        return api.get(`/v1/moment/${id}`).then((response) => {
            let moment = response.data;

            moment.settings = momentSettings[moment.typeId];

            console.log(location.pathname);

            if (!location.pathname.includes("familytree")) {
                let s: Socket = io(`${config.api.host}?token=${auth.getAccessToken()}`);

                s.on("connect", () => {
                    console.log("socket/connect");
                });

                s.on("disconnect", (e) => {
                    console.log(`socket/${e}`);
                    s = io(`${config.api.host}?token=${auth.getAccessToken()}`);
                    setSocket(s);
                })

                setSocket(s);
            }

            if (response.data && response.data.users.length == 1) {
                api.get(`/v1/moment/${moment.id}/guser`).then((response) => {
                    moment.guser = response.data;
                    setMoment(moment);
                });
            } else {
                setMoment(moment);
            }
        }).catch(e => {
            navigate("/");
        })
    }

    const momentSettings: any = {
        1: {
            anniversary: true,
            ghostuser: true,
            title: false,
            icon: true,
            guser: true,
            relstatus: true
        },
        2: {
            anniversary: false,
            ghostuser: true,
            title: false,
            icon: true,
            guser: true
        },
        3: {
            anniversary: false,
            ghostuser: false,
            title: true,
            icon: false,
            guser: false,
        },
        4: {
            anniversary: false,
            ghostuser: false,
            title: true,
            icon: false,
            guser: false,
        }
    }

    const path = location.pathname.split("/");
    path.shift();

    if (path.length == 2 && path[1] == "tree") {
        if (moment) {
            return <FamilyMomentTree/>
        }
    } else if (path.length == 2 && path[1] == "familytree") {
        if (moment) {
            return <Tree/>
        }
    } else if (path.length == 2 && path[1] == "settings") {
        if (moment) {
            return <MomentSettings fetch={fetch}/>
        }
    }

    if (moment?.type?.id == 1) {
        return <CoupleMoment fetch={fetch}/>
    } else if (moment?.type?.id == 2) {
        return <FriendshipMoment fetch={fetch}/>
    } else if (moment?.type?.id == 3) {
        return <FamilyMoment fetch={fetch}/>
    } else if (moment?.type?.id == 4) {
        return <FriendGroupMoment fetch={fetch}/>
    } else {
        return (
            <React.Fragment/>
        )
    }
}

export default MomentResolver;