import React, {useContext, useEffect, useState} from "react";
import Modal from "@components/ui/Modal";
import {Context} from "../../../index";
import Button from "@components/ui/Button";
import api from "@services/api.service";
import {useTranslation} from "react-i18next";

const AnniversaryChangeModal = ({show, setShow, fetch}: any) => {
    const {moment}: any = useContext(Context);

    const [anniversary, setAnniversary]: any = useState();

    const {t} = useTranslation<"translation">();

    const handleAnniversaryChange = () => {
        let ms = '';
        switch (moment.data?.relstatus) {
            case 1:
                ms = "got_together";
                break;
            case 2:
                ms = "engaged";
                break;
            case 3:
                ms = "married";
                break;
            default:
                ms = "got_together";
                break;
        }

        api.post(`/v1/moment/${moment.id}/sector/milestones/${ms}`, {
            date: anniversary
        }).then(() => {
            fetch();
            location.reload();
            setShow(false);
        });
    };

    useEffect(() => {
        setAnniversary(anni ? anni : "");
    }, [moment]);

    let ms = '';
    switch (moment.data?.relstatus) {
        case 1:
            ms = "got_together";
            break;
        case 2:
            ms = "engaged";
            break;
        case 3:
            ms = "married";
            break;
        default:
            ms = "got_together";
            break;
    }

    const anni = moment.milestones.find((milestone: any) => milestone.milestone == ms)?.date;

    return (
        <Modal show={show} setShow={setShow}>
            <div className="flex flex-col">
                <label htmlFor="anniversary" className="font-black mb-1">
                    {t("anniversary")}
                </label>
                <input
                    name="anniversary"
                    className="border-2 outline-none border-border-grey rounded-2xl py-3 px-4 placeholder:font-black mb-2"
                    type="date"
                    max={new Date().toISOString().split("T")[0]}
                    placeholder="********"
                    minLength={8}
                    required
                    value={anniversary}
                    onChange={(e) => setAnniversary(e.currentTarget.value)}
                />
                <Button onClick={handleAnniversaryChange} title={t("save")}/>
            </div>
        </Modal>
    );
};

export default AnniversaryChangeModal;
