import React, { FormEvent, useEffect, useState } from "react";
import Modal from "@components/ui/Modal";
import { useTranslation } from "react-i18next";
import Button from "@components/ui/Button";
import { toast } from "react-toastify";
import apiService from "@services/api.service";
import authService from "@services/auth.service";
import { useNavigate } from "react-router-dom";

const ChangePasswordModal = ({ show, setShow }: any) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPassword, setRepeatNewPassword] = useState("");

  useEffect(() => {
    if (!show) {
      setCurrentPassword("");
      setNewPassword("");
      setRepeatNewPassword("");
    }
  }, [show]);

  const handleChangePassword = (e: FormEvent) => {
    e.preventDefault();

    if (!currentPassword || !newPassword || !repeatNewPassword) {
      toast.error(t("please_fill_in_all_fields"), { position: "bottom-right" });
      return;
    }

    if (newPassword != repeatNewPassword) {
      toast.error(t("the_passwords_do_not_match"), {
        position: "bottom-right",
      });
      return;
    }

    apiService
      .post("/v1/user/password", {
        current: currentPassword,
        new: newPassword,
        new_confirm: repeatNewPassword,
      })
      .then(() => {
        authService.logout(false);
        toast.success(t("password_changed_successfully"), {
          position: "bottom-right",
        });
        navigate("/login");
      })
      .catch((e) => {
        console.log(e);
        if (e && e.response.data.message) {
          if (e.response.data.message == "invalid-current-password") {
            setCurrentPassword("");
            toast.error(t("your_current_password_is_incorrect"), {
              position: "bottom-right",
            });
          }
        }
      });
  };

  return (
    <Modal title={t("change_password")} show={show} setShow={setShow}>
      <form
        onSubmit={handleChangePassword}
        className="relative flex flex-col space-y-3"
      >
        <div className="flex flex-col">
          <label htmlFor="currPwd" className="font-black mb-1">
            {t("current_password")}
          </label>
          <input
            name="currPwd"
            className="border-2 outline-none border-border-grey rounded-2xl py-3 px-4 placeholder:font-black mb-2"
            type="Password"
            placeholder="********"
            minLength={8}
            required
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.currentTarget.value)}
          />
          <a
            tabIndex={-1}
            href="/reset"
            className="text-right font-black text-black link link-hover"
          >
            {t("forgot_password")}
          </a>
        </div>

        <label htmlFor="newPwd" className="font-black">
          {t("new_password")}
        </label>
        <input
          name="newPwd"
          className="border-2 outline-none border-border-grey rounded-2xl py-3 px-4 placeholder:font-black mb-3"
          type="password"
          placeholder="********"
          minLength={8}
          required
          value={newPassword}
          onChange={(e) => setNewPassword(e.currentTarget.value)}
        />

        <label htmlFor="confirmPwd" className="font-black">
          {t("repeat_new_password")}
        </label>
        <input
          name="confirmPwd"
          className="border-2 outline-none border-border-grey rounded-2xl py-3 px-4 placeholder:font-black mb-1"
          type="password"
          placeholder="********"
          minLength={8}
          required
          value={repeatNewPassword}
          onChange={(e) => setRepeatNewPassword(e.currentTarget.value)}
        />
        <Button title={t("change_password")} />
      </form>
    </Modal>
  );
};

export default ChangePasswordModal;
