import config from "@services/config.service";
import axios, {AxiosRequestConfig} from "axios";
import AuthService from "@services/auth.service";

export default new (class ApiService {
    private readonly host: string;

    constructor() {
        this.host = config.api.host;
    }

    getHost(): string {
        return this.host;
    }

    getDefaultAxiosConfig() {
        if (AuthService.getAccessToken()) {
            return {
                headers: {Authorization: `Bearer ${AuthService.getAccessToken()}`},
            };
        } else {
            return {};
        }
    }

    getCustomAxiosConfig(accessToken: string | undefined, responseType?: string) {
        return {
            headers: {Authorization: `Bearer ${accessToken}`},
            responseType: responseType
        };
    }

    async get(path: string, config?: any, withoutHost?: boolean, auth = true) {
        return await axios.get<any>(
            withoutHost ? path : this.getHost() + path,
            auth ? this.getDefaultAxiosConfig() : config ? config : {},
        );
    }

    put(path: string, body?: any, config?: AxiosRequestConfig, auth = true) {
        return axios.put<any>(
            this.getHost() + path,
            body ? body : {},
            config ? config : auth ? this.getDefaultAxiosConfig() : {}
        );
    }

    patch(path: string, body?: any, config?: AxiosRequestConfig, auth = true) {
        return axios.patch<any>(
            this.getHost() + path,
            body ? body : {},
            config ? config : auth ? this.getDefaultAxiosConfig() : {}
        );
    }

    post(path: string, body?: any, config?: AxiosRequestConfig, auth = true) {
        return axios.post<any>(
            this.getHost() + path,
            body ? body : {},
            config ? config : auth ? this.getDefaultAxiosConfig() : {}
        );
    }

    delete(path: string, config?: any, auth = true) {
        return axios.delete<any>(
            this.getHost() + path,
            auth ? this.getDefaultAxiosConfig() : config ? config : {},
        );
    }
})();