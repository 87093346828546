import React, { useContext } from "react";
import {
  IconLayoutDashboard,
  IconLogout,
  IconNews,
  IconSettings,
  IconX,
} from "@tabler/icons-react";
import MenuItem from "@components/ui/MenuItem";
import AuthService from "@services/auth.service";
import ProtectedImage from "@components/auth/ProtectedImage";
import { Context } from "../../index";
import { useTranslation } from "react-i18next";

const Menu = ({ avatar, hasNews, show, setShow }: any) => {
  const { setOverlay }: any = useContext(Context);

  const { t } = useTranslation<"translation", undefined>();

  const handleClose = () => {
    setShow(false);
    setOverlay(false);
  };

  if (!show) {
    return null;
  }

  return (
    <div className="card bounce-ani shadow-xl absolute top-0 right-0 w-full sm:w-[400px] rounded-3xl -mt-36 bg-white z-[99]">
      <div className="card-body min-h-screen">
        <div className="flex justify-between mt-[150px] mb-4">
          <h1 className="text-3xl text-black font-bold">{t("menu")}</h1>
          <div
            onClick={handleClose}
            className="indicator flex items-center justify-center text-white h-10 w-10 rounded-full bg-black cursor-pointer"
          >
            <IconX />
          </div>
        </div>

        <div className="card card-side shadow-xl items-center px-4">
          <figure className="pr-4">
            <ProtectedImage
              url={avatar}
              alt="Profile picture"
              className="rounded-full w-12 h-12"
            />
          </figure>
          <div className="card-body text-center -mx-8">
            <h2 className="card-title text-black text-[21px]">
              {AuthService.getUser()?.name}
            </h2>
            <p className="text-[#aaa] -mt-1.5">
              {AuthService.getUser()?.email}
            </p>
          </div>
        </div>

        <div className="border-[2px] border-gray-300 rounded-3xl mt-4 py-4 px-5">
          <MenuItem
            setMenu={setShow}
            title={t('dashboard')}
            link="/"
            icon={<IconLayoutDashboard color="gray" />}
          />
          <div className="indicator">
            {hasNews && (
              <span className="indicator-item indicator-start badge badge-xs bg-red-600 mt-2 ml-12"></span>
            )}
            <MenuItem
              setMenu={setShow}
              title={t('news_and_update')}
              link="/news"
              icon={<IconNews color="gray" />}
            />
          </div>
          <MenuItem
            setMenu={setShow}
            title={t('settings')}
            link="/settings"
            icon={<IconSettings color="gray" />}
          />
          <MenuItem
            setMenu={setShow}
            title={t('log_out')}
            link="/logout"
            icon={<IconLogout color="gray" />}
          />
        </div>
      </div>
    </div>
  );
};

export default Menu;
