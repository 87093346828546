import React from "react";

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    id: string;
    label: string;
}

const Input = ({...props}: InputProps) => {
    return (
        <div className="flex justify-between items-center border-b border-border-grey mt-3 mb-3">
            <div className="flex flex-col flex-1">
                <label htmlFor={props.id} className="font-black mb-1 text-left">
                    {props.label}
                </label>
                <input
                    name={props.id}
                    className="outline-none placeholder:font-black mb-[1px]"
                    {...props}
                />
            </div>
        </div>
    )
}

export default Input;