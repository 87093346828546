import React, { useContext, useEffect, useState } from "react";
import auth from "@services/auth.service";
import { Socket } from "socket.io-client";
import { LeafPoll, Result } from "react-leaf-polls";
import "react-leaf-polls/dist/index.css";
import { Context } from "../../index";
import { isDemo } from "@services/utils.service";
import Title from "@components/moment/settings/Title";
import AddPollModal from "@components/moment/modals/AddPollModal";
import OldPollsModal from "@components/moment/modals/OldPollsModal";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const Poll = () => {
  const { moment }: any = useContext(Context);
  const { socket }: { socket: Socket } = useContext(Context);

  const [polls, setPolls]: any = useState([]);
  const [active, setActive]: any = useState(null);
  const [activeData, setActiveData]: any = useState(null);
  const [hasVoted, setHasVoted]: any = useState(false);
  const [aSeed, setASeed] = useState(1);

  const { t } = useTranslation("");

  const reset = () => {
    setASeed(Math.random());
  };

  useEffect(() => {
    if (socket && moment) {
      socket.emit("polls/get", { momentId: moment.id });
      socket.on("polls/get", (data) => {
        updatePollData(data);
        console.log("polls/get", data);
      });

      socket.on("polls/update", (data) => {
        updatePollData(data);
        console.log("polls/update", data);
      });
    }
  }, [socket]);

  const updatePollData = (data: any) => {
    if (data.momentId && data.momentId == moment.id) {
      if (data.active) {
        setActive(data.active);
        setActiveData(getResData(data.active));
        setHasVoted(getHasVoted(data.active));
      } else {
        setActive(null);
        setActiveData(null);
        setHasVoted(false);
      }
      if (data.inactive) {
        setPolls(data.inactive);
      }
      reset();
    }
  };

  const getHasVoted = (poll: any) => {
    let check = false;
    for (const vote of poll.votes) {
      if (vote.userId == auth.getUser()?.id) {
        check = true;
      }
    }
    return check;
  };

  const getResData = (poll: any) => {
    const res = [];
    for (const option of poll.options) {
      let voteCount = 0;
      for (const vote of poll.votes) {
        if (vote.optionId == option.id) {
          voteCount++;
        }
      }
      res.push({
        id: option.index,
        text: option.name,
        votes: voteCount,
        percentage: Math.round((voteCount * 100) / poll.votes.length) | 0,
      });
    }
    return res;
  };

  const onVote = (item: Result) => {
    const index = item.id;
    let optionId;
    for (const option of active.options) {
      if (option.index == index) {
        optionId = option.id;
      }
    }
    if (optionId) {
      socket.emit("polls/vote", { momentId: moment.id, optionId: optionId });
      console.log("polls/vote");
    }
  };

  const [showAddPollModal, setShowAddPollModal] = useState(false);

  const [showOldPollsModal, setShowOldPollsModal] = useState(false);

  const addPoll = () => {
    if (!active) {
      setShowAddPollModal(true);
      return;
    }
    const d1 = new Date(active.createdAt);
    d1.setDate(d1.getDate() + 5);
    if (
      active &&
      active.votes.length != moment.users.length &&
      !(new Date() > d1)
    ) {
      toast.info(t("a_new_vote_can_only_be_created"));
      return;
    }
    setShowAddPollModal(true);
  };

  return (
    <>
      <AddPollModal
        show={showAddPollModal}
        setShow={setShowAddPollModal}
        socket={socket}
      />
      <OldPollsModal
        show={showOldPollsModal}
        setShow={setShowOldPollsModal}
        getResData={getResData}
        polls={polls}
      />
      <div className="wrapper flex justify-between items-end py-5">
        <Title title={t("votes")} />
        {!isDemo() && (
          <button>
            <p
              onClick={addPoll}
              className="underline font-gilroy font-black text-lg md:text-xl"
            >
              {t("create")}
            </p>
          </button>
        )}
      </div>
      <div className="wrapper mt-5">
        {active && (
          <LeafPoll
            key={aSeed}
            type="multiple"
            question={active.title}
            results={activeData}
            onVote={onVote}
            isVoted={hasVoted}
          />
        )}
        {isDemo() && (
          <LeafPoll
            key={"0"}
            type="multiple"
            question={`${t("where_is_the_next_short_trip_going")}`}
            results={[
              {
                id: 1,
                text: t("palma"),
                votes: 0,
                percentage: 0,
              },
              {
                id: 2,
                text: t("lisbon"),
                votes: 0,
                percentage: 0,
              },
              {
                id: 3,
                text: t("rome"),
                votes: 0,
                percentage: 0,
              },
            ]}
            isVoted={false}
          />
        )}
        {!active && !isDemo() && (
          <h2 className="text-center text-lg">{t("no_voting_active")}</h2>
        )}
        {hasVoted && (
          <button
            onClick={() => {
              setHasVoted(false);
              reset();
            }}
            type="button"
            className="w-full mb-1 mt-3 text-white bg-red-700 hover:bg-red-800 font-medium rounded-lg text-sm p-2.5 inline-flex table-cell items-center"
          >
            {t("delete_voice")}
          </button>
        )}
        {polls[0] && (
          <>
            <div className="flex justify-between items-end py-5">
              <Title title={t("results")} />
              {!isDemo() && (
                <button>
                  <p
                    onClick={() => setShowOldPollsModal(true)}
                    className="underline font-gilroy font-black text-lg md:text-xl"
                  >
                    {t("older")}
                  </p>
                </button>
              )}
            </div>
            <LeafPoll
              key={polls[0]?.id}
              type="multiple"
              question={polls[0]?.title}
              results={getResData(polls[0])}
              isVoted={true}
            />
          </>
        )}
      </div>
    </>
  );
};

export default Poll;
