import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import Languagedetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

i18n.use(Backend).use(Languagedetector).use(initReactI18next).init({
    fallbackLng: "de",
    debug: false
});

export default i18n;
