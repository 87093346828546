import Modal from "@components/ui/Modal";
import { getMonthName } from "@services/utils.service";
import React, { useState } from "react";
import apiService from "@services/api.service";
import Button from "@components/ui/Button";
import { useTranslation } from "react-i18next";

const ProductModal = ({ product, show, setShow }: any) => {
  const redeemedAt: any = product ? new Date(product.redeemedAt) : null;

  const [confirmReset, setConfirmReset] = useState(false);

  const { t } = useTranslation();

  const handleReset = () => {
    apiService.post(`/v1/token/${product.tokenId}/reset`).then(() => {
      setShow(false);
      window.location.reload();
    });
  };

  if (product) {
    return (
      <Modal title={t("product")} show={show} setShow={setShow}>
        {!confirmReset && (
          <>
            <div className="card card-side h-full w-full items-center space-x-4 justify-between bg-white mt-5 md:mt-6">
              <div className="px-0 md:px-2">
                <img
                  src={`/static/products/${product.productId}.png`}
                  alt="Product"
                  className="rounded-lg w-28 h-20 sm:28 sm:h-20 object-cover"
                />
              </div>

              <div className="text-left">
                <h2 className="text-sm md:text-md font-semibold text-black break-words">
                  {product.product.name}
                </h2>
                <p className="text-[#aaa] text-sm">{product.tokenId}</p>
                <p className="text-[#666666] font-semibold text-sm">
                  {t("enabled")} <span className="font-bold">·</span>{" "}
                  {`${redeemedAt.getDay()}. ${getMonthName(
                    redeemedAt.getMonth()
                  )} ${redeemedAt.getFullYear()}`}
                </p>
              </div>
            </div>
            <button
              onClick={() => setConfirmReset(true)}
              className="flex justify-center items-center bg-red-700 text-white py-3 rounded-xl mb-4"
            >
              <span className="md:text-xl">{t("reset_product_btn")}</span>
            </button>
          </>
        )}
        {confirmReset && (
          <>
            <p className="mt-3">{t("reset_product")}</p>
            <p className="font-bold underline">
              {t("this_action_cannot_be_undone")}
            </p>
            <Button onClick={() => setShow(false)} title={t("cancel")} />
            <button
              onClick={handleReset}
              className="flex justify-center items-center bg-red-700 text-white py-3 rounded-xl -mt-5"
            >
              <span className="md:text-xl">{t("confirm")}</span>
            </button>
          </>
        )}
      </Modal>
    );
  } else {
    return <></>;
  }
};

export default ProductModal;
