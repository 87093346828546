import i18n from "i18next";


const getMonthName = (index: number, short?: boolean): string => {
    const t = i18n.t;
    let months = [];
    if (short) {
        months = [t("jan"), t("feb"), t("mar"), t("apr"), t("may"), t("jun"),
            t("jul"), t("aug"), t("sep"), t("oct"), t("nov"), t("dec")
        ];
    } else {
        months = [t("january"), t("february"), t("march"), t("april"), t("may"), t("june"),
            t("july"), t("august"), t("september"), t("october"), t("november"), t("december")
        ];
    }
    if (index > 11) index = 11;
    if (index < 0) index = 0;
    return months[index];
}

const validSpotifyUrl = (url: string): boolean => {
    return new RegExp("^(https:\\/\\/open.spotify.com\\/playlist\\/|https:\\/\\/open.spotify.com\\/track\\/|https:\\/\\/open.spotify.com\\/intl-de\\/track\\/|https:\\/\\/open.spotify.com\\/intl-de\\/playlist\\/)([a-zA-Z0-9]+)(.*)$").test(url);
}

const spotifyUrlToEmbed = (url: string): string => {
    if (validSpotifyUrl(url)) {
        const parts = url.split("/");
        if (url.includes("intl-de")) {
            return `https://open.spotify.com/embed/${parts[4]}/${parts[5]}`;
        } else {
            return `https://open.spotify.com/embed/${parts[3]}/${parts[4]}`;
        }
    }
    return "";
}

const longestWord = (string: string) => {
    var str = string.split(" ");
    var longest = 0;
    var word = null;
    str.forEach(function (str) {
        if (longest < str.length) {
            longest = str.length;
            word = str;
        }
    });
    return word || "";
}


const isDemo = (): boolean => {
    return window.location.href.includes("demo");
}

const base64ToBlob = (base64: any, mime: any) => {
    mime = mime || '';
    let sliceSize = 1024;
    let byteChars = window.atob(base64);
    let byteArrays = [];

    for (let offset = 0, len = byteChars.length; offset < len; offset += sliceSize) {
        let slice = byteChars.slice(offset, offset + sliceSize);

        let byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        let byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, {type: mime});
}

const validEmail = (email: string) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

const firstUC = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export {
    longestWord,
    validEmail,
    isDemo,
    base64ToBlob,
    validSpotifyUrl,
    spotifyUrlToEmbed,
    getMonthName,
    firstUC
}