import { Main } from "@components/layout/Main";
import React, { useEffect, useState } from "react";
import apiService from "@services/api.service";
import { Link, useNavigate } from "react-router-dom";
import NewsArticle from "@components/news/NewsArticle";
import Title from "@components/moment/settings/Title";
import { IconChevronLeft } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

const NewsListPage = () => {
  const { t } = useTranslation();
  const [news, setNews]: any = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    apiService.get("/v1/news").then((response) => {
      setNews(response.data);
    });
  }, []);

  if (!news) {
    return (
      <Main>
        <div className="wrapper">
          <span onClick={() => navigate(-1)} className="flex cursor-pointer">
            <IconChevronLeft /> {t("back")}
          </span>
        </div>
        <div className="wrapper text-center">
          <Title title={t("news_and_update")} />
        </div>
        <div className="flex flex-col">
          <span className="mx-auto mt-10 loading loading-infinity loading-lg w-20"></span>
        </div>
      </Main>
    );
  } else if (news && news.length == 0) {
    return (
      <Main>
        <div className="wrapper">
          <span onClick={() => navigate(-1)} className="flex cursor-pointer">
            <IconChevronLeft /> {t("back")}
          </span>
        </div>
        <div className="wrapper text-center">
          <Title title={t("news_and_update")} />
        </div>
        <div className="flex flex-col text-center mt-10 mx-auto">
          <span className="text-xl font-bold">{t("no_news_available")}</span>
          <Link to="/" className="link link-hover text-lg">
            {t("back_to_the_dashboard")}
          </Link>
        </div>
      </Main>
    );
  } else {
    return (
      <Main>
        <div className="wrapper">
          <span onClick={() => navigate(-1)} className="flex cursor-pointer">
            <IconChevronLeft /> {t("back")}
          </span>
        </div>
        <div className="wrapper text-center">
          <Title title={t("news_and_update")} />
        </div>
        {news.map((news: any) => {
          return <NewsArticle news={news} key={news.id} />;
        })}
      </Main>
    );
  }
};

export default NewsListPage;
