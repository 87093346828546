import Modal from "@components/ui/Modal";
import React, { FormEvent, useEffect, useState } from "react";
import api from "@services/api.service";
import {
  IconChevronRight,
  IconCirclesRelation,
  IconPlus,
} from "@tabler/icons-react";
import Input from "@components/ui/Input";
import Button from "@components/ui/Button";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AddMomentModal = ({ show, setShow }: any) => {
  const navigate = useNavigate();
  const [search] = useSearchParams();

  const [join, setJoin]: any = useState(false);
  const [create, setCreate]: any = useState(false);

  const [inactives, setInactives]: any = useState({});

  const { t } = useTranslation();

  useEffect(() => {
    if (!show) {
      setJoin(false);
      setCreate(false);
    }
  }, [show]);

  useEffect(() => {
    if (search.get("code")) {
      setJoinCode(String(search.get("code")));
    }

    api.get("/v1/user/moments/inactive").then((response) => {
      setInactives(response.data || {});
    });
  }, []);

  const [joinCode, setJoinCode] = useState("");

  const handleJoin = (e: FormEvent) => {
    e.preventDefault();
    api
      .post(`/v1/moment/join/${joinCode}`)
      .then(() => {
        toast.success(t("accession_successful"), { position: "bottom-right" });
        navigate("/");
        setShow(false);
      })
      .catch((e) => {
        if (e.response.data.message) {
          if (e.response.data.message == "Unauthorized") {
            toast.error(t("unable_to_join"), { position: "bottom-right" });
          } else if (e.response.data.message == "already-member") {
            toast.error(t("you_are_already_a_member"), {
              position: "bottom-right",
            });
          } else if (e.response.data.message == "limit-reached") {
            toast.error(t("Unable_to_join_maximum_number_of_members_reached"), {
              position: "bottom-right",
            });
          } else if (e.response.data.message == "no-token") {
            toast.error(t("you_are_already_a_member_or_cannot_join"), {
              position: "bottom-right",
            });
          } else if (e.response.data.message == "overlaps") {
            toast.error(t("You_are_already_in_a_moment_with_this_user"), {
              position: "bottom-right",
            });
          }
        } else {
          toast.error(t("joining_not_possible"), { position: "bottom-right" });
        }
      });
  };

  const handleCreate = (tokenId: string, type: number) => {
    api
      .post(`/v1/moment`, {
        token: tokenId,
        type: type,
      })
      .then((response: any) => {
        if (response.data && response.data.id) {
          toast.success(t("moment_created_successfully"), {
            position: "bottom-right",
          });
          navigate(`/${response.data.id}`);
        }
      })
      .catch(() => {
        toast.error(t("invalid_token"), { position: "bottom-right" });
      });
    setShow(false);
  };

  return (
    <Modal title={t("add_moment")} show={show} setShow={setShow}>
      {!create && !join && (
        <>
          {inactives.create && inactives.create.length != 0 && (
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <button className="bg-gray-200 p-3 rounded-xl mr-4">
                  <IconPlus color="black" />
                </button>

                <button onClick={() => setCreate(true)} className="font-black">
                  {t("create_moment")}
                </button>
              </div>

              <button
                onClick={() => setCreate(true)}
                className="p-3 rounded-xl"
              >
                <IconChevronRight color="black" />
              </button>
            </div>
          )}
          {inactives.join && inactives.join.length != 0 && (
            <div className="flex justify-between items-center">
              <div className="flex items-center">
                <button className="bg-gray-200 p-3 rounded-xl mr-4">
                  <IconCirclesRelation color="black" />
                </button>

                <button onClick={() => setJoin(true)} className="font-black">
                  {t("join_now")}
                </button>
              </div>

              <button onClick={() => setJoin(true)} className="p-3 rounded-xl">
                <IconChevronRight color="black" />
              </button>
            </div>
          )}
        </>
      )}
      {create &&
        inactives &&
        inactives.create.map((moment: any) => {
          return (
            <div
              onClick={() =>
                handleCreate(moment.tokenId, moment.policy.momentTypeId)
              }
              key={`${moment.tokenId}-${moment.policy.momentTypeId}`}
              className="flex justify-between items-center"
            >
              <div className="flex items-center">
                <button className="bg-gray-200 p-3 rounded-xl mr-4">
                  <IconPlus color="black" />
                </button>

                <button onClick={() => setCreate(true)} className="font-black">
                  {moment.policy.momentType.name}
                </button>
              </div>

              <button
                onClick={() => setCreate(true)}
                className="p-3 rounded-xl"
              >
                <IconChevronRight color="black" />
              </button>
            </div>
          );
        })}
      {join && (
        <>
          <div className="mt-3 mb-3 text-md">
            <span className="font-bold text-lg">{t("tip")}</span>
            {t("you_can_find_this_code")}
          </div>
          <p className="mt-3">{t("enter_a_membership_code_here")}</p>
          <form onSubmit={handleJoin}>
            <Input
              id="joincode"
              label={t("join_code")}
              value={joinCode}
              onChange={(e) => setJoinCode(e.currentTarget.value.toUpperCase())}
              type="text"
              placeholder="ABCABCABC"
              minLength={9}
              maxLength={9}
              required
            />
            <Button title={t("join_now")} type="submit" />
          </form>
        </>
      )}
    </Modal>
  );
};

export default AddMomentModal;
