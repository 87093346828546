import {Link} from "react-router-dom";
import React, {useContext} from "react";
import {Context} from "../../index";
import {IconChevronRight} from "@tabler/icons-react";

const MenuItem = ({title, link, icon, setMenu}: any) => {
    const {setOverlay}: any = useContext(Context);

    const handleClose = () => {
        setOverlay(false);
        setMenu(false);
    }

    return (
        <Link to={link} onClick={handleClose}>
            <div className="card card-side rounded-l-lg rounded-r-lg items-center">
                <figure className="rounded-md bg-[#eee] p-3 h-12 w-12">
                    {icon}
                </figure>
                <div className="card-body px-4 py-4 text-center -mx-">
                    <h2 className="card-title text-black font-semibold text-[15px] md:text-[16px]">
                        {title}
                    </h2>
                </div>
                <div className="card-actions bg-none">
                    <div
                        className="btn btn-xs bg-transparent hover:bg-transparent border-none text-black">
                        <IconChevronRight color="gray"/>
                    </div>
                </div>
            </div>
        </Link>
    )
}

export default MenuItem;