import React, {useContext} from "react";
import api from "@services/api.service";
import {Context} from "../../../index";
import {useTranslation} from "react-i18next";

const RelstatusMenu = ({show, fetch}: any) => {
    const {moment}: any = useContext(Context);

    const {t} = useTranslation();

    const handleRelstatusChange = (relstatus: number) => {
        api
            .post(`/v1/moment/${moment.id}/relstatus`, {
                value: relstatus,
            })
            .then(() => {
                api.get(`/v1/moment/${moment.id}/sector/milestones`).then((milestones) => {
                    let ms = '';
                    switch (moment.data?.relstatus) {
                        case 1:
                            ms = "got_together";
                            break;
                        case 2:
                            ms = "engaged";
                            break;
                        case 3:
                            ms = "married";
                            break;
                        default:
                            ms = "got_together";
                            break;
                    }
                    const current = milestones.data.find((milestone: any) => milestone.milestone = ms);
                    switch (relstatus) {
                        case 1:
                            ms = "got_together";
                            break;
                        case 2:
                            ms = "engaged";
                            break;
                        case 3:
                            ms = "married";
                            break;
                        default:
                            ms = "got_together";
                            break;
                    }
                    api.post(`/v1/moment/${moment.id}/sector/milestones/${ms}`, {
                        date: current.date,
                    }).then(() => {
                        fetch();
                    });
                })
            });
    };

    if (!show) {
        return null;
    } else {
        return (
            <div className="flex justify-end space-x-4 items-center py-2 top__fade__ani">
                <div className="flex items-center space-x-4">
                    <button
                        onClick={() => handleRelstatusChange(1)}
                        className="w-max bg-light-grey p-4 rounded-xl border"
                    >
                        {t("together")}
                    </button>
                </div>

                <div className="flex items-center space-x-4">
                    <button
                        onClick={() => handleRelstatusChange(2)}
                        className="w-max bg-light-grey p-4 rounded-xl border"
                    >
                        {t("engaged")}
                    </button>
                </div>

                <div className="flex items-center space-x-4">
                    <button
                        onClick={() => handleRelstatusChange(3)}
                        className="w-max bg-light-grey p-4 rounded-xl border"
                    >
                        {t("married")}
                    </button>
                </div>
            </div>
        );
    }
};

export default RelstatusMenu;
