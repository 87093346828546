import CoupleMoment from "@pages/moments/types/CoupleMoment";
import {useContext, useEffect} from "react";
import {Context} from "../index";

const DemoPage = () => {

    const {setMoment}: any = useContext(Context);

    useEffect(() => {
        setMoment({
            id: "",
            typeId: 1,
            accessToken: "",
            allowJoin: false,
            users: [
                {name: "Florian", avatar: "/static/demo/florian.jpg"},
                {name: "Sandra", avatar: "/static/demo/sandra.jpg"}
            ],
            images: {
                "@banner": [
                    {url: "/static/demo/banner.jpg"}
                ],
                "@gallery": [
                    {url: "/static/demo/gallery-1.jpg"},
                    {url: "/static/demo/gallery-2.jpg"},
                    {url: "/static/demo/gallery-3.jpg"},
                    {url: "/static/demo/gallery-4.jpg"},
                    {url: "/static/demo/gallery-5.jpg"},
                    {url: "/static/demo/gallery-6.jpg"},
                ]
            },
            data: {
                anniversary: "2021-01-11",
                spotify_url: "https://open.spotify.com/track/1SOClUWhOi8vHZYMz3GluK?si=75801ef336124489",
                relstatus: 1
            }
        })
    }, [])

    return (
        <CoupleMoment fetch={() => null}/>
    )
}

export default DemoPage;