import React from "react";

const Setting = ({icon, name, value, end, onClick}: any) => {
    return (
        <div onClick={onClick} className="flex justify-between items-center space-x-4">
            <div className="flex items-center space-x-2">
                <div className="w-max bg-light-grey p-4 rounded-xl">
                    {icon}
                </div>
                <h2 className="md:text-2xl font-bold">{name}</h2>
            </div>

            <div className="flex items-center space-x-4">
                <p className="md:text-xl font-bold text-text-grey">{value}</p>
                {end}
            </div>

        </div>
    )
}

export default Setting;