import {useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import api from "@services/api.service";
import {toast} from "react-toastify";

const VerifyEmailChangePage = () => {
    const {verificationId} = useParams();

    const navigate = useNavigate();

    const {t} = useTranslation();

    useEffect(() => {
        api.post("/v1/user/email/verify", {
            token: verificationId
        }).then((response) => {
            if (response.status == 201) {
                toast.success(t("email_verified"), {position: "bottom-right"})
                navigate(`/login`);
            }
        }).catch((e) => {
            toast.error(t("invalid_verification"), {position: "bottom-right"});
            navigate("/login");
        });
    }, [verificationId])

    return (
        <></>
    )
}

export default VerifyEmailChangePage;