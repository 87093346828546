import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import Menu from "@components/ui/Menu";
import api from "@services/api.service";
import auth from "@services/auth.service";
import authService from "@services/auth.service";
import {isDemo} from "@services/utils.service";
import ProtectedImage from "@components/auth/ProtectedImage";
import {Context} from "../../index";

const Nav = () => {
    const {overlay, setOverlay}: any = useContext(Context);

    const navigate = useNavigate();

    const [avatar, setAvatar] = useState("");

    const [showMenu, setShowMenu] = useState(false);

    const handleShowMenu = () => {
        setOverlay(true);
        setShowMenu(true);
    }

    const [hasNews, setHasNews] = useState(false);

    const checkForNews = () => {
        api.get("/v1/news/unread").then((response) => {
            setHasNews(response.data.hasNews);
        })
    }

    useEffect(() => {
        if (location.pathname.includes("news")) {
            checkForNews();
        }
    }, [location.pathname]);

    useEffect(() => {
        if (!isDemo()) {
            api.get("/v1/user").then((res) => {
                if (res.data.avatar) {
                    setAvatar(res.data.avatar);
                    authService.storeAvatar(res.data.avatar);
                }
                checkForNews();
            }).catch(() => {
                auth.logout();
                navigate("/");
            })
        }
    }, [])

    return (
        <div className="navbar pt-4 w-[90%] md:w-[90%] mx-auto">
            <Menu hasNews={hasNews} show={showMenu} setShow={setShowMenu} avatar={avatar} setOverlay={setOverlay}/>

            <div className="flex-1">
                <Link
                    to="/"
                    className="text-xl font-bold text-black hover:bg-transparent bg-transparent"
                >
                    <img src="/icon.png" alt="logo" width={50} height={50}/>
                </Link>
            </div>
            {!isDemo() && (
                <div className="flex-none">
                    <div className="dropdown dropdown-end" onClick={handleShowMenu}>
                        <div className="indicator">
                            <label tabIndex={0} className="btn btn-ghost btn-circle avatar">
                                {hasNews && (
                                    <span className="indicator-item badge badge-xs bg-red-600 mt-2 mr-2"></span>
                                )}
                                <div className="w-10 rounded-full">
                                    <ProtectedImage className="rounded-full" url={avatar} alt="Profile picture"/>
                                </div>
                            </label>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Nav;
