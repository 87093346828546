import React, { useEffect, useState } from "react";
import Title from "@components/ui/Title";
import apiService from "@services/api.service";
import { IconChevronRight } from "@tabler/icons-react";
import ProductModal from "@components/dashboard/ProductModal";
import { useTranslation } from "react-i18next";

const Products = () => {
  const [products, setProducts]: any = useState(null);

  const [product, setProduct]: any = useState(null);

  const [showProductModal, setShowProductModal] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    apiService.get("/v1/user/products").then((response) => {
      setProducts(response.data);
    });
  }, []);

  if (!products) {
    return (
      <div className="pb-8 w-[90%] md:w-[500px] mx-auto mt-8">
        <div className="text-center">
          <div className="max-w-full">
            <Title title={t("your_products")} />
            <div className="flex flex-col">
              <span className="mx-auto mt-10 loading loading-infinity loading-lg w-20"></span>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (products && products.length == 0) {
    return (
      <div className="pb-8 w-[90%] md:w-[500px] mx-auto mt-8">
        <div className="text-center">
          <div className="max-w-full">
            <Title title={t("your_products")} />
            <div className="flex flex-col text-center mt-10 mx-auto">
              <span className="text-xl font-bold">{t("no_products")}</span>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="pb-8 w-[90%] md:w-[500px] mx-auto mt-8">
        <div className="text-center">
          <div className="max-w-full">
            <Title title={t("your_products")} />
            <ProductModal
              show={showProductModal}
              setShow={setShowProductModal}
              product={product}
            />
            {products.map((product: any, i: number) => {
              const redeemedAt = new Date(product.redeemedAt);
              return (
                <div
                  key={i}
                  className="card card-side h-36 md:h-36 items-center space-x-4 justify-between w-full md:w-[500px] bg-white shadow-xl mt-5 md:mt-6 px-4"
                >
                  <div className="px-0 md:px-2">
                    <img
                      src={`/static/products/${product.productId}.png`}
                      alt="Product"
                      className="rounded-lg w-28 h-20 sm:28 sm:h-20 object-cover"
                    />
                  </div>

                  <div className="text-left">
                    <h2 className="text-sm md:text-md font-semibold text-black break-words">
                      {product.product.name}
                    </h2>
                    <p className="text-[#aaa] text-sm">{product.tokenId}</p>
                  </div>

                  <div
                    onClick={() => {
                      setShowProductModal(true);
                      setProduct(product);
                    }}
                    className="card-actions px-0 md:px-2"
                  >
                    <button className="btn btn-square rounded-xl">
                      <IconChevronRight color="gray" />
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
};

export default Products;
