import React, { FormEvent, useEffect, useState } from "react";
import Modal from "@components/ui/Modal";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import apiService from "@services/api.service";
import authService from "@services/auth.service";
import { useNavigate } from "react-router-dom";
import Button from "@components/ui/Button";

const ChangePasswordModal = ({ show, setShow }: any) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [confirm, setConfirm] = useState(false);
  const [secondConfirm, setSecondConfirm] = useState(false);

  useEffect(() => {
    if (!show) {
      setEmail("");
      setConfirm(false);
      setSecondConfirm(false);
    }
  }, [show]);

  const handleAccountDelete = (e: FormEvent) => {
    e.preventDefault();

    if (email != authService.getUser()?.email) {
      toast.error(t("the_email_you_entered_was_in_correct"), {
        position: "bottom-right",
      });
      return;
    }

    apiService
      .delete("/v1/user")
      .then(() => {
        authService.logout(false);
        toast.success(t("your_account_has_been_successfully_deleted"), {
          position: "bottom-right",
        });
        navigate("/login");
      })
      .catch((e) => {
        if (e && e.response.data.message) {
          if (e.response.data.message == "wrong identifier") {
            setShow(false);
            toast.error(t("the_email_you_entered_was_incorrect"), {
              position: "bottom-right",
            });
          }
        }
      });
  };

  return (
    <Modal title={t("delete_account")} show={show} setShow={setShow}>
      {!confirm && !secondConfirm && (
        <>
          <span>{t("delete_warning")}</span>
          <span>
            <u>{t("this_action_cannot_be_undone")}</u>
          </span>
          <Button title={t("cancel")} onClick={() => setShow(false)} />
          <Button
            modifiers="bg-red-600"
            title={t("confirm")}
            onClick={() => setConfirm(true)}
          />
        </>
      )}
      {confirm && !secondConfirm && (
        <>
          <span>
            {t("please_enter_the_current_email_from_your_account_to_confirm")}
          </span>
          <label htmlFor="email" className="font-black">
            {t("your_account_email")}
          </label>
          <input
            name="email"
            className="border-2 outline-none border-border-grey rounded-2xl py-3 px-4 placeholder:font-black mb-1"
            type="email"
            placeholder="mail@mail.com"
            minLength={8}
            required
            value={email}
            onChange={(e) => setEmail(e.currentTarget.value)}
          />
          <Button title={t("cancel")} onClick={() => setShow(false)} />
          <Button
            modifiers="bg-red-600"
            title={t("confirm")}
            onClick={() => {
              if (email) {
                setSecondConfirm(true);
              } else {
                toast.error(t("please_enter_your_account_email"), {
                  position: "bottom-right",
                });
              }
            }}
          />
        </>
      )}
      {confirm && secondConfirm && (
        <>
          <span>{t("permanent_delete_warning", { email: email })}</span>
          <span>
            <u>{t("this_action_cannot_be_undone")}</u>
          </span>
          <Button title={t("cancel")} onClick={() => setShow(false)} />
          <Button
            modifiers="bg-red-600"
            title={t("yes_delete_my_account")}
            onClick={handleAccountDelete}
          />
        </>
      )}
    </Modal>
  );
};

export default ChangePasswordModal;
