import React, { useContext, useState } from "react";
import {
  isDemo,
  spotifyUrlToEmbed,
  validSpotifyUrl,
} from "@services/utils.service";
import { Context } from "../../index";
import Title from "@components/moment/settings/Title";
import EditSongModal from "@components/moment/modals/EditSongModal";
import { useTranslation } from "react-i18next";

const SpotifyWidget = ({ url, fetch }: any) => {
  const { moment }: any = useContext(Context);

  const [showEditSong, setShowEditSong] = useState(false);

  const { t } = useTranslation<"translation", undefined>();

  if (!moment) {
    return null;
  }

  return (
    <>
      <EditSongModal
        fetch={fetch}
        show={showEditSong}
        setShow={setShowEditSong}
      />
      <div className="wrapper flex justify-between items-end py-5">
        <Title title={t("your_song")} />
        {!isDemo() && (
          <button>
            <p
              onClick={() => setShowEditSong(true)}
              className="underline font-gilroy font-black text-lg md:text-xl"
            >
              {t("edit")}
            </p>
          </button>
        )}
      </div>
      {!moment.data.spotify_url && (
        <div className="wrapper">
          <p className="font-bold text-sm">
            {t("you_havent_decided_on_a_song_yet")}
          </p>
        </div>
      )}
      {moment.data.spotify_url && validSpotifyUrl(moment.data.spotify_url) && (
        <div className="mx-2 md:mx-auto lg:mx-auto p-1 rounded-xl md:w-3/5 lg:w-2/5">
          <iframe
            title="spotify"
            src={spotifyUrlToEmbed(moment.data.spotify_url)}
            width="100%"
            height="80"
            frameBorder="0"
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
            loading="lazy"
          ></iframe>
        </div>
      )}
    </>
  );
};

export { SpotifyWidget };
