import React from "react";
import PageTitle from "@components/ui/PageTitle";

const TermsOfServicePage = () => {
    return (
        <div className="w-full bg-white relative pb-8 mt-5">
            <PageTitle noBack={true} title={"Nutzungsbedingungen"}/>
            <div className="mx-10 text-lg">
                <p className="text-xl font-bold">1 GEGENSTAND</p><br/>
                <strong>1.1</strong> Die vorliegende Nutzungsvereinbarung findet Anwendung, wenn Sie (nachfolgend in der
                jeweiligen Rolle auch „Kunde“, „Partner“ oder „Nutzer“ genannt) die in dieser Vereinbarung genannte
                Plattform der Firma Infinity Moments, Schnitterweg 31 in 65375 Oestrich Winkel, Deutschland (nachfolgend
                kurz: „Infinity Moments“), vertreten durch den Inhaber Florian Bauböck (siehe Impressum
                https://infinity-moments.com/policies/legal-notice . nutzen, soweit nicht durch schriftliche
                Vereinbarungen zwischen Ihnen und uns ausdrücklich etwas anderes vereinbart wurde. Abweichende oder
                entgegenstehende Bedingungen des Kunden bzw. Nutzer werden von uns nicht anerkannt, sofern wir diesen
                nicht ausdrücklich in Schriftform zugestimmt haben. Diese Vereinbarung tritt in Kraft, sobald Sie (Kunde
                und/oder Nutzer) sich auf der Plattform https://infinity.moments.name registrieren, spätestens jedoch,
                wenn Sie auf der vorgenannten Plattform unsere Leistungen in Anspruch nehmen.<br/><br/>
                <strong>1.2</strong> Die Nutzungsvereinbarung hat eine unbefristete Laufzeit und kann von jeder Partei
                nach Maßgabe
                Bestimmung über die Kündigung dieser Vereinbarung gekündigt werden, ohne dass der jeweilige Vertrag
                zwischen Parteien über den Erwerb der für die Nutzung der Plattform erforderlichen Armbänder von der
                Kündigung berührt wird.<br/><br/>
                <strong>1.3</strong> Die zu erbringende Leistung ergibt sich aus dieser Vereinbarung und insbesondere
                aus der
                Leistungsbeschreibung der Anlage 2, soweit angegeben. Die Vereinbarung wird nicht an den Kunden oder
                Nutzer versandt, sie kann jedoch jederzeit auf https://infinity.moments.name heruntergeladen
                werden.<br/><br/>
                <strong>1.4</strong> Folgende Dokumente sind integraler Teil der Vereinbarung, wobei im Falle von
                Widersprüchen zwischen
                den einzelnen Dokumenten folgende absteigende Vorrangregelung gilt:<br/>
                • Die vorliegende Nutzungsvereinbarung<br/>
                • Anlage 1 - Verantwortung der Plattform<br/>
                • Anlage 2 - Leistungsbeschreibung<br/><br/>
                <p className="text-xl font-bold">2 KOMMUNIKATION</p><br/>
                <strong>2.1</strong> Soweit nicht abweichend vereinbart, sind sämtliche Erklärungen des Kunden und
                Nutzer unter dieser
                Vereinbarung nur wirksam, wenn sie gegenüber uns entweder (i) über die Plattform, sofern auf der
                Plattform eine entsprechende Mitteilungsmöglichkeit bereitgestellt wird; oder (ii) mindestens in
                Textform an die nachfolgend genannte E-Mail-Adresse von Infinity Moments erfolgt:<br/>
                E-Mail-Adresse von Infinity Moments: kundenservice@infinity-moments.de<br/>
                <strong>2.2</strong> Mitteilungen und Erklärungen von Infinity Moments unter diese Vereinbarung an den
                Kunden/Nutzer sind
                wirksam, wenn sie an die vom Kunden/Nutzer bei der Registrierung hinterlegte E-Mail-Adresse erfolgen.
                Etwaige Änderungen der hinterlegten E-Mail-Adresse teilt der Kunde/Nutzer unaufgefordert und
                unverzüglich Infinity Moments mit.<br/><br/>
                <p className="text-xl font-bold">3 DEFINITIONEN</p><br/>
                Aus Gründen der besseren Lesbarkeit wird in dieser Vereinbarung der generische Maskulin verwendet. Wir
                meinen mit dem generischem Maskulin jedoch alle Menschen, unabhängig von ihrem Geschlecht.<br/>
                <strong>3.1</strong> „Homepage“ ist die Webseite www.infinity.moments.name, auf der sich Nutzer auf der
                Plattform
                anmelden können. Der Zugang zur Homepage ist ohne Passwort möglich.<br/>
                <strong>3.2</strong> „Kunde“ meint den Vertragspartner von uns, der auf www.infinity-moments.com das für
                die Nutzung der
                Plattform erforderliche Armband erworben hat.<br/>
                <strong>3.3</strong> „Nutzer“ meint sowohl den Kunden der Plattform als auch den Partner des Kunden, den
                der Kunde zur
                Nutzung der Plattform auf die Plattform im Innenverhältnis zur Nutzung der Plattform animiert hat. Die
                Nutzer sind entweder der Kunde selbst oder, wenn vom Kunden als weitere Person (Partner) angelegt, im
                Auftrag des Kunden tätig. Nutzer dürfen nur natürliche Personen sein, die volljährig und unbeschränkt
                geschäftsfähig sind.<br/>
                <strong>3.4</strong> „Partner“ meint den vom Kunden zur Nutzung der Plattform aufgeforderte oder
                eingeladene natürliche
                Person, welche die Plattform nach Maßgabe dieser Nutzungsvereinbarung nutzen darf, wenn der Partner ein
                eigenes Armband von Infinity Moments hat, das zur Anmeldung an der Plattform erforderlich ist. Der Kunde
                verpflichtet sich, den Partner über diese Nutzungsvereinbarung zu unterrichten und den Partner zur
                Einhaltung dieser Nutzungsvereinbarung zu verpflichten. Für Handlungen und Unterlassungen des Partners
                haftet der Kunde, wie für ein eigenes Handeln und Unterlassen. Bei der initialen Anmeldung des Partners
                auf der Plattform, hat der Partner auch diese Nutzungsvereinbarung zur Kenntnis zu nehmen und zu
                akzeptieren.<br/>
                <strong>3.5</strong> „Plattform“ bezeichnet die Plattform von Infinity Moments auf
                https://infinity.moments.name sowie
                alle gemäß Leistungsbeschreibung beschrieben Zugänge sowie die nach dem Login über die Homepage
                erreichbaren Webseiten, die für den Kunden/Nutzer individuell, d. h. getrennt von anderen
                Kunden/Nutzern, eingerichtet sind.<br/>
                <strong>3.6</strong> „Plattform-IP“ sind alle bei Abschluss dieser Vereinbarung bestehenden oder während
                der Laufzeit
                dieser Vereinbarung von Infinity Moments oder von durch Infinity Moments einbezogene Dritte
                geschaffenen, entwickelten oder erworbenen Materialien, Unterlagen, Ergebnisse, Software (in allen
                Ausdrucksformen, insbesondere Objektcode und Quellcode), Gegenstände, Dokumente, Skizzen, Zeichnungen,
                Entwürfe, Konzepte, Informationen, Daten etc. einschließlich ihrer Bearbeitungen.<br/>
                <strong>3.7</strong> „Services“ sind die Leistungen, die Infinity Moments an den Kunde/Nutzer unter
                dieser Vereinbarung
                erbringt.<br/>
                <strong>3.8</strong> „Schutzrechte“ sind Urheberrechte sowie alle sonstigen Rechte, die unter deutschem
                oder
                ausländischem Recht einen Schutz von geistigem Eigentum genießen, sowie vergleichbare Rechte.<br/>
                <strong>3.9</strong> „Kunden/Nutzer-Content“ sind alle Inhalte, Daten oder andere Informati¬onen, die
                vom Kunden/Nutzer
                oder von für ihn tätigen Dritten im Zusammenhang mit der Nutzung der Services an Infinity Moments
                übermittelt werden.<br/>
                <strong>3.10</strong> „Kunden/Nutzer-Feedback“ sind sämtliche Vorschläge, Berichte,
                Verbesserungswünsche, Empfehlungen
                oder sonstige Rückmeldungen des Kunden/Nutzers hinsichtlich der Plattform und der Services.<br/>
                <strong>3.11</strong> „Vertrauliche Informationen“ sind alle Informationen und Unterlagen der jeweils
                anderen Partei, die
                als vertraulich gekennzeichnet oder aus den Umständen heraus als vertraulich anzusehen sind,
                insbesondere die Plattform-IP, Informationen über betriebliche Abläufe, Geschäftsbeziehungen und
                Know-how; dies umfasst auch diese Vereinbarung samt aller Anlagen. Keine Vertraulichen Informationen –
                weder für Infinity Moments noch für den Kunde/Nutzer – sind solche Informationen, welche (i) der
                Kunde/Nutzer für Infinity Moments zur Darstellung auf der Plattform für Dritte bereitgestellt hat, (ii)
                dem Empfänger bei Abschluss dieser Vereinbarung nachweislich bereits bekannt waren oder danach von
                dritter Seite bekannt werden, ohne dass dadurch eine Vertraulichkeitsvereinbarung, gesetzliche
                Vorschriften oder behördliche Anordnungen verletzt werden, oder (iii) die bei Abschluss dieser
                Vereinbarung öffentlich bekannt sind oder danach öffentlich bekannt gemacht werden, soweit dies nicht
                auf einer Verletzung dieser Vereinbarung beruht.<br/><br/>
                <p className="text-xl font-bold">4 SERVICES</p><br/>
                <strong>4.1</strong> Infinity Moments stellt dem Kunden/Nutzer für die Laufzeit, d.h. bis zur Kündigung
                dieser
                Vereinbarung einen Zugriff auf die Services über die Plattform zur Verfügung, wenn der Kunde/Nutzer
                zuvor das für die Nutzung der Plattform erforderliche Armband von Infinity Moments entgeltlich erworben
                hat. Die Services sowie die diesbezüglichen Verantwortlichkeiten von Infinity Moments sind in dieser
                Vereinbarung abschließend festgelegt; Einzelheiten ergeben sich auch aus den Anlagen 1 und 2.<br/>
                <strong>4.2</strong> Der Kunde/Nutzer erhält weder Hardware noch physische oder digitale Kopien der
                Services oder des
                Quellcodes hierfür. Insbesondere hat der Kunde/Nutzer keinen Anspruch auf Erstellung oder Herausgabe von
                körperlichen Dokumenten (z. B. physischen Ausdrucken).<br/>
                <strong>4.3</strong> Zur Erbringung der Leistungen ist Infinity Moments berechtigt, Subunternehmer
                einzusetzen.<br/><br/>
                <p className="text-xl font-bold">5 ZUGANGSDATEN UND REGISTRIERUNG</p><br/>
                <strong>5.1</strong> Der Kunde/Nutzer verpflichtet sich, die Zugangsdaten (insb. Benutzerkennungen und
                Passworte) vor dem
                Zugriff und Missbrauch durch unbefugte Dritte technisch und organisatorisch zu sichern. Der Kunde/Nutzer
                ist in vollem Umfang für die Wahrung der Vertraulichkeit und Sicherheit seiner Zugangsdaten
                verantwortlich und trägt die alleinige Verantwortung für alle Aktivitäten, die unter seinen Konten
                stattfinden, soweit diese Aktivitäten vom Kunden/Nutzer autorisiert wurden oder vom Kunden/Nutzer nicht
                autorisiert wurden, aber von ihm bei Anwendung der gebotenen Sorgfalt hätten verhindert werden
                können.<br/>
                <strong>5.2</strong> Wird dem Kunde/Nutzer bekannt oder hat er den Verdacht, dass (i) ein Dritter das
                Passwort zum Login
                auf der Plattform erlangt hat oder (ii) von ihm nicht autorisierte Tätigkeiten auf der Plattform im
                Namen des Kunde/Nutzers vorgenommen werden, so ist er verpflichtet, unverzüglich das Passwort zu ändern
                oder den Benutzerzugang zu sperren und Infinity Moments unverzüglich darüber zu informieren.<br/><br/>
                <p className="text-xl font-bold">6 NUTZUNGSRECHTE UND GEISTIGES EIGENTUM</p><br/>
                <strong>6.1</strong> Infinity Moments gewährt dem Kunden/Nutzer für die Dauer dieser Vereinbarung, also
                bis zur Kündigung
                dieser Vereinbarung, ein nicht exklusives, nicht übertragbares, nicht unterlizenzierbares Recht, auf die
                Services und die Plattform-IP in Übereinstimmung mit dieser Vereinbarung zuzugreifen, solange und soweit
                dies für die Nutzung der Services gemäß dieser Vereinbarung erforderlich ist. Die Nutzung der Plattform
                ist nur angemeldeten Nutzern gestattet. Die Anmeldung eines jeweiligen Nutzers und mithin die Gewährung
                der Rechte nach Maßgabe dieser Ziffer kann nur erfolgen, wenn zuvor für jeden einzelnen Nutzer jeweils
                ein eigenes Armband entgeltlich von Infinity Moments erworben wurde.<br/>
                <strong>6.2</strong> Nutzungsrechte können durch Infinity Moments widerrufen werden, insbesondere wenn
                (i) eine
                missbräuchliche Nutzung, auch durch den Kunden/Nutzer oder Dritte, vorliegt, und/oder (ii) der
                Kunde/Nutzer gegen diese Vereinbarung oder die Sorgfaltspflicht im Umgang mit der Zugangskennung
                verstoßen hat.<br/>
                <strong>6.3</strong> Der Kunde/Nutzer erkennt an, dass die über die Services bereitgestellten Inhalte
                (z. B. Texte,
                Bilder, Videos, Animationen, Tabellen, Logos etc.) und die Plattform-IP dem jeweils geltenden
                gesetzlichen Schutz unterliegen, insbesondere dem des Marken-, Urheber-, Leistungsschutz- und
                Wettbewerbsrechts.<br/>
                <strong>6.4</strong> Soweit dies notwendig ist, um die Services gegenüber dem Kunde/Nutzer zu erbringen,
                gewährt der
                Kunde/Nutzer Infinity Moments ein einfaches, weltweites und zeitlich auf die Laufzeit dieser
                Vereinbarung beschränktes Recht, Kunde/Nutzer-Content zu nutzen und/oder durch Subunternehmer nutzen zu
                lassen, insbesondere zu kopieren, zu bearbeiten und dem Nutzer zugänglich zu machen. Der Kunde/Nutzer
                räumt Infinity Moments darüber hinaus ein unwiderrufliches, exklusives, inhaltlich, zeitlich und örtlich
                unbefristetes, unterlizenzierbares Recht zur kostenfreien Nutzung, Anonymisierung, Analyse und
                Weiterentwicklung von Kunde/Nutzer-Feedback ein.<br/>
                <strong>6.5</strong> Infinity Moments steht es frei, die Nutzung der Services durch den Kunde/Nutzer
                völlig anonym, also
                ohne Rückführbarkeit auf den jeweiligen Nutzer, zu analysieren, soweit kartell- und datenschutzrechtlich
                zulässig. Die Analyse dient insbesondere dem Ziel, die Services zu verbessern und entsprechend dem
                Marktbedarf anzupassen (beispielsweise durch aggregierte statistische Auswertung), die vereinbarte
                Verfügbarkeit zu gewährleisten sowie die Systemsicherheit der Plattform zu verbessern.<br/>
                <strong>6.6</strong> Der Kunde/Nutzer ist nicht berechtigt, die Services in weiterem Umfang zu nutzen
                als explizit im
                Rahmen dieser Vereinbarung gestattet. Insbesondere ist der Kunde/Nutzer nicht berechtigt, (i)
                Bearbeitungen an den Services vorzunehmen, (ii) unbefugten Dritten den Zugriff auf die Services zu
                gestatten, (iii) abgeleitete Werke auf der Grundlage der Services zu erstellen, oder (iv) auf die
                Services zuzugreifen, um (a) ein Konkurrenzprodukt zu erstellen oder (b) Features, Funktionen oder
                Grafiken der Services zu kopieren.<br/>
                <strong>6.7</strong> Der Kunde/Nutzer (i) wird die Services nur in Übereinstimmung mit dieser
                Vereinbarung und mit den
                geltenden Gesetzen nutzen, (ii) wird wirtschaftlich angemessene Anstrengungen unternehmen, um unbefugten
                Zugriff auf die Services und Kunde/Nutzer-Content oder dessen unbefugte Nutzung zu verhindern, (iii)
                wird die Integrität und Verfügbarkeit der Services, der zugehörigen Systeme und Infrastruktur von
                Infinity Moments sowie die darin enthaltenen Daten Dritter nicht beeinträchtigen oder stören und (iv)
                wird nicht versuchen, unbefugten Zugriff auf die Services oder die zugehörigen Systeme oder Netzwerke zu
                erlangen.<br/>
                <strong>6.8</strong> Der Kunde/Nutzer wird Infinity Moments unverzüglich über jeden Verstoß gegen diese
                Ziffer 6 sowie
                jede dem Kunde/Nutzer bekannt gewordene unbefugte Nutzung der Services mit einer detaillierten
                Beschreibung informieren.<br/><br/>
                <p className="text-xl font-bold">7 KUNDE/NUTZER-CONTENT</p><br/>
                <strong>7.1</strong> Für den Kunde/Nutzer-Content übernimmt Infinity Moments keine inhaltliche
                Verantwortung. Der
                Kunde/Nutzer ist allein verantwortlich für die Genauigkeit, Qualität, Integrität und Rechtmäßigkeit des
                Kunde/Nutzer-Content sowie die Mittel, mit denen er den Kunde/Nutzer-Content erworben hat.<br/>
                <strong>7.2</strong> Dem Kunde/Nutzer ist untersagt, Kunde/Nutzer-Content hochzuladen oder an Infinity
                Moments in
                sonstiger Weise zur Verfügung zu stellen, der (i) Rechte Dritter verletzt, (ii) gegen anwendbares Recht
                verstößt, (iii) zu einem Rechtsverstoß von Infinity Moments gegen geltendes Recht führt oder führen
                kann, (iv) die Sicherheit der Services beeinträchtigt oder wahrscheinlich beeinträchtigen kann oder (v)
                die Leistungsfähigkeit der Services mehr als nur unerheblich beeinträchtigt.<br/>
                <strong>7.3</strong> Im Falle eines Verstoßes gegen Ziffer 7.2 ist der Kunde/Nutzer verpflichtet, (i)
                den Verstoß
                Infinity Moments unverzüglich anzuzeigen, (ii) den jeweils betroffenen Kunde/Nutzer-Content auf der
                Plattform unverzüglich zu löschen bzw. löschen zu lassen (je nachdem, was durch den Kunde/Nutzer
                vorgenommen werden kann) und (iii) Infinity Moments von sämtlichen deswegen gegen Infinity Moments
                geltend gemachten Ansprüchen freizustellen und die daraus resultierenden Kosten (inkl. angemessener
                Kosten für die Rechtsverteidigung) zu tragen, es sei denn, den Kunde/Nutzer trifft hieran kein
                Verschulden.<br/><br/>
                <p className="text-xl font-bold">8 MITWIRKUNG(SPFLICHTEN) DES KUNDE/NUTZERS</p><br/>
                <strong>8.1</strong> Die einzelnen Mitwirkungspflichten des Kunde/Nutzers ergeben sich aus der gesamten
                Vereinbarung. Der
                Kunde/Nutzer wird zudem alle Mitwirkungen erbringen, die für eine ordnungsgemäße Leistungserbringung
                durch Infinity Moments erforderlich sind. Die Erbringung der Services gemäß dieser Vereinbarung hängt
                wesentlich von der Erbringung dieser Mitwirkungen des Kunde/Nutzers ab. Infinity Moments darf sich bei
                der Erbringung der Services auf Mitteilungen, Anweisungen, Erklärungen des Kunde/Nutzers verlassen.<br/>
                <strong>8.2</strong> Soweit für die jeweiligen Service anwendbar, erbringt der Kunde/Nutzer insbesondere
                folgende
                Mitwirkungen:<br/>
                • Der Kunde/Nutzer stellt Infinity Moments rechtzeitig im vereinbarten, sonst in angemessenem Format,
                alle Daten und Informationen zur Verfügung, die für die Erbringung der Services erforderlich sind.
                Soweit erforderlich, aktualisiert der Kunde/Nutzer diese Daten und Informationen. Der Kunde/Nutzer ist
                für die Vollständigkeit und Richtigkeit dieser Daten und Informationen verantwortlich; Infinity Moments
                ist nicht zu einer Überprüfung verpflichtet.<br/>
                • Der Kunde/Nutzer hat die Obliegenheit, regelmäßig eine Sicherungskopie von seinen Inhalten, die auf
                der Plattform gespeichert sind, zu erstellen und bei sich zu speichern.<br/>
                <strong>8.3</strong> Infinity Moments ist nicht für Konsequenzen verantwortlich, die aus
                Pflichtverletzung des
                Kunde/Nutzers oder einer nicht ordnungsgemäßen und/oder verspätet erbrachten Mitwirkung des
                Kunde/Nutzers resultieren.<br/><br/>
                <p className="text-xl font-bold">9 GEWÄHRLEISTUNG</p><br/>
                <strong>9.1</strong> Infinity Moments gewährleistet, dass die Services beim Einsatz gemäß dieser
                Vereinbarung im
                Wesentlichen den Angaben in dieser Vereinbarung entsprechen. Um die Leistungsqualität der Services –
                zugunsten aller Kunde/Nutzer – aufrechterhalten zu können, ist Infinity Moments auf Anregungen und
                Rückmeldungen der Kunde/Nutzer angewiesen. Deshalb hat der Kunde/Nutzer etwaige Fehler und Mängel der
                Services (gemeint sind also insb. fehlerhafte Funktionalitäten der Plattform als solchen) unverzüglich
                Infinity Moments anzuzeigen und die Fehlersymptome detailliert zu beschreiben.<br/>
                <strong>9.2</strong> Infinity Moments gewährleistet ferner, dass die Services frei von Schutzrechten
                Dritter sind, welche
                die Nutzung der Services gemäß dieser Vereinbarung beeinträchtigen. Machen Dritte gegen den Kunde/Nutzer
                Ansprüche wegen einer Verletzung von Schutzrechten geltend, für die Infinity Moments verantwortlich ist,
                kann Infinity Moments diesen Mangel nach seiner Wahl dadurch beseitigen, dass es (i) für den
                Kunde/Nutzer die erforderlichen Rechte erwirbt, sodass die Services keine Rechte Dritter mehr verletzen,
                oder (ii) die Services so verändern, dass bei vergleichbarem Nutzen für den Kunde/Nutzer keine
                Schutzrechte Dritter verletzt werden.<br/>
                <strong>9.3</strong> Infinity Moments wird den Kunde/Nutzer nach Maßgabe der Bestimmungen dieser
                Vereinbarung von während
                der Gewährleistungsfrist geltend gemachten, gerichtlich festgestellten Ansprüchen Dritter im Sinne der
                Ziffer 9.2 freistellen unter der Voraussetzung, dass der Kunde/Nutzer (i) Infinity Moments unverzüglich
                über einen solchen Anspruch informiert, (ii) Infinity Moments sämtliche zumutbare Unterstützung zukommen
                lässt, die Infinity Moments anfordert, und (iii) Infinity Moments im Innenverhältnis die alleinige
                Kontrolle und Entscheidungshoheit über die Abwehr und Beilegung eines solchen Anspruchs auf Kosten von
                Infinity Moments überlässt. Die Freistellungspflicht gilt nicht, wenn Infinity Moments die
                Schutzrechtsverletzung nicht zu vertreten hat.<br/>
                <strong>9.4</strong> Ziffer 9 beschreibt abschließend den Umfang, für den eine Gewährleistungspflicht
                von Infinity
                Moments besteht. Gewährleistungsrechte bestehen nicht, soweit ein Mangel auf einer Nutzung der Services
                durch den Kunde/Nutzer beruht, die über die nach dieser Vereinbarung explizit gestattete Nutzung
                hinausgeht. Es besteht keine verschuldensunabhängige Haftung von Infinity Moments wegen Mängeln, die
                bereits zum Zeitpunkt des Vertragsschlusses vorhanden sind, § 536a Abs. 1,1. Alt. BGB (Bestimmung zur
                verschuldensunabhängigen Haftung wegen Mängel, die bereits zum Zeitpunkt des Vertragsschlusses über die
                Nutzung von Wohnraum) findet keine Anwendung.<br/><br/>
                <p className="text-xl font-bold">10 HAFTUNG</p><br/>
                <strong>10.1</strong> Ansprüche des Kunden/Nutzers auf Schadensersatz sind ausgeschlossen. Hiervon
                ausgenommen sind
                Schadensersatzansprüche des Kunden/Nutzers aus der Verletzung des Lebens, des Körpers, der Gesundheit
                oder aus der Verletzung wesentlicher Vertragspflichten (Kardinalpflichten) sowie die Haftung für
                sonstige Schäden, die auf einer vorsätzlichen oder grob fahrlässigen Pflichtverletzung von Infinity
                Moments, seiner gesetzlichen Vertreter oder Erfüllungsgehilfen beruhen. Wesentliche Vertragspflichten
                sind solche, deren Erfüllung zur Erreichung des Ziels des Vertrags notwendig ist.<br/>
                <strong>10.2</strong> Bei der Verletzung wesentlicher Vertragspflichten haftet Infinity Moments nur auf
                den
                vertragstypischen, vorhersehbaren Schaden, wenn dieser einfach fahrlässig verursacht wurde, es sei denn,
                es handelt sich um Schadensersatzansprüche des Kunden/Nutzers aus einer Verletzung des Lebens, des
                Körpers oder der Gesundheit.<br/>
                <strong>10.3</strong> Die Einschränkungen der Ziffern 10.1 und 10.2 gelten auch zugunsten der
                gesetzlichen Vertreter und
                Erfüllungsgehilfen des Anbieters, wenn Ansprüche direkt gegen diese geltend gemacht werden.<br/>
                <strong>10.4</strong> (4) Die sich aus Ziffern 10.1 und 10.2 ergebenden Haftungsbeschränkungen gelten
                nicht, soweit der
                Infinity Moments den Mangel arglistig verschwiegen oder eine Garantie für die Beschaffenheit der Sache
                übernommen hat. Das gleiche gilt, soweit wir und der Kunde/Nutzer eine Vereinbarung über die
                Beschaffenheit der Sache getroffen haben. Die Vorschriften des Produkthaftungsgesetzes bleiben
                unberührt.<br/><br/>
                <p className="text-xl font-bold">11 VERTRAULICHKEIT</p><br/>
                <strong>11.1</strong> Die Parteien vereinbaren, über Vertrauliche Informationen Stillschweigen zu wahren
                und diese nicht
                Dritten gegenüber mitzuteilen oder zugänglich zu machen. Diese Verpflichtung besteht für einen Zeitraum
                von fünf (5) Jahren nach Beendigung dieser Vereinbarung fort. Mit Infinity Moments verbundene
                Unternehmen im Sinne der §§ 15 ff. AktG sowie die zur Erfüllung der Pflichten aus dieser Vereinbarung
                von Infinity Moments eingesetzten Subunternehmer gelten nicht als Dritte im Sinne dieser
                Vorschrift.<br/>
                <strong>11.2</strong> Die Weitergabe von Vertraulichen Informationen durch die Parteien an Dritte ist in
                jedem Fall
                gestattet, soweit dies zur Erfüllung dieses Vertrags oder für den Betrieb der Plattform erforderlich
                ist.<br/>
                <strong>11.3</strong> Wenn Vertrauliche Informationen aufgrund gesetzlicher Verpflichtungen oder auf
                Anordnung eines
                Gerichtes oder einer Behörde offengelegt werden müssen, wird der zur Offenlegung verpflichtete
                Empfänger, soweit zulässig und möglich, die andere Partei vorab unterrichten und ihr Gelegenheit geben,
                gegen die Offenlegung vorzugehen.<br/>
                <strong>11.4</strong> Infinity Moments ist berechtigt, Anwälten, Wirtschaftsprüfern und sonstigen
                Beratern sowie
                Erfüllungsgehilfen und/oder technischen Dienstleistern (z. B. RZ-Betreibern) Zugang zu Vertraulichen
                Informationen zu gewähren, (i) soweit dies für die Wahrnehmung berechtigter Interessen von Infinity
                Moments erforderlich ist und (ii) die Berater und Erfüllungsgehilfen entweder einem gesetzlichen
                Berufsgeheimnis unterliegen oder zuvor Geheimhaltungsverpflichtungen zugestimmt haben, die im
                Wesentlichen denen dieser Vereinbarung entsprechen.<br/><br/>
                <p className="text-xl font-bold">12 KÜNDIGUNG DER VEREINBARUNG</p><br/>
                <strong>12.1</strong> Diese Vereinbarung kann durch die Parteien dieser Vereinbarung jederzeit mit einer
                Frist von einem
                Monat zum Monatsende gekündigt werden. Das Recht jeder Partei zur Kündigung dieser Vereinbarung aus
                wichtigem Grund bleibt unberührt.<br/>
                <strong>12.2</strong> Jede Kündigung bedarf der Textform (E-Mail reicht). Der Nutzer hat die
                Möglichkeit, diesen Vertrag
                mittels eines Klicks auf eine Schaltfläche auf der Plattform zu kündigen.<br/>
                <strong>12.3</strong> Mit Beendigung dieser Vereinbarung endet das Recht zur Nutzung der Plattform und
                der Zugang des
                Kunde/Nutzers zur Plattform wird deaktiviert, sodass der Kunde/Nutzer keine Services mehr nutzen
                kann.<br/>
                <strong>12.4</strong> Bis zum Ablauf von drei Monaten nach Beendigung dieser Vereinbarung wird Infinity
                Moments dem
                Kunde/Nutzer auf dessen Verlangen die bestehenden Inhalte zum Download anbieten. Darüber hinaus bestehen
                keine Aufbewahrungspflichten von Infinity Moments gegenüber dem Kunde/Nutzer nach Beendigung dieser
                Vereinbarung. Datenschutzrechtliche Auskunftsrechte (z. B. nach Art. 15 DS-GVO) bleiben unberührt.<br/>
                <strong>12.5</strong> Von jedweder Kündigung bleibt der Vertrag über den entgeltlichen Erwerb der
                Armbänder unberührt und
                mithin weiterhin in Kraft. Die Armbänder können im Falle einer Kündigung weiterhin als Schmuck getragen
                werden.<br/><br/>
                <p className="text-xl font-bold">13 ÄNDERUNGSVORBEHALT</p><br/>
                <strong>13.1</strong> Diese Vereinbarung sowie seine Anlagen können durch Infinity Moments nachträglich
                geändert werden,
                sofern nicht für das Äquivalenzverhältnis wesentliche Inhalte dieser Vereinbarung geändert werden und
                die Änderung für den Kunde/Nutzer zumutbar ist.<br/>
                <strong>13.2</strong> Die Services unterliegen einer stetigen Weiterentwicklung, um dem technischen
                Fortschritt Rechnung
                zu tragen und Verbesserungen über die Beseitigung etwaiger Störungen hinaus vorzunehmen. Infinity
                Moments darf daher die Services stetig weiterentwickeln und die Vereinbarung sowie die Anlagen 1 und 2
                entsprechend anpassen, sofern damit in der Gesamtschau keine Verschlechterung im Vergleich zum
                bisherigen Funktionsumfang verbunden ist.<br/>
                <strong>13.3</strong> In den Fällen der Ziffer 13.1 und der Ziffer 13.2 wird Infinity Moments den
                Kunde/Nutzer mindestens
                acht (8) Wochen vor Inkrafttreten der Änderungen davon in geeigneter Weise in Kenntnis setzen. Ist der
                Kunde/Nutzer mit der Änderung nicht einverstanden, ist er berechtigt, innerhalb von acht (8) Wochen nach
                Zugang der Änderungen dieser Vereinbarung zum Wirksamwerden der Änderungen nach Maßgabe der Ziffer 12 zu
                kündigen. Setzt der Kunde/Nutzer die Inanspruchnahme der Services fort, so gilt die Änderungen mit
                Ablauf der Kündigungsfrist als wirksam vereinbart. Auf diese Folgen wird Infinity Moments in der
                Mitteilung hinweisen.<br/>
                <strong>13.4</strong> Im Übrigen kann diese Vereinbarung von Infinity Moments angepasst werden, soweit
                dies zur
                Beseitigung von nicht unerheblichen Schwierigkeiten bei der Durchführung der Leistungen aufgrund von
                nach Abschluss dieser Vereinbarung entstandenen Regelungslücken oder anderen rechtlichen Notwendigkeiten
                erforderlich ist. Dies kann insbesondere der Fall sein, wenn sich Gesetze oder die Rechtsprechung zur
                Wirksamkeit von Bestimmungen dieser Vereinbarung ändert.<br/><br/>
                <p className="text-xl font-bold">14 HÖHERE GEWALT</p><br/>
                Infinity Moments haftet nicht für Unmöglichkeit, Verzögerungen oder Leistungsmängel, soweit diese durch
                höhere Gewalt, Naturkatastrophen oder sonstige, zum Zeitpunkt des Abschlusses dieser Vereinbarung nicht
                vorhersehbare Ereignisse (z. B. Pandemien, Epidemien, Betriebsstörungen aller Art, Schwierigkeiten in
                der Material- oder Energiebeschaffung, Transportverzögerungen, Streiks, rechtmäßige Aussperrungen,
                Energie oder Rohstoffen, Schwierigkeiten bei der Beschaffung von notwendigen behördlichen Genehmigungen,
                behördliche Maßnahmen, Terrorismus, Naturereignisse, Aufstand, Revolution, Krieg etc.) verursacht worden
                sind, die Infinity Moments nicht zu vertreten hat.<br/><br/>
                <p className="text-xl font-bold">15 SCHLUSSBESTIMMUNGEN</p><br/>
                <strong>15.1</strong> Infinity Moments darf seine Rechte und Pflichten aus oder im Zusammenhang mit
                dieser Vereinbarung
                auf verbundene Unternehmen im Sinne der §§ 15 ff. AktG übertragen.<br/>
                <strong>15.2</strong> Auf diese Vereinbarung ist ausschließlich das deutsche Recht unter Ausschluss des
                Übereinkommens
                der Vereinten Nationen über Verträge über den internationalen Warenverkauf vom 11.4.1980 (UN-Kaufrecht)
                anzuwenden. Die gesetzlichen Vorschriften zur Beschränkung der Rechtswahl und zur Anwendbarkeit
                zwingender Vorschriften insbes. des Staates, in dem der Kunde/Nutzer als Verbraucher seinen gewöhnlichen
                Aufenthalt hat, bleiben unberührt.<br/>
                <strong>15.3</strong> Sofern es sich beim Kunden/Nutzer um einen Kaufmann, eine juristische Person des
                öffentlichen
                Rechts oder um ein öffentlich-rechtliches Sondervermögen handelt, ist Gerichtsstand für alle
                Streitigkeiten aus oder wegen dieses Vertragsverhältnissen zwischen dem Kunden und uns der Sitz von
                Infinity Moments.<br/>
                <strong>15.4</strong> Die Vereinbarung bleibt auch bei rechtlicher Unwirksamkeit einzelner Punkte in
                ihren übrigen Teilen
                verbindlich. Anstelle der unwirksamen Punkte treten, soweit vorhanden, die gesetzlichen Vorschriften.
                Soweit dies für eine Vertragspartei eine unzumutbare Härte darstellen würde, wird der Vertrag jedoch im
                Ganzen unwirksam.<br/>
                <br/>
                <p className="text-xl font-bold">Anlage 1 – Verantwortung der Plattform</p><br/>
                <p className="text-xl font-bold">1 DEFINITIONEN</p><br/>
                Die Definitionen aus der Nutzungsvereinbarung oben gelten auch für diese Anlage 1, soweit in dieser
                Anlage 1 nicht explizit davon abgewichen wird.<br/><br/>
                <p className="text-xl font-bold">2 GRUNDLAGEN DER PLATTFORM</p><br/>
                <strong>2.1</strong> Darstellung von Content<br/>
                Die Darstellungen auf der Plattform geben die Ansicht der hochgeladenen Inhalte des Kunde/Nutzers wieder
                und basieren auf jenen Informationen, die in der Plattform im Einklang mit den zur Verfügung stehenden
                Funktionalitäten über die Homepage oder über etwaige weitere Zugänge vom Kunden/Nutzer eingegeben
                wurden.<br/>
                <strong>2.2</strong> Rechtliche Rolle von Infinity Moments<br/>
                Infinity Moments stellt die technische Plattform bereit, auf welcher der Kunde/Nutzer sich austauschen
                und Kunden/Nutzer-Content hochladen können. Infinity Moments hat keinen Einfluss auf Inhalte, die von
                den Kunden/Nutzern auf der Plattform hochgeladen werden.<br/><br/>
                <p className="text-xl font-bold">3 GRENZEN DER SERVICES</p><br/>
                <strong>3.1</strong> Nutzung ausschließlich über die Plattform<br/>
                Funktionalitäten der Plattform (z. B. Hochladen von Bildern) können vom Kunden/Nutzer nur über die
                Plattform durchgeführt werden. Insbesondere ist es nicht möglich, Infinity Moments etwa per E-Mail,
                Telefon oder Brief etc. anzuweisen, Funktionalitäten der Plattform auszuführen.<br/>
                <strong>3.2</strong> Keine Verantwortlichkeit von Infinity Moments<br/>
                Infinity Moments prüft insbesondere nicht und die Plattform kann aus technischen Gründen keine Aussage
                dazu treffen,<br/>
                • ob die von den Kunden/Nutzer auf die Plattform hochgeladener Kunden/Nutzer-Content gegen kein Gesetz
                oder Rechte Dritter verstoßen<br/><br/>
                <p className="text-xl font-bold">Anlage 2 – Leistungsbeschreibung</p><br/>
                <p className="text-xl font-bold">TEIL 1: ALLGEMEINE REGELUNGEN</p><br/>
                <p className="text-xl font-bold">1 GEGENSTAND</p><br/>
                Diese Anlage 2 definiert die Services für den Kunden/Nutzer sowie die notwendigen Voraussetzungen für
                die Nutzung unserer Services.
                Infinity Moments trägt keine Verantwortung dafür, dass seine Services den Erwartungen des Kunden/Nutzers
                entsprechen.<br/><br/>
                <p className="text-xl font-bold">2 DEFINITIONEN</p><br/>
                Ergänzend zu den Definitionen in der Nutzungsvereinbarung gelten für diese Anlage 2
                (Leistungsbeschreibung) folgende Definitionen:<br/>
                <strong>2.1</strong> „NFC“ meint die sogenannte “Near Field Communication” (übersetzt:
                Nahfeldkommunikation), welche ein
                internationaler Übertragungsstandard zum kontaktlosen Austausch von Daten per elektromagnetischer
                Induktion mittels loser gekoppelter Spulen über kurze Strecken von wenigen Zentimetern ist. Zum Ablesen
                der für die Nutzung der Plattform erforderlichen Armbänder von Infinity Moments ist ein Handy
                erforderlich, das die NFC-Funktion zum Ablesen der Armbänder von Infinity Moments besitzt.<br/><br/>
                <p className="text-xl font-bold">3 VERFÜGBARKEIT DER PLATTFORM</p><br/>
                <strong>3.1</strong> Infinity Moments gewährleistet eine Verfügbarkeit der Services von Montag bis
                Sonntag (jeweils von
                06:00 bis 00:00 Uhr) von 98 % im Jahresmittel (berechnet in Minutentaktung). Demarkationspunkt, an dem
                die Verfügbarkeit gemessen wird, ist die WAN-gerichtete Router-Ausgabe des zum Betrieb der Plattform
                genutzten Servers. Sämtliche in Ziffer 3.2 und 3.3 aufgeführten Unterbrechungszeiten sind bei der
                Berechnung der Verfügbarkeit von der Soll-Verfügbarkeit in Abzug zu bringen.<br/>
                <strong>3.2</strong> Die Services werden nicht ohne Unterbrechung bereitgestellt. Infinity Moments kann
                während der
                folgenden Wartungszeiten Wartungen durchführen:<br/>
                · Planmäßige Wartungen: im Jahresdurchschnitt nicht mehr als zwölf (12) Stunden pro Monat;<br/>
                · Ungeplante Wartungsarbeiten: im Jahresdurchschnitt bis zu eine (1) Stunde pro Monat.<br/>
                Über geplante Wartungsarbeiten informiert Infinity Moments den Kunde/Nutzer – soweit möglich – mit
                angemessenem Vorlauf auf der Plattform oder per E-Mail. Während planmäßigen oder ungeplanten
                Wartungsarbeiten stehen die Services nicht zur Verfügung. Zudem können Sicherheits- und
                Kapazitätsprobleme sowie Vorkommnisse, die außerhalb der Kontrolle von Infinity Moments liegen (z. B.
                Fälle der höheren Gewalt in Ziffer 14 der Anlage 1), zu kurzzeitigen Störungen oder zur vorübergehenden
                Aussetzung von Services führen. Ebenso kann Infinity Moments nach eigenem Ermessen Services aus
                Sicherheitsgründen (z. B. wenn dies zur Behebung von Sicherheitslücken angebracht ist) oder zur
                Verhinderung von Verstößen gegen die Verpflichtungen des Kunde/Nutzers aus dieser Vereinbarung
                deaktivieren.<br/>
                <strong>3.3</strong> Infinity Moments behält sich insbesondere das Recht vor, den Kunde/Nutzer
                vorübergehend oder
                dauerhaft von dem Zugang zur Plattform zu sperren<br/>
                • bei einem Verstoß des Kunde/Nutzers gegen die Bestimmungen der Vereinbarung, insbesondere bei einem
                Verstoß des Kunde/Nutzers gegen die Nutzungsbeschränkungen der Plattform oder gegen seine
                Mitwirkungspflichten;<br/>
                • zum Schutz der Rechte und Rechtsgüter von Infinity Moments oder anderer Kunde/Nutzer<br/>
                • sofern das Passwort mehrfach falsch eingegeben wird;<br/>
                • sofern der Kunde/Nutzer von Infinity Moments eine entsprechende Sperrung verlangt;<br/>
                • um eine missbräuchliche oder unbefugte Nutzung der Plattform durch Dritte zu verhindern; und/oder<br/>
                • sofern der Verdacht besteht, dass ein Dritter das Passwort erlangt hat oder ein durch den Kunde/Nutzer
                nicht erlaubter Auftrag erteilt wurde.<br/>
                <strong>3.4</strong> Infinity Moments wird nach eigenem Ermessen die Services und die Verfügbarkeit der
                für die
                jeweiligen Services erforderlichen Server überwachen.<br/>
                <strong>3.5</strong> Infinity Moments bietet einen Support, der von den Kunde/Nutzern genutzt werden
                kann. Der
                Support<br/>
                ist zu üblichen Geschäftszeiten von Infinity Moments erreichbar. Anfragen an den Support können per
                E-Mail an kundenservice@infinity-moments.de gerichtet werden. Sollte Infinity Moments eine Hotline
                einrichten, werden die Details auf der Homepage bzw. in der Plattform bekannt gegeben.<br/><br/>
                <p className="text-xl font-bold">4 TECHNISCHE VORAUSSETZUNGEN</p><br/>
                <strong>4.1</strong> Der Zugang zur Homepage oder Plattform ist mit einer jeweils aktuellen Version der
                folgenden Browser
                möglich: Microsoft Edge®, Mozilla® Firefox®, Google™ Chrome™, Safari®.<br/>
                <strong>4.2</strong> Bei Nutzung eines mobilen Endgeräts (Tablets-PCs) sind jeweils die aktuellen
                Versionen der folgenden
                Betriebssoftware oder deren jeweilige Vorgängerversion erforderlich: Android OS® und Apple iOS®.<br/>
                <strong>4.3</strong> Der Kunde/Nutzer ist insbesondere verpflichtet, die technische Verbindung zur
                Plattform nur über die
                von Infinity Moments auf der Homepage oder gesondert zur Verfügung gestellten Zugriffsmöglichkeiten, z.
                B. Links, herzustellen. Infinity Moments ist berechtigt, bei Updates die technischen
                Systemvoraussetzungen durch Mitteilung an den Kunde/Nutzer im Rahmen der Release-Notes zur Anpassung an
                den Stand der Technik zu ändern. Dabei gewährleistet Infinity Moments, dass gängige am Markt erhältliche
                Browser unterstützt werden. Für Folgen einer etwaigen Nichterfüllung des Kunde/Nutzers der technischen
                Anforderungen trägt Infinity Moments keine Verantwortung.<br/><br/>
                <p className="text-xl font-bold">5 TECHNISCHE UND ORGANISATORISCHE VORAUSSETZUNGEN</p><br/>
                Voraussetzung für die Nutzung der Plattform ist für den jeden einzelnen Nutzer der entgeltliche Erwerb
                der für die Nutzung der Plattform erforderlichen Armbänder im Shop von Infinity Moments. Des Weiteren
                ist ein Handy erforderlich, welches die Funktion „NFC“ zum Ablesen der erforderlichen Armbänder von
                Infinity Moments besitzt. Nachdem man mit einem NFC-fähigen Handy das Armband von Infinity Moments
                abliest, gelangt man auf die Registrierungsseite der Plattform, wo man seine Login-Daten bestimmen kann
                und dieser Vereinbarung für die Nutzung der Plattform zustimmen muss. Bei Vorliegen aller zuvor
                beschriebenen Voraussetzungen ist der Login erst auf die Plattform möglich, auf welcher die Services
                unserer Plattform genutzt werden können, wenn der Kunde/Nutzer zuvor auch seine hinterlegte
                E-Mail-Adresse mittels einer Bestätigungs-Mail verifiziert.<br/><br/>
                <p className="text-xl font-bold">6 GRENZEN DER PLATTFORM</p><br/>
                <strong>6.1</strong> Die Services werden ausschließlich über die Plattform zur Verfügung gestellt und
                sind für den
                Kunde/Nutzer nur über das Internet erreichbar.<br/>
                <strong>6.2</strong> Die Services sind in dieser Anlage 2 abschließend formuliert. Zu den Services
                zählen explizit nicht
                weitere Leistungen und Services, die nicht in dieser Vereinbarung explizit genannt sind. Ferner ist es
                nicht gestattet, die Plattform für fremde Zwecke, wie bspw. das Minen von Krypto-Währungen, die Nutzung
                als E-Mail-Postfach oder als Datenspeicher, zu verwenden.<br/><br/>
                <p className="text-xl font-bold">7 EINSICHT AUF CONTENT UND INFORMATIONEN</p><br/>
                <strong>7.1</strong> Sofern relevante Ereignisse (z.B. Eingang eines neuen Kunden/Nutzer-Content) auf
                der Plattform
                eintreten, stellt Infinity Moments diese Ereignisse zur Einsicht auf der Plattform für den Kunde/Nutzer
                bereit.<br/>
                <strong>7.2</strong> Darüber hinaus wird Infinity Moments die Nutzer in angemessener Weise über wichtige
                produktbezogene
                (Weiter)Entwicklungen der Services informieren. Diese Information erfolgt insbesondere auf der
                Plattform, durch automatisierte Info-Mails oder durch andere Kanäle an die jeweiligen Nutzer; im Falle
                von automatisierten Info-Mails können die Nutzer dem Versand mittels eines Klicks auf einen Link in der
                jeweiligen E-Mail widersprechen (Opt-Out). Von dem Widerspruch bleiben System-Benachrichtigung
                unberührt.

            </div>
        </div>
    )
}

export default TermsOfServicePage;