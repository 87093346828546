import React, { useContext } from "react";
import Modal from "@components/ui/Modal";
import ImageUploader from "@components/upload/ImageUploader";
import { Context } from "../../../index";
import { useTranslation } from "react-i18next";

const UploadBannerModal = ({ show, setShow, fetch }: any) => {
  const { moment }: any = useContext(Context);

  const { t } = useTranslation<"translation", undefined>();

  const handleBannerUpload = () => {
    setShow(false);
    fetch();
  };

  return (
    <Modal title={t("banner")} show={show} setShow={setShow}>
      <p className="text-gray-400 mb-1">
        {t("images_can_be_up_to_5_MB_in_size")}
      </p>
      <ImageUploader
        id={1}
        fixedArea={16 / 9}
        onFinish={handleBannerUpload}
        endpoint={`/v1/moment/${moment.id}/images/banner`}
      />
    </Modal>
  );
};

export default UploadBannerModal;
