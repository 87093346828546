import React from "react";
import Modal from "@components/ui/Modal";
import {LeafPoll} from "react-leaf-polls";

const OldPollsModal = ({show, setShow, polls, getResData}: any) => {

    return (
        <Modal show={show} setShow={setShow}>
            {polls.map((poll: any) => {
                return (
                    <div className="mb-5" key={poll.id}>
                        <LeafPoll
                            type="multiple"
                            question={poll.title}
                            results={getResData(poll)}
                            isVoted={true}
                        />
                        <div
                            className="text-center">{new Date(polls[0].createdAt).toLocaleDateString()}</div>
                        <hr className="mt-3"/>
                    </div>
                )
            })}
        </Modal>
    )
}

export default OldPollsModal;