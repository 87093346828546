import React from "react";
import {IconArrowLeft} from "@tabler/icons-react";
import {useNavigate} from "react-router-dom";

const Title = ({title, desc, badge, back, color = "black", center}: any) => {

    const navigate = useNavigate();

    return (
        <div>
            <div
                className={`flex text-center items-center md:items-end ${center ? "justify-center" : "justify-start"}`}>
                <h1 className={`text-${color} font-fragille text-2xl md:text-4xl text-black mr-[10px]`}>
                    {back && (
                        <span className="cursor-pointer mr-2 mb-1" onClick={() => navigate(-1)}>
                                        <IconArrowLeft className="inline"/>
                                    </span>
                    )}
                    {title || ""}
                </h1>
                {badge && (
                    <p className="flex items-center justify-center h-[25px] w-[25px] md:h-[30px] md:w-[30px] text-black rounded-full bg-[#dddddd] font-bold text-sm">
                        {badge}
                    </p>
                )}
            </div>
            <p className="font-normal text-left md:text-left mr-0 md:mr-[50px] text-[15px] md:text-base text-black mt-1 md:mt-2">
                {desc || ""}
            </p>
        </div>
    );
};

export default Title;
