import React, {useContext, useState} from "react";
import Title from "@components/moment/settings/Title";
import {Context} from "../../index";
import ImageSlider from "@components/moment/slider/ImageSlider";
import Slide from "@components/moment/slider/Slide";
import GalleryModal from "@components/moment/modals/GalleryModal";
import {isDemo} from "@services/utils.service";
import {useTranslation} from "react-i18next";

const Gallery = ({fetch}: any) => {
    const {moment}: any = useContext(Context);

    const [showGalleryModal, setShowGalleryModal] = useState(false);

    const {t} = useTranslation<"translation", undefined>();

    if (!moment) {
        return null;
    }

    return (
        <>
            <GalleryModal
                show={showGalleryModal}
                setShow={setShowGalleryModal}
                fetch={fetch}
            />
            <div className="wrapper flex justify-between items-end py-5">
                <div className="flex">
                    <Title title={t("gallery")}/>
                    {moment.images && moment.images["@gallery"] && moment && moment.quotas && moment.users && (
                        <span className="self-center mx-2">
              {moment.images["@gallery"].length} {t("of")} {moment.quotas.images}
            </span>
                    )}
                </div>
                {!isDemo() && (
                    <button>
                        <p
                            onClick={() => setShowGalleryModal(true)}
                            className="underline font-gilroy font-black text-lg md:text-xl"
                        >
                            {t("add_photos")}
                        </p>
                    </button>
                )}
            </div>
            <div className="wrapper">
                {moment.images && !moment.images["@gallery"] && (
                    <p className="font-bold text-sm">
                        {t("you_havent_uploaded_any_pictures_yet")}
                    </p>
                )}
                {moment.images && moment.images["@gallery"] && (
                    <div className="mt-1 pb-5">
                        <ImageSlider>
                            {moment.images["@gallery"].map((image: any, i: number) => {
                                return <Slide key={i} image={image.url}/>;
                            })}
                        </ImageSlider>
                    </div>
                )}
            </div>
        </>
    );
};

export default Gallery;
