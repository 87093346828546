import api from "@services/api.service";
import {toast} from "react-toastify";
import i18n from "../i18n";

interface User {
    accessToken: string;
    email: string;
    name: string;
    id: string;
    birthdate?: string;
    avatar?: string;
}

export default new (class AuthService {


    login(email: string, password: string, callback: (status: string) => void) {
        if (!this.getUser()) {
            api.post("/v1/auth/login", {
                email: email,
                password: password,
            }).catch((e) => {
                if (e.response) {
                    switch (e.response.status) {
                        case 401:
                            callback("invalid-credentials");
                            break;
                        default:
                            callback("invalid-response");
                    }
                } else if (e.request) {
                    callback("no-response");
                }
            }).then((response) => {
                if (response) {
                    const accessToken = response.data.access_token;
                    api
                        .get("/v1/auth/info", api.getCustomAxiosConfig(accessToken), false, false)
                        .catch((e) => {
                            if (e.response) {
                                callback("invalid-response");
                            }
                        })
                        .then((response) => {
                            if (response) {
                                api.get("/v1/user", api.getCustomAxiosConfig(accessToken), false, false).then((response) => {
                                    if (response) {
                                        this.storeUser({
                                            accessToken: accessToken,
                                            ...response.data
                                        });
                                        callback("success");
                                    }
                                })
                            }
                        });
                }
            });
        } else {
            callback("already-logged-in");
        }
    }

    logout(manual = false) {
        const t = i18n.t;
        localStorage.removeItem("user");
        localStorage.removeItem("images")
        if (manual) toast.success(t("toast_logout_successfull"), {position: "bottom-right"});
        else
            toast.warning(t("toast_invalid_session"), {position: "bottom-right"});
    }

    storeUser(user: User) {
        localStorage.setItem("user", JSON.stringify(user));
    }

    storeAvatar(url: string) {
        let user = this.getUser();
        if (user) {
            user.avatar = url;
            this.storeUser(user);
        }
    }

    changeBirthdate(birthdate: string) {
        let user = this.getUser();
        if (user) {
            user.birthdate = birthdate;
            this.storeUser(user);
        }
    }

    changeUsername(name: string) {
        let user = this.getUser();
        if (user) {
            user.name = name;
            this.storeUser(user);
        }
    }

    loggedIn() {
        if (this.getUser()) {
            if (this.getUser()?.id) {
                return true;
            }
        }
        return false;
    }

    getUser(): User | undefined {
        const user = JSON.parse(localStorage.getItem("user") as string);
        if (user) {
            let cancel = false;
            for (const v of [user.accessToken, user.email, user.name, user.id]) {
                if (typeof v == "undefined") {
                    this.logout();
                    cancel = true;
                    break;
                }
            }
            if (cancel) {
                return;
            }
            return user;
        }
    }

    getAccessToken() {
        return this.getUser()?.accessToken;
    }
})();