import React, {useContext} from "react";
import api from "@services/api.service";
import {Context} from "../../../index";
import {useTranslation} from "react-i18next";
import {firstUC} from "@services/utils.service";

const DurationMenu = ({show, fetch}: any) => {
    const {moment}: any = useContext(Context);

    const {t} = useTranslation<"translation", undefined>();

    const handleDurationChange = (duration: number) => {
        api
            .post(`/v1/moment/${moment.id}/anniversary_style`, {
                value: duration,
            })
            .then(() => {
                fetch();
            });
    };

    if (!show) {
        return null;
    } else {
        return (
            <div className="flex justify-end space-x-4 items-center py-2 top__fade__ani">
                <div className="flex items-center space-x-4">
                    <button
                        onClick={() => handleDurationChange(1)}
                        className="w-max bg-light-grey p-4 rounded-xl border"
                    >
                        {firstUC(("days"))}
                    </button>
                </div>

                <div className="flex items-center space-x-4">
                    <button
                        onClick={() => handleDurationChange(2)}
                        className="w-max bg-light-grey p-4 rounded-xl border"
                    >
                        {t("months")}
                    </button>
                </div>

                <div className="flex items-center space-x-4">
                    <button
                        onClick={() => handleDurationChange(3)}
                        className="w-max bg-light-grey p-4 rounded-xl border"
                    >
                        {t("years")}
                    </button>
                </div>
            </div>
        );
    }
};

export default DurationMenu;
