import Title from "@components/ui/Title";
import React from "react";
import {Link} from "react-router-dom";

const NewsArticle = ({news}: any) => {
    return (
        <Link to={`/news/${news.id}`}>
            <div className="card h-44 md:h-60 w-[90%] md:w-[500px] mx-auto bg-cover mt-6">
                <div className="indicator absolute w-full h-full bg-cover rounded-2xl brightness-75 z-10" style={{
                    backgroundImage: `url(${news.image})`
                }}>
                    {!news.hasRead && (
                        <span className="indicator-item badge badge-lg bg-red-600 mt-1 mr-1"></span>
                    )}
                </div>
                <div className="card-body z-20">
                    <Title color="white" title={news.title}/>
                    <p className="text-md md:text-lg text-white font-bold">
                        {news.subtitle}
                    </p>
                </div>
                <img className="rounded-full w-16 h-16 object-cover flex self-end -mt-6 mb-3 mr-1 z-30"
                     src={news.author_image}
                     alt="Author"/>
            </div>
        </Link>
    )
}

export default NewsArticle;