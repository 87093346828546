import Modal from "@components/ui/Modal";
import React, { FormEvent, useContext, useState } from "react";
import { toast } from "react-toastify";
import { Context } from "../../../index";
import Button from "@components/ui/Button";
import { IconTrash } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

const AddPollModal = ({ show, setShow, socket }: any) => {
  const { moment }: any = useContext(Context);

  const [options, setOptions] = useState([""]);
  const [title, setTitle] = useState("");

  const { t } = useTranslation<"translation", undefined>();

  const handleChange = (i: number, event: any) => {
    const values = [...options];
    values[i] = event.target.value;
    setOptions(values);
  };

  const handleAdd = () => {
    const values = [...options];
    values.push("");
    setOptions(values);
  };

  const handleRemove = (i: number) => {
    const values = [...options];
    values.splice(i, 1);
    setOptions(values);
  };

  const handleCreate = (e: FormEvent) => {
    e.preventDefault();
    if (options.length < 2) {
      toast.error(t("please_add_2_or_more_options"), {
        position: "bottom-right",
      });
      return;
    }
    socket.emit("polls/create", {
      momentId: moment.id,
      title: title,
      options: options,
    });
    console.log("polls/create");
    setShow(false);
    setTitle("");
    setOptions([""]);
  };

  return (
    <Modal show={show} setShow={setShow}>
      <form onSubmit={handleCreate}>
        <div className="flex flex-col">
          <label className="font-black mb-1">{t("title")}</label>
          <input
            type="text"
            id="title"
            className="border-2 outline-none border-border-grey rounded-2xl py-3 px-4 placeholder:font-black mb-1"
            value={title}
            onChange={(e) => setTitle(e.currentTarget.value)}
            placeholder={""}
            required
          />
          <label className="font-black mb-1">{t("options")}</label>
          {options.map((field, idx) => {
            return (
              <div className="flex justify-between mb-2" key={idx}>
                <input
                  type="text"
                  placeholder={`${t("option")} ${idx + 1}`}
                  value={field || ""}
                  className="border-2 outline-none border-border-grey rounded-2xl py-3 px-4 w-full placeholder:font-black mb-1"
                  required
                  onChange={(e) => handleChange(idx, e)}
                />
                <button type="button" onClick={() => handleRemove(idx)}>
                  <IconTrash size={32} />
                </button>
              </div>
            );
          })}
          <Button
            modifiers="bg-green-800 mb-2 mt-3"
            onClick={handleAdd}
            title={t("add_option")}
          />
          <Button type="submit" title={t("Create")} />
        </div>
      </form>
    </Modal>
  );
};

export default AddPollModal;
