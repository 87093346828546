import {Main} from "@components/layout/Main";
import React, {useContext, useState} from "react";
import Title from "@components/ui/Title";
import {useNavigate, useParams} from "react-router-dom";
import {
    IconAlbum,
    IconCalendar,
    IconChevronRight,
    IconCirclesRelation,
    IconCloudUpload, IconEgg,
    IconFlare,
    IconPencil, IconPumpkinScary,
    IconSignature,
    IconSnowflake,
    IconUser,
} from "@tabler/icons-react";
import {DateFormat, DateStyle, FormatDate, FormatDateDelta, RelStatus,} from "@components/moment/Dates";
import IconMenu from "@components/moment/settings/IconMenu";
import Setting from "@components/moment/settings/Setting";
import DurationMenu from "@components/moment/settings/DurationMenu";
import {Context} from "../../index";
import Icon from "@components/ui/Icon";
import ProtectedImage from "@components/auth/ProtectedImage";
import UploadBannerModal from "@components/moment/modals/UploadBannerModal";
import api from "@services/api.service";
import "react-datepicker/dist/react-datepicker.css";
import AnniversaryChangeModal from "@components/moment/modals/AnniversaryChangeModal";
import AddUserModal from "@components/moment/modals/AddUserModal";
import LeaveModal from "@components/moment/modals/LeaveModal";
import TitleMenu from "@components/moment/settings/TitleMenu";
import ChristmasMenu from "@components/moment/settings/ChristmasMenu";
import NewyearsMenu from "@components/moment/settings/NewyearsMenu";
import RelstatusMenu from "@components/moment/settings/RelstatusMenu";
import {useTranslation} from "react-i18next";
import HalloweenMenu from "@components/moment/settings/HalloweenMenu";
import EasterMenu from "@components/moment/settings/EasterMenu";

const MomentSettingsPage = ({fetch}: any) => {
    const {moment}: any = useContext(Context);

    const {t} = useTranslation<"translation">();

    const [showLeaveModal, setShowLeaveModal] = useState(false);
    const [showIconMenu, setShowIconMenu] = useState(false);
    const [showTitleMenu, setShowTitleMenu] = useState(false);
    const [showDurationMenu, setShowDurationMenu] = useState(false);
    const [showUploadBannerModal, setShowUploadBannerModal] = useState(false);
    const [showAnniversaryChangeModal, setShowAnniversaryChangeModal] =
        useState(false);
    const [showAddUser, setShowAddUser] = useState(false);
    const [showChristmasMenu, setShowChristmasMenu] = useState(false);
    const [showHalloweenMenu, setShowHalloweenManu] = useState(false);
    const [showEasterMenu, setShowEasterMenu] = useState(false);
    const [showNewyearsMenu, setShowNewyearsMenu] = useState(false);
    const [showRelstatusMenu, setShowRelstatusMenu] = useState(false);

    let ms = '';
    switch (moment.data?.relstatus) {
        case 1:
            ms = "got_together";
            break;
        case 2:
            ms = "engaged";
            break;
        case 3:
            ms = "married";
            break;
        default:
            ms = "got_together";
            break;
    }

    const anniversary = moment.milestones.find((milestone: any) => milestone.milestone == ms)?.date;

    return (
        <Main>
            <AddUserModal fetch={fetch} show={showAddUser} setShow={setShowAddUser}/>
            <UploadBannerModal
                fetch={fetch}
                show={showUploadBannerModal}
                setShow={setShowUploadBannerModal}
            />
            <AnniversaryChangeModal
                fetch={fetch}
                show={showAnniversaryChangeModal}
                setShow={setShowAnniversaryChangeModal}
            />
            {!moment && (
                <div className="wrapper items-center pb-4">
                    <Title title={t("settings")} back={true}/>
                    <div className="flex flex-col">
                        <span className="mx-auto mt-10 loading loading-infinity loading-lg w-20"></span>
                    </div>
                </div>
            )}
            {moment && (
                <div className="wrapper items-center pb-4">
                    <Title title={t("settings")} back={true}/>
                    <div className="relative">
                        <div
                            className="z-10 w-full absolute top-[55%] left-[50%] -translate-x-[50%] -translate-y-[50%]  flex flex-col justify-center items-center">
                            {moment.settings.anniversary && (
                                <div
                                    className="z-30 w-full flex flex-col items-center mx-auto text-center md:space-y-[1px] bg-black bg-opacity-[65%] py-3 mb-6">
                                    <div className="flex justify-center items-start space-x-2 text-white text-center">
                                        <p
                                            onClick={() => setShowAnniversaryChangeModal(true)}
                                            className="text-white text-sm md:text-lg"
                                        >
                                            <FormatDate
                                                dateString={anniversary}
                                                format={DateFormat.FULL}
                                            />
                                        </p>
                                        {!anniversary && <>{t("edit_anniversary")}</>}
                                        <button
                                            onClick={() => setShowAnniversaryChangeModal(true)}
                                            className="-mt-1"
                                        >
                                            <IconPencil/>
                                        </button>
                                    </div>
                                    {anniversary && (
                                        <p className="text-white text-sm md:text-lg">
                                            {RelStatus(moment.data.relstatus)} {t("since")}{" "}
                                            <FormatDateDelta
                                                style={moment.data?.anniversary_style}
                                                dateString={anniversary}
                                                spaces={true}
                                            />
                                        </p>
                                    )}
                                </div>
                            )}
                            <button
                                className="flex mx-auto items-center text-white space-x-2 py-2 px-8 border-[1.5px] md:border-[2.5px] border-white rounded-3xl mb-4">
                                <IconCloudUpload/>
                                <span
                                    onClick={() => setShowUploadBannerModal(true)}
                                    className="text-white font-extrabold text-sm md:text-xl"
                                >
                  {t("upload_new_image")}
                </span>
                            </button>

                            {moment.images && moment.images["@banner"] && (
                                <button
                                    onClick={() => {
                                        api
                                            .delete(`/v1/moment/${moment.id}/images/banner`)
                                            .then(() => {
                                                fetch();
                                            });
                                    }}
                                    className="text-center mb-4"
                                >
                                    <p className="underline font-bold text-white text-sm md:text-lg">
                                        {t("remove_image")}
                                    </p>
                                </button>
                            )}
                        </div>

                        {moment.images && moment.images["@banner"] && (
                            <ProtectedImage
                                className="w-full rounded-2xl brightness-75 max-h-[350px] object-cover"
                                url={moment.images["@banner"][0].url}
                            />
                        )}
                        {moment.images && !moment.images["@banner"] && (
                            <img
                                className="w-full rounded-2xl brightness-75 max-h-[350px] object-cover"
                                src="/static/blank.png"
                                alt="banner"
                            />
                        )}
                    </div>
                    <div
                        className="border-2 border-border-grey rounded-2xl p-4 md:p-8 space-y-2 md:space-y-6 mb-8 mt-8">
                        {((moment.users.length == 1 && moment.settings.guser) ||
                            moment.typeId == 3 ||
                            moment.typeId == 4) && (
                            <Setting
                                onClick={() => setShowAddUser(true)}
                                name={`${moment.typeId == 1 ? t("add_partner") : ""}${
                                    moment.typeId == 2 ? t("add_friend") : ""
                                }${moment.typeId == 3 ? t("add_family") : ""}${
                                    moment.typeId == 4 ? t("add_friend") : ""
                                } `}
                                // ${t("add")}
                                icon={<IconUser/>}
                                end={
                                    <button>
                                        <IconChevronRight/>
                                    </button>
                                }
                            />
                        )}

                        {moment.settings.icon && (
                            <>
                                <Setting
                                    onClick={() => setShowIconMenu((show) => !show)}
                                    icon={<IconAlbum/>}
                                    name={t("symbol")}
                                    value={<Icon style={moment.data.icon}/>}
                                    end={
                                        <button>
                                            <IconChevronRight
                                                className={showIconMenu ? "rotate-90" : ""}
                                            />
                                        </button>
                                    }
                                />

                                <IconMenu fetch={fetch} show={showIconMenu}/>
                            </>
                        )}

                        {moment.settings.title && (
                            <>
                                <Setting
                                    onClick={() => setShowTitleMenu((show) => !show)}
                                    icon={<IconSignature/>}
                                    name={`${moment.typeId == 3 ? t("family_name") : ""}${
                                        moment.typeId == 4 ? t("group_name") : ""
                                    }`}
                                    value={moment.data.title}
                                    end={
                                        <button>
                                            <IconChevronRight
                                                className={showIconMenu ? "rotate-90" : ""}
                                            />
                                        </button>
                                    }
                                />

                                <TitleMenu fetch={fetch} show={showTitleMenu}/>
                            </>
                        )}

                        {moment.settings.anniversary && (
                            <>
                                <Setting
                                    onClick={() => setShowDurationMenu((show) => !show)}
                                    icon={<IconCalendar/>}
                                    name={t("show_anniversary_in")}
                                    value={<DateStyle style={moment.data.anniversary_style}/>}
                                    end={
                                        <button>
                                            <IconChevronRight
                                                className={showDurationMenu ? "rotate-90" : ""}
                                            />
                                        </button>
                                    }
                                />

                                <DurationMenu fetch={fetch} show={showDurationMenu}/>
                            </>
                        )}

                        {moment.settings.relstatus && (
                            <>
                                <Setting
                                    onClick={() => setShowRelstatusMenu((show) => !show)}
                                    icon={<IconCirclesRelation/>}
                                    name={t("relationship_status")}
                                    value={RelStatus(moment.data.relstatus)}
                                    end={
                                        <button>
                                            <IconChevronRight
                                                className={showRelstatusMenu ? "rotate-90" : ""}
                                            />
                                        </button>
                                    }
                                />

                                <RelstatusMenu fetch={fetch} show={showRelstatusMenu}/>
                            </>
                        )}

                        {Boolean(process.env.REACT_APP_CHRISTMAS) && (
                            <>
                                <Setting
                                    onClick={() => setShowChristmasMenu((show) => !show)}
                                    icon={<IconSnowflake/>}
                                    name={t("christmas_effect")}
                                    value={moment.data.effects == false ? t("out_of") : t("at")}
                                    end={
                                        <button>
                                            <IconChevronRight
                                                className={showChristmasMenu ? "rotate-90" : ""}
                                            />
                                        </button>
                                    }
                                />

                                <ChristmasMenu fetch={fetch} show={showChristmasMenu}/>
                            </>
                        )}

                        {Boolean(process.env.REACT_APP_HALLOWEEN) && (
                            <>
                                <Setting onClick={() => setShowHalloweenManu((show) => !show)}
                                         icon={<IconPumpkinScary/>}
                                         name={t("halloween_effect")}
                                         value={moment.data.effects == false ? t("off") : t("on")}
                                         end={
                                             <button>
                                                 <IconChevronRight className={showHalloweenMenu ? "rotate-90" : ""}/>
                                             </button>
                                         }/>

                                <HalloweenMenu fetch={fetch} show={showHalloweenMenu}/>
                            </>
                        )}

                        {Boolean(process.env.REACT_APP_EASTER) && (
                            <>
                                <Setting onClick={() => setShowEasterMenu((show) => !show)}
                                         icon={<IconEgg/>}
                                         name={t("easter_effect")}
                                         value={moment.data.effects == false ? t("off") : t("on")}
                                         end={
                                             <button>
                                                 <IconChevronRight className={showEasterMenu ? "rotate-90" : ""}/>
                                             </button>
                                         }/>

                                <EasterMenu fetch={fetch} show={showEasterMenu}/>
                            </>
                        )}

                        {Boolean(process.env.REACT_APP_NEWYEARS) && (
                            <>
                                <Setting
                                    onClick={() => setShowNewyearsMenu((show) => !show)}
                                    icon={<IconFlare/>}
                                    name={t("new_year_effect")}
                                    value={moment.data.effects == false ? t("out_of") : t("at")}
                                    end={
                                        <button>
                                            <IconChevronRight
                                                className={showNewyearsMenu ? "rotate-90" : ""}
                                            />
                                        </button>
                                    }
                                />

                                <NewyearsMenu fetch={fetch} show={showNewyearsMenu}/>
                            </>
                        )}
          </div>
          <LeaveModal show={showLeaveModal} setShow={setShowLeaveModal} />
          <button
            onClick={() => setShowLeaveModal(true)}
            className="flex w-full justify-center items-center bg-red-700 text-white py-3 rounded-xl mt-4"
          >
            <span className="md:text-xl font-bold">{t("leave_moment")}</span>
          </button>
        </div>
      )}
    </Main>
  );
};
export default MomentSettingsPage;
