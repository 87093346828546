import React from "react";
import Nav from "@components/ui/Nav";
import Overlay from "@components/ui/Overlay";
import PageTitle from "@components/ui/PageTitle";

interface Props {
    children: React.ReactNode,
    title?: string;
}

export const Main = ({children, title}: Props) => {
    return (
        <div className="w-full bg-white relative pb-8">
            <Overlay/>
            <Nav/>
            {title && <PageTitle title={title}/>}
            {children}
        </div>
    );
};