import React, {FormEvent, useState} from "react";
import {toast} from "react-toastify";
import {validEmail} from "@services/utils.service";
import {Navigate, useSearchParams} from "react-router-dom";
import AuthService from "@services/auth.service";
import Button from "@components/ui/Button";
import Input from "@components/ui/Input";
import {useTranslation} from "react-i18next";

const LoginPage = () => {

    const {t} = useTranslation();

    const [search] = useSearchParams();

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);

    if (AuthService.loggedIn()) {
        return <Navigate to={search.get("continue") || "/"}/>
    }

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();
        setLoading(true);

        if (!email || !password) {
            toast.error(t("toast_login_invalid_input"), {
                position: "bottom-right"
            })
            setLoading(false);
            return;
        }

        if (!validEmail(email)) {
            toast.error(t("toast_invalid_email"))
            setLoading(false);
            return;
        }

        AuthService.login(email, password, (res: string) => {
            if (res === "no-response") {
                toast.error(t("toast_login_no_response"), {position: "bottom-right"});
            } else if (res === "invalid-response") {
                toast.error(
                    t("toast_login_invalid_response"), {position: "bottom-right"}
                );
            } else if (res === "invalid-credentials") {
                toast.error(t("toast_login_invalid_credentials"), {position: "bottom-right"});
                setPassword("");
            } else if (res === "already-logged-in") {
                return <Navigate to="/"/>;
            } else if (res === "success") {
                toast.success(
                    t("toast_login_success"), {position: "bottom-right"}
                );
            }
            setLoading(false);
        });

    }

    return (
        <div className="bg-white">
            <img className="wrapper mt-8 w-20 mx-auto rounded-xl" src="/logo.jpeg" alt="!#"/>
            <div className="wrapper text-center mb-8 mt-4 md:mb-12">
                <p className="font-black text-xl">Infinity Moments ist jetzt <u>inTatches</u></p>
                <br/>
                <p className="font-black text-lg">Jetzt auch als App verfügbar</p>
                <div className="flex items-center mx-auto justify-center gap-2">
                    <a href="https://apps.apple.com/de/app/intatches/id6478170854" target="_blank">
                        <img
                            width={150}
                            src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/de-de?size=250x83&amp;releaseDate=1276560000&h=7e7b68fad19738b5649a1bfb78ff46e9"
                            alt="Laden im App Store"/>
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.moments.infinity.infinity_moments"
                       target="_blank">
                        <img
                            width={164}
                            alt="Jetzt bei Google Play"
                            src="https://play.google.com/intl/de_de/badges/images/generic/de_badge_web_generic.png"/>
                    </a>
                </div>
            </div>

            <form onSubmit={handleSubmit} className="wrapper relative flex flex-col mb-4">
                <Input id="email" label={t("email")}
                       type="email"
                       placeholder="name@email.de"
                       autoComplete="username"
                       required
                       value={email}
                       onChange={(e) => setEmail(e.currentTarget.value)}/>

                <Input id="pwd" label={t("password")}
                       type="password"
                       placeholder="********"
                       autoComplete="password"
                       minLength={8}
                       required
                       value={password}
                       onChange={(e) => setPassword(e.currentTarget.value)}/>

                <div className="text-right mb-6">
                    <a href="/reset" className="text-md font-bold link link-hover">{t("forgot_password")}</a>
                </div>

                <Button content={loading ? <span className="loading loading-infinity loading-md"/> :
                    <span className="text-md">{t("login")}</span>}/>
            </form>


            <div className="wrapper text-center mb-8">
                <p className="text-sm">{t("dont_have_an_account")}</p>
                <p className="text-sm font-bold">{t("scan_bracelet_to_start")}</p>
                <p className="text-sm font-bold mt-5"><a
                    href="https://infinity-moments.com/policies/legal-notice">Impressum</a> &bull; <a
                    href="/tos">Nutzungsbedingungen</a> &bull; <a href="/privacy">Datenschutzerklärung</a></p>
            </div>
        </div>
    );
};

export default LoginPage;
