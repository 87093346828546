import {Moment} from "@pages/moments/Moment";
import {SpotifyWidget} from "@components/moment/SpotifyWidget";
import React, {useContext, useEffect, useState} from "react";
import api from "@services/api.service";
import {Context} from "../../../index";
import Gallery from "@components/moment/Gallery";
import Letters from "@components/moment/Letters";
import Timeline from "@components/moment/Timeline";
import BucketList from "@components/moment/BucketList";
import {isDemo} from "@services/utils.service";
import TicTacToe from "@components/moment/tictactoe/TicTacToe";

export const FriendshipMoment = ({fetch}: any) => {
    const {moment}: any = useContext(Context);

    return (
        <Moment fetch={fetch}>
            <Gallery fetch={fetch}/>
            <SpotifyWidget fetch={fetch}/>
            <Letters/>
            {(isDemo() || moment.users.length == 2) && (
                <TicTacToe/>
            )}
            <BucketList/>
            <Timeline/>
        </Moment>
    );
}