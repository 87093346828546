import AuthService from "@services/auth.service";
import {Navigate} from "react-router-dom";

const LogoutPage = () => {
    if (AuthService.loggedIn()) {
        AuthService.logout(true);
    }
    return <Navigate to="/login" />;
}

export default LogoutPage;