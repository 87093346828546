import React, {useContext} from 'react';
import '@styles/index.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "@styles/index.css";
import {SpotifyWidget} from "@components/moment/SpotifyWidget";
import {Moment, momentProps} from "@pages/moments/Moment";
import Gallery from "@components/moment/Gallery";
import Letters from "@components/moment/Letters";
import BucketList from "@components/moment/BucketList";
import Timeline from "@components/moment/Timeline";
import {Context} from "../../../index";
import TicTacToe from "@components/moment/tictactoe/TicTacToe";
import {isDemo} from "@services/utils.service";
import Title from "@components/moment/settings/Title";

const CoupleMoment = ({fetch}: momentProps) => {
    const {moment}: any = useContext(Context);

    return (
        <Moment fetch={fetch}>
            <Gallery fetch={fetch}/>
            <SpotifyWidget fetch={fetch}/>
            <Letters/>
            {(isDemo() || moment.users.length == 2) && (
                <TicTacToe/>
            )}
            {moment && moment.users.length == 1 && !isDemo() && (
                <div className="wrapper">
                    <div className="py-5">
                        <Title title="TicTacToe"/>
                    </div>
                    <p className="font-bold text-sm">
                        Füge deinen Partner hinzu um zu starten
                    </p>
                </div>
            )}
            <BucketList/>
            <Timeline/>
        </Moment>
    );
}

export default CoupleMoment;