import React, { useContext, useEffect, useState } from "react";
import Modal from "@components/ui/Modal";
import ProtectedImage from "@components/auth/ProtectedImage";
import api from "@services/api.service";
import { toast } from "react-toastify";
import { Context } from "../../../index";
import { IconTrash } from "@tabler/icons-react";
import Button from "@components/ui/Button";
import { useTranslation } from "react-i18next";

const EditTimelineModal = ({ show, setShow, fetch, event }: any) => {
  const { moment }: any = useContext(Context);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [imgPreview, setImgPreview]: any = useState("");
  const [image, setImage]: any = useState();
  const [date, setDate]: any = useState();
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation<"translation", undefined>();

  useEffect(() => {
    if (event) {
      setName(event.name);
      setDescription(event.description);
      if (event.image) {
        setImgPreview(event.image.url);
      }
      setImage(null);
      let d = new Date(event.date);
      d.setDate(d.getDate() + 1);
      setDate(d.toISOString().split("T")[0]);
    }
  }, [event]);

  const handleDeleteImage = () => {
    api
      .delete(`/v1/moment/${moment.id}/sector/insider/${event.id}/image`)
      .then(() => {
        setImgPreview(null);
        fetch();
      });
  };

  const handleEdit = () => {
    setLoading(true);
    let fd = new FormData();
    fd.append("name", name);
    fd.append("date", date);
    if (description) {
      fd.append("description", description);
    }
    if (image) {
      fd.append("file", image);
    }
    api
      .put(`/v1/moment/${moment.id}/sector/insider/${event.id}`, fd)
      .then(() => {
        setLoading(false);
        setShow(false);
        fetch();
        setName("");
        setDescription("");
        setDate("");
        setImage(null);
        setImgPreview(null);
        toast.success(t("processed_successfully"), {
          position: "bottom-right",
        });
      });
  };

  if (!moment || !event) {
    return null;
  }

  return (
    <Modal title={t("timeline")} show={show} setShow={setShow}>
      <div className="flex flex-col">
        <label className="font-black mb-1">{t("date")}</label>
        <input
          type="date"
          id="date"
          max={new Date().toISOString().substring(0, 10)}
          className="border-2 outline-none border-border-grey rounded-2xl py-3 px-4 placeholder:font-black mb-1"
          defaultValue={date}
          value={date || ""}
          onChange={(e) => setDate(e.currentTarget.value)}
          placeholder={""}
        />
        <label className="font-black mb-1">{t("insider")}</label>
        <input
          type="text"
          id="name"
          className="border-2 outline-none border-border-grey rounded-2xl py-3 px-4 placeholder:font-black mb-1"
          value={name}
          maxLength={255}
          onChange={(e) => setName(e.currentTarget.value)}
          placeholder={""}
        />
        <label htmlFor="description" className="font-black mb-1">
          {t("description")}
        </label>
        <textarea
          id="description"
          className="border-2 outline-none border-border-grey rounded-2xl py-3 px-4 placeholder:font-black mb-1"
          value={description}
          onChange={(e) => setDescription(e.currentTarget.value)}
          placeholder={""}
        />
      </div>
      <p className="block mb-2 mt-2 text-sm font-medium text-gray-900 dark:text-gray-300">
        {t("change_picture")}
      </p>
      {imgPreview && (
        <div className="relative w-full h-48 mt-3">
          <ProtectedImage
            className="w-full h-48 object-cover mb-2 lg:w-1/2 rounded-2xl"
            url={imgPreview}
          />
          <IconTrash
            onClick={handleDeleteImage}
            className="text-red-600 absolute top-2 left-2"
            size={40}
          />
        </div>
      )}
      <label htmlFor="description" className="font-black mb-1">
        {t("picture")}
      </label>
      <input
        type="file"
        className="border-2 outline-none border-border-grey rounded-2xl py-3 px-4 placeholder:font-black mb-1"
        onChange={(e) => {
          if (e.currentTarget.files && e.currentTarget.files[0]) {
            setImage(e.currentTarget.files[0]);
          }
        }}
      />
      <Button
        content={
          loading ? (
            <span className="loading loading-infinity loading-xs"></span>
          ) : (
            t("save")
          )
        }
        onClick={handleEdit}
      />
    </Modal>
  );
};

export default EditTimelineModal;
