import {Popover} from "react-tiny-popover";
import {useState} from "react";
import ProtectedImage from "@components/auth/ProtectedImage";
import {IconPencil, IconTrash} from "@tabler/icons-react";
import {getMonthName, longestWord} from "@services/utils.service";

const Insider = (props: {
    demo?: boolean,
    handleDelete: any,
    handleEdit: any,
    title?: string,
    description: string,
    image: string,
    name: string,
    date: Date
}) => {
    const [open, setOpen] = useState(false);
    return (
        <>
            <div className="timestamp">
                <span className="date text-lg">{props.title || <br/>}</span>
            </div>
            <div className="point default">
                <Popover onClickOutside={() => setOpen(false)} containerClassName="z-10"
                         isOpen={open} content={
                    <div className="shadow-2xl m-8 w-72 bg-white rounded-lg border border-gray-300">
                        <div className="flex p-2 items-center">
                            <span
                                className={`flex-1 text-xl ${longestWord(props.name).length > 20 ? "break-all" : "break-word"}`}>{props.name}</span>
                            {
                                !props.demo && (
                                    <>
                                        <span className="flex-end text-gray-700 text-lg"
                                              onClick={() => props.handleEdit()}><IconPencil size={30}/></span>
                                        <span className="flex-end text-red-600 text-lg"
                                              onClick={() => props.handleDelete()}><IconTrash size={30}/></span>
                                    </>
                                )
                            }
                        </div>
                        <hr className="border-gray-600"/>
                        {props.image && <ProtectedImage url={props.image}/>}
                        <div className="p-2 overflow-scroll max-h-48">
                            <span
                                className={`${longestWord(props.name).length > 20 ? "break-all" : "break-word"}`}>{props.description}</span>
                            <p className="text-center mt-3 text-sm font-normal text-gray-600 mb-0">
                                {props.date.getDate() === 1 && `${getMonthName(props.date.getMonth())} ${props.date.getFullYear()}`}
                                {props.date.getDate() !== 1 && `${props.date.getDate()}. ${getMonthName(props.date.getMonth())} ${props.date.getFullYear()}`}
                            </p>
                        </div>
                    </div>
                }>
                    <span className={`${longestWord(props.name).length > 20 ? "break-all" : "break-word"}`}
                          onClick={() => setOpen(!open)}>{props.name}</span>
                </Popover>
            </div>
        </>
    )
}

export default Insider;