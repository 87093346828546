import "@components/moment/swiper/swiper.css";

import "swiper/css";
import React, {useContext, useEffect, useRef, useState} from "react";
import api from "@services/api.service";
import {getMonthName, isDemo} from "@services/utils.service";
import {Context} from "../../index";
import {Swiper, SwiperSlide} from "swiper/react";
import Insider from "@components/moment/swiper/Insider";
import EditTimelineModal from "@components/moment/modals/EditTimelineModal";
import AddTimelineModal from "@components/moment/modals/AddTimelineModal";
import {IconPlus} from "@tabler/icons-react";
import Title from "@components/moment/settings/Title";
import {useTranslation} from "react-i18next";

const Timeline = () => {
    const {moment}: any = useContext(Context);

    const [events, setEvents]: any = useState([]);

    const swiper: any = useRef(null);

    const [showAddTimeline, setShowAddTimeline] = useState(false);
    const [showEditTimeline, setShowEditTimeline] = useState(false);
    const [editEvent, setEditEvent] = useState();

    const {t} = useTranslation<"translation", undefined>();

    const handleEdit = (event: any) => {
        setEditEvent(event);
        setShowEditTimeline(true);
    };

    const fetchEvents = (slide?: boolean) => {
        api.get(`/v1/moment/${moment.id}/sector/insider`).then((response) => {
            setEvents(parseEvents(response.data));
            setTimeout(() => {
                if (swiper.current && slide && response.data) {
                    swiper.current.slideTo(response.data.length);
                }
            }, 100);
        });
    };

    const parseEvents = (
        evt: { name: string; date: Date; description: string; image: any }[]
    ) => {
        const sorted = evt.sort(
            (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
        );
        const parsed: any = {};
        sorted.forEach((event) => {
            event.date = new Date(event.date);
            const title = `${getMonthName(
                event.date.getMonth(),
                true
            )} ${event.date.getFullYear()}`;
            if (!parsed[title]) {
                parsed[title] = [];
            }
            parsed[title].push(event);
        });
        return parsed;
    };

    let index = 0;
    const getIndex = () => {
        const i = index;
        index++;
        return i;
    };

    const handleDelete = (id: string) => {
        api.delete(`/v1/moment/${moment.id}/sector/insider/${id}`).then(() => {
            fetchEvents();
        });
    };

    useEffect(() => {
        if (isDemo()) {
            setEvents(
                parseEvents([
                    {
                        date: new Date("2021-01-12"),
                        name: t("muro"),
                        description: "",
                        image: {url: "/static/demo/insider-2.jpeg"},
                    },
                    {
                        date: new Date("2021-01-13"),
                        name: t("mallorca"),
                        description: "",
                        image: {url: "/static/demo/insider-4.jpeg"},
                    },
                    {
                        date: new Date("2022-12-04"),
                        name: t("visit_to_our_home"),
                        description: "",
                        image: {url: "/static/demo/insider-5.jpeg"},
                    },
                    {
                        date: new Date("2022-10-09"),
                        name: t("after_oktoberfest"),
                        description: "",
                        image: {url: "/static/demo/insider-7.jpeg"},
                    },
                    {
                        date: new Date("2022-10-08"),
                        name: t("before_oktoberfest"),
                        description: "",
                        image: {url: "/static/demo/insider-8.jpeg"},
                    },
                    {
                        date: new Date("2022-09-19"),
                        name: t("Lavagna (Italien)"),
                        description: "",
                        image: {url: "/static/demo/insider-9.jpeg"},
                    },
                    {
                        date: new Date("2022-09-17"),
                        name: t("cecina_best_pizza_in_the_world"),
                        description: "",
                        image: {url: "/static/demo/insider-10.jpeg"},
                    },
                    {
                        date: new Date("2022-09-16"),
                        name: t("piombino_italy"),
                        description: "",
                        image: {url: "/static/demo/insider-11.jpeg"},
                    },
                    {
                        date: new Date("2021-02-10"),
                        name: t("cuxhaven"),
                        description: "",
                        image: {url: "/static/demo/insider-13.jpeg"},
                    },
                    {
                        date: new Date("2021-03-08"),
                        name: t("black_forest"),
                        description: "",
                        image: {url: "/static/demo/insider-14.jpeg"},
                    },
                    {
                        date: new Date("2021-04-21"),
                        name: t("easter"),
                        description: "",
                        image: {url: "/static/demo/insider-15.jpeg"},
                    },
                    {
                        date: new Date("2021-07-21"),
                        name: t("affenberg_flo_birthday"),
                        description: "",
                        image: {url: "/static/demo/insider-17.jpeg"},
                    },
                    {
                        date: new Date("2022-07-22"),
                        name: t("flo_birthday_in_meran"),
                        description: "",
                        image: {url: "/static/demo/insider-16.jpeg"},
                    },
                    {
                        date: new Date("2022-10-18"),
                        name: t("sandras_birthday"),
                        description: "",
                        image: {url: "/static/demo/insider-18.jpeg"},
                    },
                    {
                        date: new Date("2021-06-21"),
                        name: t("somewhere_between_gravedona_and_switzerland"),
                        description: "",
                        image: {url: "/static/demo/insider-19.jpeg"},
                    },
                    {
                        date: new Date("2021-06-21"),
                        name: t("flo_learns_to_clean_house"),
                        description: t("vacuum_man"),
                        image: {url: "/static/demo/insider-20.jpeg"},
                    },
                    {
                        date: new Date("2023-07-04"),
                        name: t("construction_of_our_studio"),
                        description: "",
                        image: {url: "/static/demo/insider-3.jpeg"},
                    },
                    {
                        date: new Date("2022-09-17"),
                        name: t("rio_maggiore"),
                        description: "",
                        image: {url: "/static/demo/insider-22.jpeg"},
                    },
                    {
                        date: new Date("2021-07-01"),
                        name: t("the_raised_hand"),
                        description: "",
                        image: {url: "/static/demo/insider-1.jpg"},
                    },
                    {
                        date: new Date("2020-12-01"),
                        name: t("the_brillux_alien"),
                        description: "",
                        image: {url: "/static/demo/insider-21.jpeg"},
                    },
                ])
            );
            return;
        }
        fetchEvents(true);
    }, []);
    return (
        <>
            <EditTimelineModal
                fetch={fetchEvents}
                event={editEvent}
                show={showEditTimeline}
                setShow={setShowEditTimeline}
            />
            <AddTimelineModal
                fetch={fetchEvents}
                show={showAddTimeline}
                setShow={setShowAddTimeline}
            />
            <div className="wrapper flex justify-between items-end py-5">
                <div className="flex">
                    <Title title={t("timeline")}/>
                    {events && moment && moment.quotas && (
                        <span className="self-center mx-2">
              {Object.keys(events).length} {t("of")} {moment.quotas.timeline}
            </span>
                    )}
                </div>
            </div>
            <div className="row mx-auto timeline h-44 mb-5 bg-gradient-to-b from-white via-gray-300 to-white">
                <Swiper
                    onSwiper={(s) => (swiper.current = s)}
                    className="mx-auto mt-16 justify-self-center self-center w-full h-full"
                    slidesPerView={2}
                    grabCursor
                    direction={"horizontal"}
                    breakpoints={{
                        640: {
                            width: 640,
                            slidesPerView: 3,
                        },
                        768: {
                            width: 768,
                            slidesPerView: 4,
                        },
                    }}
                >
                    {Object.entries(events).map(([title, events]: any) => {
                        return (
                            <React.Fragment key={getIndex()}>
                                <SwiperSlide key={getIndex()}>
                                    <Insider
                                        handleEdit={() => handleEdit(events[0])}
                                        demo={isDemo()}
                                        description={events[0].description}
                                        image={events[0].image?.url}
                                        handleDelete={() => handleDelete(events[0].id)}
                                        title={title}
                                        name={events[0].name}
                                        date={new Date(events[0].date)}
                                    />
                                </SwiperSlide>
                                {events.slice(1).map((event: any) => {
                                    return (
                                        <SwiperSlide key={getIndex()}>
                                            <Insider
                                                handleEdit={() => handleEdit(event)}
                                                demo={isDemo()}
                                                description={event.description}
                                                image={event.image?.url}
                                                handleDelete={() => handleDelete(event.id)}
                                                name={event.name}
                                                date={event.date}
                                            />
                                        </SwiperSlide>
                                    );
                                })}
                            </React.Fragment>
                        );
                    })}
                    {!isDemo() && (
                        <SwiperSlide key={getIndex()}>
                            <div className="timestamp"></div>
                            <div
                                className="point add"
                                onClick={() => setShowAddTimeline(true)}
                            >
                                <IconPlus className="point-icon" size={40}/>
                                <span className="text-green-700 underline">{t("add")}</span>
                            </div>
                        </SwiperSlide>
                    )}
                </Swiper>
            </div>
        </>
    );
};

export default Timeline;
