import {Main} from "@components/layout/Main";
import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import apiService from "@services/api.service";
import Title from "@components/moment/settings/Title";
import ReactMarkdown from "react-markdown";
import {IconChevronLeft} from "@tabler/icons-react";
import { useTranslation } from "react-i18next";

const NewsPage = () => {
    const {id} = useParams();
    const navigate = useNavigate();

    const [news, setNews]: any = useState(null);

    const {t}=useTranslation<"translation", undefined>()

    useEffect(() => {
        apiService.get(`/v1/news/${id}`).then((response) => {
            setNews(response.data);
        })
    }, [])

    if (!news) {
        return (
            <Main>
                <div className="flex flex-col">
                    <span className="mx-auto loading loading-infinity loading-lg w-20"></span>
                </div>
            </Main>
        )
    } else {
        return (
            <Main>
                <div className="wrapper">
                    <span onClick={() => navigate(-1)} className="flex cursor-pointer">
                        <IconChevronLeft/> {t('back')}
                    </span>
                </div>
                <div className="wrapper py-3">
                    <div className="w-[75%] float-left">
                        <Title title={news.title}/>
                        <p>{news.subtitle}</p>
                    </div>
                    <div className="w-[25%] float-right">
                        <img className="rounded-full w-16 h-16 object-cover flex self-end -mt-1 z-20"
                             src={news.author_image}
                             alt="Author"/>
                    </div>
                </div>
                <div className="wrapper h-20"/>
                <div className="wrapper">
                    <ReactMarkdown className="markdown space-y-3">
                        {news.content}
                    </ReactMarkdown>
                </div>
            </Main>
        )
    }
}

export default NewsPage;