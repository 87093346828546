import React, {useContext} from "react";
import {IconFriends, IconHeart, IconHeartHandshake, IconInfinity} from "@tabler/icons-react";
import {Context} from "../../../index";
import api from "@services/api.service";

const IconMenu = ({show, fetch}: any) => {

    const {moment}: any = useContext(Context);

    const handleChangeIcon = (icon: number) => {
        api.post(`/v1/moment/${moment.id}/icon`, {
            value: icon
        }).then(() => {
            fetch();
        });
    }

    if (!show) {
        return null;
    } else {
        return (
            <div className="flex justify-end space-x-4 items-center py-2 top__fade__ani">
                <div className="flex items-center space-x-4">
                    <button onClick={() => handleChangeIcon(1)} className="w-max bg-light-grey p-4 rounded-xl border">
                        {moment.typeId == 1 && (
                            <IconInfinity size={28}/>
                        )}
                        {moment.typeId == 2 && (
                            <IconHeartHandshake size={28}/>
                        )}
                    </button>
                </div>

                <div className="flex items-center space-x-4">
                    <button onClick={() => handleChangeIcon(2)} className="w-max bg-light-grey p-4 rounded-xl border">
                        {moment.typeId == 1 && (
                            <IconHeart size={28}/>
                        )}
                        {moment.typeId == 2 && (
                            <IconFriends size={28}/>
                        )}
                    </button>
                </div>
            </div>
        );
    }
};

export default IconMenu;
