import React, { useEffect, useState } from "react";
import api from "@services/api.service";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Dialog } from "@components/layout/Dialog";
import { useTranslation } from "react-i18next";

const CreateMomentPage = () => {
  const { tokenId, type } = useParams();
  const navigate = useNavigate();

  const [info, setInfo]: any = useState();

  const { t } = useTranslation<"translation", undefined>();

  useEffect(() => {
    api
      .get(`/v1/token/${tokenId}/auth`)
      .then((response) => {
        if (response.data && response.data.type.policies) {
          for (const policy of response.data.type.policies) {
            if (policy.momentTypeId === Number(type)) {
              setInfo(policy);
            }
          }
        }
      })
      .catch(() => {
        navigate("/login");
      });
  }, [tokenId]);

  const handleCreate = () => {
    api
      .post(`/v1/moment`, {
        token: tokenId,
        type: Number(type),
      })
      .then((response: any) => {
        if (response.data && response.data.id) {
          toast.success(t("moment_created_successfully"), {
            position: "bottom-right",
          });
          navigate(`/${response.data.id}`);
        }
      })
      .catch(() => {
        toast.error(t("invalid_token"), { position: "bottom-right" });
      });
  };

  return (
    <Dialog
      title={`${t("your")} ${info?.momentType?.name} ${t("Moment")}`}
      back="/"
      loading={!info}
    >
      {Number(type) == 1 ||
        (Number(type) == 2 && (
          <div
            className="p-3 mt-3 mb-3 text-md text-blue-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800"
            role="alert"
          >
            <span className="font-bold text-lg">{t("tip")}</span>
            {t("just_one_of_the_two_of_you_create_your_partner")}
          </div>
        ))}
      {Number(type) == 3 && (
        <div
          className="p-3 mt-3 mb-3 text-md text-blue-700 bg-blue-100 rounded-lg dark:bg-blue-200 dark:text-blue-800"
          role="alert"
        >
          <span className="font-bold text-lg">{t("tip")}</span>{" "}
          {t("only_one_person_in_your_family_creates")}
        </div>
      )}
      <p>{t("you_are_about_to_create_a_new_moment")}</p>
      <button
        onClick={handleCreate}
        type="submit"
        className="w-full mt-3 h-12 text-white bg-gray-700 hover:bg-gray-800 focus:outline-none font-medium rounded-lg text-md px-5 py-3 text-center"
      >
        {t("create_new_moment")}
      </button>
    </Dialog>
  );
};

export default CreateMomentPage;
