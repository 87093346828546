import React, {useContext, useEffect} from "react";
import {Context} from "../../index";
import {IconX} from "@tabler/icons-react";
import Title from "@components/ui/Title";

const Modal = ({title, show, setShow, children}: any) => {
    const {setOverlay}: any = useContext(Context);

    const handleClose = () => {
        setShow(false);
    };

    window.onkeydown = (e) => {
        if (e.key === "Escape" && show) {
            setShow(false);
        }
    }

    useEffect(() => {
        setOverlay(show);
    }, [show])

    if (!show) {
        return null;
    }

    return (
        <div
            className="wrapper max-h-[90%] overflow-y-scroll fixed top-[50%] left-[50%] -translate-y-[50%] -translate-x-[50%] z-50">
            <div className="flex flex-col justify-center space-y-4 bg-white p-8 rounded-2xl top__fade__ani">
                <div className="flex justify-between items-center">
                    <div className="wrapper flex self-center items-start">
                        <Title title={title}/>
                    </div>
                    <div className="flex items-end justify-end">
                        <button onClick={handleClose}>
                            <IconX color="black"/>
                        </button>
                    </div>
                </div>
                {children}
            </div>
        </div>
    );
};

export default Modal;
