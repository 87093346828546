import Modal from "@components/ui/Modal";

import React, { useContext, useState } from "react";
import { Context } from "../../../index";
import Button from "@components/ui/Button";
import api from "@services/api.service";
import { validSpotifyUrl } from "@services/utils.service";
import { toast } from "react-toastify";
import axios, { AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";

const EditSongModal = ({ fetch, show, setShow }: any) => {
  const { moment }: any = useContext(Context);

  const [song, setSong] = useState(
    moment && moment.data.spotify_url ? moment.data.spotify_url : ""
  );

  const { t } = useTranslation<"translation", undefined>();

  const handleEdit = async () => {
    if (song && song != "" && !validSpotifyUrl(song)) {
      toast.error(t("please_enter_a_spotify_url"), {
        position: "bottom-right",
      });
      return;
    }

    if (new URL(song).host == "spotify.link") {
      axios
        .get(song, { withCredentials: false })
        .then((response: AxiosResponse) => {
          console.log(response.request.res.responseUrl);
        })
        .catch((e) => {
          console.log(e);
        });
    }

    api
      .post(`/v1/moment/${moment.id}/spotify_url`, {
        value: song,
      })
      .then(() => {
        fetch();
        setShow(false);
      });
  };

  return (
    <Modal title={t("edit_song")} show={show} setShow={setShow}>
      {t("please_only_use_links")}
      <div className="flex flex-col">
        <label htmlFor="song" className="font-black mb-1">
          {t("spotify_url")}
        </label>
        <input
          name="song"
          className="border-2 outline-none border-border-grey rounded-2xl py-3 px-4 placeholder:font-black mb-2"
          type="url"
          placeholder="https://open.spotify.com/..."
          required
          value={song}
          onChange={(e) => setSong(e.currentTarget.value)}
        />
        <Button onClick={handleEdit} title={t("save")} />
      </div>
    </Modal>
  );
};

export default EditSongModal;
