import {Link} from "react-router-dom";
import {Main} from "@components/layout/Main";
import React from "react";
import {useTranslation} from "react-i18next";

const NotfoundPage = () => {
    const {t} = useTranslation();

    return (
        <Main>
            <div className="flex flex-col text-center mt-10 mx-auto">
                <span className="text-xl font-bold">404 - {t("page_not_found")}</span>
                <Link to="/" className="link link-hover text-lg">{t("back_to_dashboard")}</Link>
            </div>
        </Main>
    )
};

export default NotfoundPage;
