import React, { useContext } from "react";
import Modal from "@components/ui/Modal";
import { Context } from "../../../index";
import Button from "@components/ui/Button";
import { useNavigate } from "react-router-dom";
import api from "@services/api.service";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const LeaveModal = ({ show, setShow }: any) => {
  const { moment }: any = useContext(Context);

  const navigate = useNavigate();

  const { t } = useTranslation<"translation", undefined>();

  const handleLeave = () => {
    api.post(`/v1/moment/${moment.id}/leave`).then(() => {
      toast.success(t("exit_moment_successfully"), {
        position: "bottom-right",
      });
      setShow(false);
      navigate("/");
    });
  };

  return (
    <Modal show={show} setShow={setShow}>
      <p className="mt-3 text-lg text-center">
        {t("do_you_really_want_to_leave_this_page")}
      </p>
      <p className="font-bold underline">{t("this_action_cannot_be_undone")}</p>
      <Button onClick={() => setShow(false)} title={t("cancel")} />
      <button
        onClick={handleLeave}
        className="flex justify-center items-center bg-red-700 text-white py-3 rounded-xl -mt-5"
      >
        <span className="md:text-xl">{t("leave_moment")}</span>
      </button>
    </Modal>
  );
};

export default LeaveModal;
