import React, {useContext, useEffect, useState} from "react";
import {Context} from "../../../index";
import {Socket} from "socket.io-client";
import Title from "@components/moment/settings/Title";
import "./tictactoe.css";
import {isDemo} from "@services/utils.service";
import AuthService from "@services/auth.service";
import {useTranslation} from "react-i18next";

const TicTacToe = () => {
    const {moment}: any = useContext(Context);
    const {socket}: { socket: Socket } = useContext(Context);

    const {t} = useTranslation();

    const [score, setScore] = useState("1-3");
    const [state, setState] = useState("OX----XO----");
    const [lock, setLock] = useState(true);
    const [info, setInfo] = useState("");
    const [lastMove, setLastMove] = useState("");
    const [userMark, setUserMark] = useState("");
    const [colored, setColored] = useState("");

    useEffect(() => {
        if (socket && moment && !isDemo()) {
            socket.emit("ttt/get", {momentId: moment.id})
            socket.on("ttt/get", (data) => {
                updateTTTData(data);
            })
            socket.on("ttt/update", (data) => {
                updateTTTData(data);
            })
        }
    }, [socket]);

    const startGame = () => {
        if (lastMove && (lastMove.includes("w") || lastMove.includes("d"))) {
            setState("---------");
            setLock(false);
            setInfo(`${t("your_turn")}`);
            setColored("");
        }
    }

    const updateTTTData = (data: any) => {
        setState(data.state);
        setScore(data.score);
        setLastMove(data.lastMove);

        if (data.lastMove && (data.lastMove.includes("w") || data.lastMove.includes("d"))) {
            setLock(true);
        } else {
            setLock(false);
            setColored("");
        }

        let mark;

        for (let i = 0; i < moment.users.length; i++) {
            if (moment.users[i].id == AuthService.getUser()?.id) {
                mark = i == 0 ? "O" : "X";
            }
        }


        if (!mark) {
            return;
        }

        setUserMark(mark);

        if (data.lastMove == null) {
            setInfo(`${t("your_turn")}`);
        }
        if (data.lastMove == "X") {
            if (mark == "X") {
                setInfo(`${moment.users[0].name} ${t("other_turn")}`)
            } else {
                setInfo(`${t("your_turn")}`);
            }
        }
        if (data.lastMove == "O") {
            if (mark == "O") {
                setInfo(`${moment.users[1].name} ${t("other_turn")}`)
            } else {
                setInfo(`${t("your_turn")}`);
            }
        }
        if (data.lastMove == "Ow") {
            markWin(data.state);
            if (mark == "O") {
                setInfo(`${t("you_won")}`);
            } else {
                setInfo(`${t("you_lost")}`);
            }
        }
        if (data.lastMove == "Xw") {
            markWin(data.state);
            if (mark == "X") {
                setInfo(`${t("you_won")}`);
            } else {
                setInfo(`${t("you_lost")}`);
            }
        }
        if (data.lastMove.includes("d")) {
            setInfo(`${t("tie")}`);
        }
    }

    const markWin = (nstate: any) => {
        const wins = [
            [0, 1, 2],
            [3, 4, 5],
            [6, 7, 8],
            [0, 3, 6],
            [1, 4, 7],
            [2, 5, 8],
            [0, 4, 8],
            [2, 4, 6]
        ];

        for (const win of wins) {
            const markAt = nstate.charAt(win[0]);
            if (markAt != "-") {
                if (
                    nstate.charAt(win[1]) == markAt &&
                    nstate.charAt(win[2]) == markAt
                ) {
                    setColored(`${win[0]}${win[1]}${win[2]}`)
                }
            }
        }
    }

    const move = (i: number) => {
        if (lock) {
            return;
        }
        setLock(true);
        socket.emit("ttt/move", {
            momentId: moment.id,
            move: i
        })
    }

    return (
        <>
            <div className="wrapper flex justify-between items-end py-5">
                <Title title="TicTacToe"/>
                {!isDemo() && lastMove && (lastMove.includes("w") || lastMove.includes("d")) && (
                    <button onClick={startGame}>
                        <p
                            className="underline font-gilroy font-black text-lg md:text-xl">
                            {t("new_game")}
                        </p>
                    </button>
                )}
            </div>
            <div className="wrapper mt-5 mb-5 mx-auto">
                <div className="w-full text-center">
                    <div>{info}</div>
                    {!isDemo() && (
                        <div className="mb-5">{t("you_are")} <span className="underline">{userMark}</span></div>
                    )}
                    <div className="board flex">
                        {state.split("").map((c, i) => {
                            let classes = "";
                            switch (i) {
                                case 0: {
                                    classes = "b-b b-r"
                                    break;
                                }
                                case 1: {
                                    classes = "b-b b-r b-l"
                                    break;
                                }
                                case 2: {
                                    classes = "b-b b-l"
                                    break;
                                }
                                case 3: {
                                    classes = "b-b b-t b-r"
                                    break;
                                }
                                case 4: {
                                    classes = "b-b b-t b-r b-l"
                                    break;
                                }
                                case 5: {
                                    classes = "b-b b-t b-l"
                                    break;
                                }
                                case 6: {
                                    classes = "b-t b-r"
                                    break;
                                }
                                case 7: {
                                    classes = "b-t b-r b-l"
                                    break;
                                }
                                case 8: {
                                    classes = "b-t b-l"
                                    break;
                                }
                            }

                            return (
                                <button key={i}
                                        className={`square ${classes}${colored.includes(String(i)) ? " colored" : ""}`}
                                        onClick={() => move(i)}>{c == "-" ? "" : c}</button>
                            )
                        })}
                    </div>
                    {moment && (
                        <div className="self-center mt-3 text-xl"><span
                            className="underline">O</span> {moment.users[0].name} {score} {moment.users[1].name} <span
                            className="underline">X</span></div>
                    )}
                </div>
            </div>
        </>
    )

}

export default TicTacToe;