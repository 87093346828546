import React from "react";
import ProtectedImage from "@components/auth/ProtectedImage";

const Slide = (props: { image: string }) => {
    return (
        <div className="child-element text-center focus:outline-none">
            <ProtectedImage className="focus:outline-none rounded-2xl" url={props.image} alt=""/>
        </div>
    )
}

export default Slide;